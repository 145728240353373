import { fetchPost } from "../../utils/api";
import { Metric } from "../../models/Metric";
import { TemporalMetricsCollection } from "../../models/TemporalMetricsCollection";

export const fetchMetricsForPracticeMarketByMetricTypeIds = (
    practiceId:string,
    marketId: string,
    metricTypeIds: string[]
) => fetchPost<Metric[]>(`/api/HF/metrics/practice/${practiceId}`, metricTypeIds);

export const fetchTemporalMetricsForPracticeMarketByMetricTypeId = (
    practiceId:string,
    marketId: string,
    temporalMetricTypeIds: string[]
) => fetchPost<TemporalMetricsCollection[]>(`/api/HF/metrics/practice/${practiceId}/temporalMetrics`, temporalMetricTypeIds);