import React from "react";
import { ReactSVG } from "react-svg";

interface PatientsKpiSingleValueMetricIconProps {
    iconSource: string;
    className: string;
}

const PatientsKpiSingleValueMetricIcon: React.FunctionComponent<
    PatientsKpiSingleValueMetricIconProps> = ({iconSource, className}) => {
    return (<ReactSVG
        className={className}
        src={iconSource}
        afterInjection={(svg) => {
            svg.setAttribute('style', 'width: 30px')
            svg.setAttribute('style', 'height: 30px')
        }}/>
    );
};

export const PatientsKpiSingleValueMetricIconMemo = React.memo(PatientsKpiSingleValueMetricIcon);