import './App.scss';

import React, {useCallback, useEffect, useMemo} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {createBrowserRouter, RouterProvider } from 'react-router-dom';
import AppRoutes from "./AppRoutes";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import {MetricsProvider} from "./utils/useMetrics";
import {FlexLoaderMemo} from "./features/FlexLoader/FlexLoader";
import { WebAnalyticsProvider } from '@e360/react-web-analytics';
import { MatomoAdapter } from '@e360/react-web-analytics-matomo';
import {useAppAuthentication} from "./utils/useAppAuthentication";
import {
    isUserAuthenticatedSelector,
    loggedUserSelector,
    nonSilentAuthProcessingSelector
} from "./app/selectors/authSelector";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import { GlobalErrorBoundaryMemo } from './features/GlobalErrorBoundary/GlobalErrorBoundary';

import { OIDC_CONFIG } from "./index";
import { useInitWithCleanup } from './utils/custom-react-hooks';
import { setActions, setProfile, setSiteUri } from './app/slices/globalNavigationSlice';
import HttpService from './app/services/http-service';
import { useRelativeSiteUri } from './utils/useRelativeSiteUri';


library.add(fas)

export const App = () => {
    useAppAuthentication();
    const dispatch = useAppDispatch();
    
    const oidcStorageKey = `oidc.user:${OIDC_CONFIG.authority}:${OIDC_CONFIG.client_id}`;
    const oidcEmptyStorageValue = 'synced';

    const loggedUser = useAppSelector(loggedUserSelector);
    const isAuthenticated = useAppSelector(isUserAuthenticatedSelector);
    const nonSilentAuthProcessing = useAppSelector(nonSilentAuthProcessingSelector);

    const routerBaseName: string = useRelativeSiteUri();

    useEffect(
        () => {
            if (isAuthenticated) {
                const oidcSessionStorageValue = sessionStorage.getItem(
                    oidcStorageKey);
                
                if (oidcSessionStorageValue) {
                    localStorage.setItem(
                        oidcStorageKey,
                        oidcSessionStorageValue);
                    
                    localStorage.setItem(
                        oidcStorageKey,
                        oidcEmptyStorageValue)
                }
            }
        }, [isAuthenticated, oidcStorageKey]);

    const matomoAdapter: MatomoAdapter = useMemo(() =>
        new MatomoAdapter({
            baseUri: window.appSettings.webAnalyticsProvider.matomoBaseUri,
            siteId: window.appSettings.webAnalyticsProvider.matomoSiteId
        }), []);
    useEffect(() => {
        if (loggedUser?.email) {
            matomoAdapter.trackUserId(loggedUser.email);
        }
    }, [loggedUser, matomoAdapter]);

    const logoutCallback = useCallback(
        () => {
            sessionStorage.clear();
            localStorage.clear();
        },
        []);

    const onLocalStorageUpdate = (event) => {
        if (event.key === oidcStorageKey) {                
            const oidcSessionStorageValue = sessionStorage.getItem(
                oidcStorageKey);
            
            // update only when exists, previous expired or removed by logout
            if (oidcSessionStorageValue 
                && oidcSessionStorageValue !== event.value 
                && event.value !== oidcEmptyStorageValue) {
                sessionStorage.setItem(
                    oidcStorageKey,
                    event.value);
            }
        } else if (event.key === null) {
            // when cleared, key is null
            sessionStorage.clear();
        }
    };
    
    useInitWithCleanup(
        () => {
            
            dispatch(setSiteUri(window.appSettings.siteUri));

            HttpService.configureBaseUrl(
                window.appSettings.siteUri);

            window.addEventListener(
                "storage",
                onLocalStorageUpdate);
            
            return () => {
                window.removeEventListener(
                    "storage",
                    onLocalStorageUpdate);
            };
        }
    )
    
    useEffect(
        () => {
            const profile = {
                name: loggedUser?.name,
                email: loggedUser?.email,
                logoutUrl: `${window.appSettings.siteUri}logout`,
                onLogoutClick: () => logoutCallback(),
                "profileLinks": [
                    {
                        "icon": "user",
                        "label": "Profile",
                        "url": `${window.appSettings.platformUri}Account/Profile`,
                    },
                    {
                        "icon": "cog",
                        "label": "Manage account",
                        "url": `${window.appSettings.platformUri}Account/Manage`
                    },
                    {
                        "icon": "question-circle",
                        "label": "Help",
                        "url": `${window.appSettings.platformUri}Help`
                    }
                ]
            };

            dispatch(setProfile(profile));
        }, [dispatch, logoutCallback, loggedUser]);

    const actions = useMemo(
        () => [{
            iconType: "question-circle",
            tooltip: "Help"
        }], []);
    
    useEffect(
        () => {
            dispatch(setActions(actions))
        }, [dispatch, actions]);
    
    return (
        <>
            <ErrorBoundary FallbackComponent={GlobalErrorBoundaryMemo}>
                {nonSilentAuthProcessing ? (
                    <FlexLoaderMemo wrapperClassName="standalone-loader"/>
                ) : (
                    isAuthenticated ? (
                            <MetricsProvider>
                                <WebAnalyticsProvider adapter={matomoAdapter}>
                                    <RouterProvider router={createBrowserRouter(AppRoutes, { basename: routerBaseName })}/>
                                </WebAnalyticsProvider>
                            </MetricsProvider>
                    ) : (
                        <FlexLoaderMemo wrapperClassName="standalone-loader"/>
                    )
                )}
            </ErrorBoundary>
        </>
    );
};
