import { useEffect } from "react";

export const useInit = (callback: () => void) => {
    useEffect(() => {
        callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export const useInitWithCleanup = (callback: () => (() => void)) => {
    useEffect(() => {
        return callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};