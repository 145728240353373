import { useCallback, useEffect, useMemo, useState } from "react"
import { HttpServiceError } from "../../models/HttpServiceError";
import { HttpServiceTimeoutError } from "../../models/HttpServiceTimeoutError";
import { AxiosError, HttpStatusCode } from "axios";
import React from "react";
import { getBaseUrl } from "../../utils/api";
import HttpService from "../../app/services/http-service";
import { useInit } from "../../utils/custom-react-hooks";

const HttpErrorInterceptor = ({children}) => {
    const [error, setError] = useState<any>(undefined);
    const [interceptorConfigured, setInterceptorConfigured] = useState<boolean>(false);

    const appBaseUrl = useMemo(
        () => getBaseUrl(),
        []);

    const httpResponseErrorHandlerCallback = useCallback(
        (error: any) => {
            if (error.response && error.response.status) {
                if (error.response.status === HttpStatusCode.Unauthorized) {
                    window.location.href = appBaseUrl;
                } else {
                    const errorInstance = new HttpServiceError(
                        error.response.status,
                        error);

                    setError(
                        errorInstance);
                } 
            } else if (error instanceof AxiosError) {
                const axiosError = error as AxiosError;
                if (axiosError.code === 'ECONNABORTED') {
                    const timeoutErrorInstance = new HttpServiceTimeoutError(
                        HttpStatusCode.RequestTimeout,
                        error,
                        true);
                    
                        setError(
                            timeoutErrorInstance);
                }
            } else {
                setError(
                    error);
            }
        }, [appBaseUrl]);
        
    useInit(
        () => {
            HttpService.configureHttpResponseErrorHandler(
                httpResponseErrorHandlerCallback);
            // on initial render order of useEffects is from parent to children
            // when opening app again, somehow order is changed for useEffect execution order
            // to be from children (!) to parent
            // setting interceptor must be explicitly ensured to be first before any child action
            setInterceptorConfigured(true);
        });

    useEffect(
        () => {
            if (error) {
                throw error;
            };

        }, [error]);

    return(
        <>
            {interceptorConfigured ? children : <></> }
        </>
    )
};

export const HttpErrorInterceptorMemo = React.memo(HttpErrorInterceptor);