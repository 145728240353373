import { Outlet } from 'react-router';
import './HeartFailureLayout.scss';
import React from 'react';
import { PracticeWidgetMemo } from '../PracticeWidget/PracticeWidget';

const HeartFailureLayout = () => {

    return(
        <div className='heartfailure-layout-module-wrapper'>
            <PracticeWidgetMemo></PracticeWidgetMemo>
            <div className="heartfailure-layout-module-container">
                <div className="heartfailure-layout-router-container">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export const HeartFailureLayoutMemo = React.memo(HeartFailureLayout);