import {useAppSelector} from "../../app/hooks";
import {selectPracticeSelector} from "../../app/selectors/practiceSelectors";
import React, {ReactNode, useEffect} from "react";
import { useWebAnalytics } from '@e360/react-web-analytics';

interface WebAnalyticsTrackerProps {
    children?: ReactNode;
}

const WebAnalyticsTracker: React.FunctionComponent<WebAnalyticsTrackerProps> = ({children}) => {
    const { trackPageView } = useWebAnalytics();
    const practiceData = useAppSelector(selectPracticeSelector);

    useEffect(() => {
        const enabled = window.appSettings.webAnalyticsProvider.enabled ?? false;
        if (enabled && practiceData.practiceInfo?.id) {
            trackPageView({
                customDimensions: {
                    practiceId: practiceData.practiceInfo.id,
                },
                setCustomDimensions: false
            });
        }
    }, [trackPageView, practiceData]);
    
    return <>{children}</>;
};

export const WebAnalyticsTrackerMemo = React.memo(WebAnalyticsTracker);