import React, { useMemo } from 'react';
import './AccessDeniedError.scss';
import { ReactSVG } from 'react-svg';
import { ICON_NAME } from '../../../utils/icon-name';
import { ErrorBlue, ErrorGray } from '../../../utils/palette';
import { GoHomeContactSupportButtonsMemo } from '../GoHomeContactSupportButtons/GoHomeContactSupportButtons';
import { getIconUrl } from '../../../utils/api';

const AccessDeniedError = () => {
    const companyIconUrl = useMemo(
        () => getIconUrl(ICON_NAME.COMPANY_LOGO_HORIZONTAL),
        []);

    const sadFaceIconUrl = useMemo(
        () => getIconUrl(ICON_NAME.SAD_FACE),
        []);

    return(
        <div className='access-denied-container'>
            <ReactSVG
                className='access-denied-company-icon'
                src={companyIconUrl}
                afterInjection={(svg) => {
                    svg.setAttribute('style', 'height: 50px; width: 150px')
                }}/>
            <ReactSVG
                className='access-denied-sad-face-icon'
                src={sadFaceIconUrl}
                afterInjection={(svg) => {
                    svg.setAttribute('style', 'height: 90px; width: 90px')
                }}/>

            <h1
                className='access-denied-title'
                style={{color: ErrorBlue}}>
                Access Denied
            </h1>
            <p
                className='access-denied-description'
                style={{color: ErrorGray}}>
                Sorry, you do not have sufficient permissions to access this content.
            </p>
            <div className='access-denied-buttons-container-wrapper'>
                <GoHomeContactSupportButtonsMemo/>
            </div>
        </div>
    );
}

export const AccessDeniedErrorMemo = React.memo(AccessDeniedError)