import React from 'react';
import './OopsSomethingWentWrong.scss';
import { ErrorBlue } from '../../../utils/palette';
import { GoHomeContactSupportButtonsMemo } from '../GoHomeContactSupportButtons/GoHomeContactSupportButtons';

const OopsSomethingWentWrong = () => {
    return(
        <div className='oops-something-went-wrong-container'>
            <h1 
                className='oops-something-went-wrong-title'
                style={{color: ErrorBlue}}>
                Oops, something went wrong...
            </h1>
            <div className='oops-something-went-wrong-buttons-container-wrapper'>
                <GoHomeContactSupportButtonsMemo/>
            </div>
        </div>
    );
}

export const OopsSomethingWentWrongMemo = React.memo(OopsSomethingWentWrong)