import './PatientsList.scss';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Card, CardBody, CardTitle} from "@e360/react-core";
import {PagedPatientsList} from "../../../../models/PagedPatientsList";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectPracticeSelector} from "../../../../app/selectors/practiceSelectors";
import {selectMarketId} from "../../../../app/selectors/marketSelectors";
import {fetchPracticeMarketPagedPatientsList, updatePracticeMarketPatientData} from "../../../../app/api/patientsApi";
import {PaginationCriteria} from "../../../../models/PaginationCriteria";
import {PatientsListCriteria} from "../../../../models/PatientsListCriteria";
import {PatientEntry} from "../../../../models/PatientEntry";
import {
    DataTable,
    DataTableDataSelectableEvent,
    DataTableEditingRows,
    DataTableFilterMetaData,
    DataTableRowEditCompleteEvent,
    DataTableRowEditEvent,
    DataTableSortEvent,
    DataTableStateEvent,
} from 'primereact/datatable';
import {
    Column,
    ColumnBodyOptions,
    ColumnEditorOptions,
    ColumnFilterElementTemplateOptions,
    ColumnProps
} from 'primereact/column';
import {Paginator, PaginatorPageChangeEvent} from 'primereact/paginator';
import {getIconUrl} from "../../../../utils/api";
import {LvsdStatusEnum} from "../../../../models/LvsdStatusEnum";
import {formatDate} from "../../../../utils/date";
import {DATE_FORMAT} from "../../../../utils/date-format";
import {nameof} from "../../../../utils/nameof";
import {Dropdown, DropdownChangeEvent, DropdownProps} from "primereact/dropdown";
import _ from "lodash";
import {BlueLoader} from "../../../BlueLoader/BlueLoader";
import {PatientsListColumnsSettingsBoxMemo} from "./PatientsListColumnsSettingsBox/PatientsListColumnsSettingsBox";
import {PatientEntryUpdateModel} from "../../../../models/PatientEntryUpdateModel";
import {PatientsListQuickFilterMemo} from "./PatientsListQuickFilter/PatientsListQuickFilter";
import {HFXXXTableCellMemo, useHFXXXFilteringProps} from "./HFXXXTableCell/HFXXXTableCell";
import {HFXXXExcTableCellMemo} from "./HFXXXExcTableCell/HFXXXExcTableCell";
import {Tooltip} from 'primereact/tooltip';
import {loggedUserSelector} from "../../../../app/selectors/authSelector";
import {UserColumnSettings} from "../../../../models/UserColumnSettings";
import {UserColumnSettingsViewModel} from "../../../../models/UserColumnSettingsViewModel";
import {fetchUserColumnsSettings} from "../../../../app/api/userPreferencesApi";
import {classNames} from "primereact/utils";
import {ColumnFilterViewModel} from "../../../../models/ColumnFilterViewModel";
import {ExportSectionMemo} from "./ExportSection/ExportSection";
import {PatientsBulkEditMemo} from "./PatientsBulkEdit/PatientsBulkEdit";
import {
    DECISIONS,
    EMPTY_DROPDOWN_OPTION_LABEL, EMPTY_DROPDOWN_OPTION_VALUE,
    HEADER_FILTERS_DISABLED_IN_EDIT_MODE_MESSAGE,
    LVSD_STATUS_ICONS,
    SNOMED_CODES_BY_QOF_ACCEPTED_CODE_TERM,
    TYPE_OF_USERS
} from "./patients-list-constants";
import {ColumnSortingCriteriaViewModel} from "../../../../models/ColumnSortingCriteriaViewModel";
import {SortingDirectionEnum} from "../../../../models/SortingDirectionEnum";
import {
    hf003MetricDescription,
    hf006MetricDescription,
    hf007MetricDescription,
    hf008MetricDescription
} from "../../HfCommonItems";
import {InputTextarea} from "primereact/inputtextarea";
import {useCustomEvent} from "../../../../utils/useCustomEvent";
import {
    patientsListFiltersSelector, patientsListPaginationSelector,
    patientsListSortingSelector
} from "../../../../app/selectors/patientsListSelectors";
import {
    goToPatientsListFirstPage,
    setFirstPage,
    setHeaderFilters, 
    setPaginationCriteria,
    setQuickFilterKey, 
    setRowsNumber,
    setSortedColumns,
    setTotalRecordsCount
} from "../../../../app/slices/patientsListSlice";

const enum PatientListCustomEvent {
    ScrollToFirstEditableColumn = 'scroll-to-first-editable-col',
    ScrollToLastLastSavedPosition = 'scroll-to-last-last-saved-position'
}

export const FrozenColumnsSeparatorKey = 'frozen-columns-separator';

type ColumnKeyToName = Record<string, string>;
const columnKeyToNameMapping: ColumnKeyToName = {
    [FrozenColumnsSeparatorKey]: 'Freeze columns above this line',
    [nameof<PatientEntry>('patientId')]: 'Patient ID',
    [nameof<PatientEntry>('nhsNumber')]: 'NHS Number',
    [nameof<PatientEntry>('name')]: 'Name',
    [nameof<PatientEntry>('dateOfBirth')]: 'Date of Birth',
    [nameof<PatientEntry>('age')]: 'Age',
    [nameof<PatientEntry>('gender')]: 'Sex',
    [nameof<PatientEntry>('qofHfCode')]: 'QOF HF Code',
    [nameof<PatientEntry>('hfDate')]: 'HF Date',
    [nameof<PatientEntry>('lvsdStatus')]: 'LVSD Status',
    [nameof<PatientEntry>('addedBy')]: 'Added By',
    [nameof<PatientEntry>('decision')]: 'Decision',
    [nameof<PatientEntry>('authorizer')]: 'Authoriser',
    [nameof<PatientEntry>('qofAcceptedCodeTerm')]: 'QOF Accepted Code Term',
    [nameof<PatientEntry>('snomedCode')]: 'SNOMED Code',
    [nameof<PatientEntry>('comments')]: 'Comments',
    [nameof<PatientEntry>('hf001')]: 'HF001',
    [nameof<PatientEntry>('hf003')]: 'HF003',
    [nameof<PatientEntry>('hf006')]: 'HF006',
    [nameof<PatientEntry>('hf007')]: 'HF007',
    [nameof<PatientEntry>('hf008')]: 'HF008',
    [nameof<PatientEntry>('hf003Exc')]: 'HF003 Exc',
    [nameof<PatientEntry>('hf006Exc')]: 'HF006 Exc',
    [nameof<PatientEntry>('hf007Exc')]: 'HF007 Exc',
    [nameof<PatientEntry>('hf008Exc')]: 'HF008 Exc',
    [nameof<PatientEntry>('potentialLvsdCode')]: 'Potential LVSD Code',
    [nameof<PatientEntry>('lvsdDate')]: 'LVSD Date',
    [nameof<PatientEntry>('hoEchocardiagram')]: 'H/O Echocardiogram',
    [nameof<PatientEntry>('treatmentLevel')]: 'Treatment Level',
    [nameof<PatientEntry>('preserveEfCode')]: 'Preserved EF Code',
    [nameof<PatientEntry>('efDate')]: 'Preserved EF Date',
    [nameof<PatientEntry>('efRecord')]: 'EF Record',
    [nameof<PatientEntry>('timestamp')]: 'Updated On',
};

const defaultColumnSettings : UserColumnSettingsViewModel[] = [
    { columnKey: nameof<PatientEntry>('patientId'), position: 1, isVisible: true, isFrozen: true },
    { columnKey: nameof<PatientEntry>('nhsNumber'), position: 2, isVisible: true, isFrozen: true },
    { columnKey: FrozenColumnsSeparatorKey, position: 3, isVisible: true, isFrozen: true },
    { columnKey: nameof<PatientEntry>('name'), position: 4, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('dateOfBirth'), position: 5, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('age'), position: 6, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('gender'), position: 7, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('qofHfCode'), position: 8, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('hfDate'), position: 9, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('lvsdStatus'), position: 10, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('potentialLvsdCode'), position: 11, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('lvsdDate'), position: 12, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('hoEchocardiagram'), position: 13, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('treatmentLevel'), position: 14, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('preserveEfCode'), position: 15, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('efDate'), position: 16, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('efRecord'), position: 17, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('comments'), position: 18, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('decision'), position: 19, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('authorizer'), position: 20, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('addedBy'), position: 21, isVisible: true, isFrozen: false },    
    { columnKey: nameof<PatientEntry>('qofAcceptedCodeTerm'), position: 22, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('snomedCode'), position: 23, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('hf001'), position: 24, isVisible: false, isFrozen: false },
    { columnKey: nameof<PatientEntry>('hf003'), position: 25, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('hf006'), position: 26, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('hf007'), position: 27, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('hf008'), position: 28, isVisible: true, isFrozen: false },
    { columnKey: nameof<PatientEntry>('hf003Exc'), position: 29, isVisible: false, isFrozen: false },
    { columnKey: nameof<PatientEntry>('hf006Exc'), position: 30, isVisible: false, isFrozen: false },
    { columnKey: nameof<PatientEntry>('hf007Exc'), position: 31, isVisible: false, isFrozen: false },
    { columnKey: nameof<PatientEntry>('hf008Exc'), position: 32, isVisible: false, isFrozen: false },   
    { columnKey: nameof<PatientEntry>('timestamp'), position: 33, isVisible: true, isFrozen: false },    
];

const headerTooltips = [
    { columnKey: nameof<PatientEntry>('hf003'), tooltipContent: hf003MetricDescription },
    { columnKey: nameof<PatientEntry>('hf006'), tooltipContent: hf006MetricDescription },
    { columnKey: nameof<PatientEntry>('hf007'), tooltipContent: hf007MetricDescription },
    { columnKey: nameof<PatientEntry>('hf008'), tooltipContent: hf008MetricDescription },
]

const rowsPerPageOptions = [25, 50, 100];

const patientListTableWrapperClass = 'patients-list-wrapper';

const PatientsDataTable = DataTable<PatientEntry[]>

interface PatientsListProps {
    onDataChange: () => void;
}

const PatientsList: React.FC<PatientsListProps> = ({onDataChange}) => {
    const dispatch = useAppDispatch();    
    const { practiceInfo } = useAppSelector(selectPracticeSelector);
    const marketId = useAppSelector(selectMarketId);
    const loggedUser = useAppSelector(loggedUserSelector);
    const { sortedColumns } = useAppSelector(patientsListSortingSelector);
    const {
        quickFilterKey,
        headerFilters
    } = useAppSelector(patientsListFiltersSelector);
    const {
        firstPage,
        rowsNumber,
        totalRecordsCount,
        paginationCriteria: currentPaginationCriteria
    } = useAppSelector(patientsListPaginationSelector);
    
    const patientsDataTableRef = useRef<DataTable<PatientEntry[]>>(null);

    const [showTable, setShowTable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [pagedPatientsList, setPagedPatientsList] = useState<PagedPatientsList|undefined>(undefined);
    const [rows, setRows] = useState<PatientEntry[]>([]);
    const [selectedRows, setSelectedRows] = useState<PatientEntry[]>([]);
    const [rowsInEditMode, setRowsInEditMode] = useState<DataTableEditingRows>({});
    const [scrollbarPosition, setScrollbarPosition] = useState<number>(0);
    
    const headerFiltersEnabled = useMemo(() => 
        Object.keys(rowsInEditMode).length === 0, [rowsInEditMode]);
    
    const closeAllEditingRows = useCallback(() => {
        setRowsInEditMode({});
    }, []);
    
    const assignNamesToUserColumnsSettings = useCallback((userColumnSettingsViewModels: UserColumnSettingsViewModel[]) : UserColumnSettings[] =>
        userColumnSettingsViewModels.map(ucs => {
            const columnName = columnKeyToNameMapping[ucs.columnKey ?? ''] ?? '';
            return {
                ...ucs,
                columnName
            } as UserColumnSettings;
        }), []);

    const [currentColumnSettings, setCurrentColumnSettings] = useState<UserColumnSettings[]>(assignNamesToUserColumnsSettings(defaultColumnSettings));

    const getColumnFilters = useCallback(() => {
        return _.chain(Object.keys(headerFilters))
            .map(column => {
            const columnFilterMetadata = headerFilters[column] as DataTableFilterMetaData;
            if (columnFilterMetadata.value !== undefined) {
                return {
                    column: column,
                    filterValue: columnFilterMetadata.value?.toString()
                } as ColumnFilterViewModel
            }
            return null;
            })
            .filter(cf => !_.isEmpty(cf))
            .value() as ColumnFilterViewModel[];
    }, [headerFilters]);
    
    const getColumnsSortingCriteria = useCallback(() => {
        const getSortingDirection = (symbol: 0 | 1 | -1 | undefined | null) => {
            switch (symbol){
                case -1:
                    return SortingDirectionEnum.DESCENDING
                case 1:
                default:
                    return SortingDirectionEnum.ASCENDING
            }
        };        
        return _.chain(sortedColumns)
            .map(sc => {
                return {
                    column: sc.field,
                    sortingDirection: getSortingDirection(sc.order)
                } as ColumnSortingCriteriaViewModel
            }).value();
    }, [sortedColumns]);

    const getPatientTableScrollableElement = useCallback(() => {
        const scrollableElement = patientsDataTableRef?.current?.getElement();
        const scrollClassSelector = scrollableElement?.getAttribute('data-scrollselectors')?.substring(1) ?? '';
        return document.getElementsByClassName(scrollClassSelector)[0];
    }, [patientsDataTableRef]);

    const saveCurrentPatientTableScrollPosition = useCallback(() => {
        const scrollableElement = getPatientTableScrollableElement();
        setScrollbarPosition(scrollableElement?.scrollLeft);
    }, [getPatientTableScrollableElement]);
    
    const getPagedPatientsList = useCallback(async (
        paginationCriteria: PaginationCriteria, 
        columnFilters: ColumnFilterViewModel[],
        columnsSortingCriteria: ColumnSortingCriteriaViewModel[],
        quickFilterKey: string | null = null
    ) => {
        if (!practiceInfo) { 
            return;
        }
        saveCurrentPatientTableScrollPosition();
        setLoading(true);
        dispatch(setPaginationCriteria(paginationCriteria));
        const criteria : PatientsListCriteria = {
            paginationCriteria: paginationCriteria,
            columnFilters: columnFilters,
            quickFilterKey: quickFilterKey,
            columnsSortingCriteria: columnsSortingCriteria
        };        
        
        fetchPracticeMarketPagedPatientsList(
            practiceInfo!.id,
            criteria)
                .then((results) => { 
                    if (results) {
                        setPagedPatientsList(results);
                        dispatch(setTotalRecordsCount(results.totalItemsCount));
                        setRows(results.items)
                    }
                }).finally(() => {
                    setShowTable(true);
                    setLoading(false);
        });
    }, [practiceInfo, saveCurrentPatientTableScrollPosition, dispatch]);

    const getUserColumnsSettings = useCallback(async () => {
        if (!practiceInfo || !marketId) {
            return;
        }
        fetchUserColumnsSettings()
            .then((userColumnSettings) => {
                if (!_.isEmpty(userColumnSettings)) {
                    setCurrentColumnSettings(assignNamesToUserColumnsSettings(userColumnSettings));
                } else {
                    setCurrentColumnSettings(assignNamesToUserColumnsSettings(defaultColumnSettings));
                }
            }).catch(() => {
                console.error('Could not load user columns settings. Restoring the default state...')
                setCurrentColumnSettings(assignNamesToUserColumnsSettings(defaultColumnSettings));
        });
    }, [practiceInfo, marketId, assignNamesToUserColumnsSettings]);
    
    const getColumnProps = (fieldName: string) : Partial<ColumnProps> => {
        return {
            columnKey: fieldName,
            field: fieldName,
            sortable: true,
            filter: false,
            className: `${fieldName}-col`
        };
    };
    
    const getSnomedCodesByQofAcceptedCodeTerm = useCallback((qofAcceptedCodeTerm: string | null) =>
        qofAcceptedCodeTerm && Object.keys(SNOMED_CODES_BY_QOF_ACCEPTED_CODE_TERM).includes(qofAcceptedCodeTerm)
            ? SNOMED_CODES_BY_QOF_ACCEPTED_CODE_TERM[qofAcceptedCodeTerm]
            : null, []);
    
    const updateRowValues = useCallback((newRowData: PatientEntry) => {
        const patientId = newRowData.patientId;
        const rowIndex = rows.findIndex(r => r.patientId === patientId);
        let _rows = [...rows];
        _rows[rowIndex] = newRowData;
        setRows(_rows);
    }, [rows]);
    
    const onQofAcceptedCodeTermChanged = useCallback((evt: DropdownChangeEvent, options: ColumnEditorOptions) => {
        const newValue = evt.value;
        const patientId = (options.rowData as PatientEntry).patientId;
        const rowData = rows.find(r => r.patientId === patientId);
        if (!rowData){
            return;
        }
        const rowDataWithAdjustedSnomedCode = {
            ...rowData,
            qofAcceptedCodeTerm: newValue,
            snomedCode: getSnomedCodesByQofAcceptedCodeTerm(newValue)
        };
        updateRowValues(rowDataWithAdjustedSnomedCode);
        options.editorCallback!(newValue);
    }, [updateRowValues, getSnomedCodesByQofAcceptedCodeTerm, rows]);

    const onDecisionChanged = useCallback((evt: DropdownChangeEvent, options: ColumnEditorOptions) => {
        const newValue = evt.value;
        const patientId = (options.rowData as PatientEntry).patientId;
        const rowData = rows.find(r => r.patientId === patientId);
        if (!rowData){
            return;
        }
        const rowDataWithAdjustedAuthorizedValue = {
            ...rowData,
            decision: newValue,
            authorizer: loggedUser?.name ?? null
        };
        updateRowValues(rowDataWithAdjustedAuthorizedValue);
        options.editorCallback!(newValue);
    }, [updateRowValues, loggedUser, rows]);
    
    const headerFilterDropdownCommonProps: DropdownProps = useMemo(() => ({
        disabled: !headerFiltersEnabled,
        tooltip: headerFiltersEnabled ? undefined : HEADER_FILTERS_DISABLED_IN_EDIT_MODE_MESSAGE,
        tooltipOptions: {showOnDisabled: true},
        showClear: true
    }), [headerFiltersEnabled]);
    
    const hfxxxColumnsProps = useHFXXXFilteringProps(headerFiltersEnabled);
    const columnDefinitions = useMemo(() => [
            {
                ...getColumnProps(nameof<PatientEntry>('patientId')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('nhsNumber')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('name')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('dateOfBirth')),
                dataType: 'date',
                body: (rowData: PatientEntry) =>
                    formatDate(rowData.dateOfBirth, DATE_FORMAT.dd_mm_yyyy_SLASHED),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('age')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('gender')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('qofHfCode')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('hfDate')),
                dataType: 'date',
                body: (rowData: PatientEntry) => rowData.hfDate ?
                    formatDate(rowData.hfDate, DATE_FORMAT.dd_mm_yyyy_SLASHED) : null
            },
            {
                ...getColumnProps(nameof<PatientEntry>('lvsdStatus')),
                body: (rowData: PatientEntry) => {
                    const lvsdStatusIcon = LVSD_STATUS_ICONS.find(ls => ls.status === rowData.lvsdStatus);
                    if (!lvsdStatusIcon) {
                        return <></>
                    }
                    const iconSrc = getIconUrl(lvsdStatusIcon.icon);
                    return (
                        <div className='lvsd-status-icon-wrapper'>
                            <Tooltip target=".lvsd-status-icon" />
                            <img
                                className={classNames("lvsd-status-icon", `lvsd-status-${rowData.lvsdStatus}`)}
                                data-pr-tooltip={lvsdStatusIcon.name}
                                data-pr-position="left"
                                alt={iconSrc}
                                src={iconSrc}
                                style={{ width: '24px'}} />
                        </div>);
                },
                filter: true,
                showFilterMenu: false,
                filterElement: (options: ColumnFilterElementTemplateOptions) => {
                    return (
                        <Dropdown {...headerFilterDropdownCommonProps}
                            value={options.value} 
                            options={LVSD_STATUS_ICONS.map(i => i.status)} 
                            onChange={(e) => options.filterApplyCallback(e.value)}
                            itemTemplate={(statusEnum: LvsdStatusEnum) => {
                                const lvsdStatusIcon = LVSD_STATUS_ICONS.find(ls => ls.status === statusEnum);
                                if (!lvsdStatusIcon) {
                                    return <></>;
                                }
                                const iconSrc = getIconUrl(lvsdStatusIcon.icon);
                                return <div>
                                    <img alt={iconSrc} src={iconSrc} style={{ width: '24px', marginRight: '1rem' }} />
                                    <span>{lvsdStatusIcon.name}</span>
                                </div> 
                            }}
                            valueTemplate={(statusEnum: LvsdStatusEnum, props: DropdownProps) => {
                                const lvsdStatusIcon = LVSD_STATUS_ICONS.find(ls => ls.status === statusEnum);
                                if(!lvsdStatusIcon) {
                                    return <span>{props.placeholder}</span>;
                                }
                                const iconSrc = getIconUrl(lvsdStatusIcon.icon);
                                return <div>
                                    <img alt={iconSrc} src={iconSrc} style={{ width: '24px', marginRight: '1rem' }} />
                                    <span>{lvsdStatusIcon.name}</span>
                                </div>
                            }}
                            placeholder="LVSD Status"
                        />
                    );
                }
            },
            {
                ...getColumnProps(nameof<PatientEntry>('addedBy')),
                editor: (options) => {
                    return (
                        <Dropdown
                            value={options.value}
                            options={TYPE_OF_USERS}
                            onChange={(e) => options.editorCallback!(e.value)}
                            placeholder="Select Added By"
                        />
                    );
                }
            },
            {
                ...getColumnProps(nameof<PatientEntry>('decision')),
                filter: true,
                showFilterMenu: false,
                filterElement: (options: ColumnFilterElementTemplateOptions) => {
                    const filterableDecisions = [EMPTY_DROPDOWN_OPTION_VALUE, ...DECISIONS];
                    return (
                        <Dropdown {...headerFilterDropdownCommonProps}
                            value={options.value}
                            options={filterableDecisions}
                            onChange={(e) => options.filterApplyCallback(e.value)}
                            placeholder="Decision" 
                            itemTemplate={(decision: string) => decision === '' ? EMPTY_DROPDOWN_OPTION_LABEL : decision} 
                            valueTemplate={(decision: string, props: DropdownProps) =>
                                decision === ''
                                    ? EMPTY_DROPDOWN_OPTION_LABEL
                                    : (decision ?? props.placeholder)
                            }                      
                        />
                    );
                },
                editor: (options) => {
                    return (
                        <Dropdown
                            value={options.value}
                            options={DECISIONS}
                            onChange={(e) => onDecisionChanged(e, options)}
                            placeholder="Select Decision"
                        />
                    );
                }
            },            
            {
                ...getColumnProps(nameof<PatientEntry>('authorizer'))
            },
            {
                ...getColumnProps(nameof<PatientEntry>('qofAcceptedCodeTerm')),
                editor: (options) => {
                    return (
                        <Dropdown
                            value={options.value}
                            options={Object.keys(SNOMED_CODES_BY_QOF_ACCEPTED_CODE_TERM)}
                            onChange={(e) => onQofAcceptedCodeTermChanged(e, options)}
                            placeholder="Select QOF Accepted Code Term"
                        />
                    );
                },  
            },
            {
                ...getColumnProps(nameof<PatientEntry>('snomedCode')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('comments')),                
                editor: (options) => {
                    return <InputTextarea
                        className="comments-input-text"
                        maxLength={255}
                        value={options.value}
                        placeholder="Comments"
                        onChange={(e) => {
                            options.editorCallback!(e.target.value);
                        }}
                        rows={1} 
                        cols={30} 
                        onFocus={(e) => {
                            e.target.rows = 3;
                        }}
                        onBlur={(e) => {
                            e.target.rows = 1;
                        }}
                    />
                        
                },
                body: (rowData: PatientEntry, options: ColumnBodyOptions) => {
                    const displayableLenght = 50;
                    const commentLength = rowData.comments?.length ?? 0;
                    if (commentLength > displayableLenght) {
                        const cellId = `comment-${rowData.patientId}-${options.rowIndex}`
                        return <React.Fragment key={cellId}>
                            <Tooltip 
                                target={`#${cellId}`}
                            />
                            <div 
                                id={cellId} 
                                data-pr-tooltip={rowData.comments ?? ''}
                                data-pr-position='mouse'
                            >
                                {rowData.comments?.slice(0, displayableLenght)}...
                            </div>
                        </React.Fragment>
                    }
                    return rowData.comments;
                }
                
            },
            {
                ...getColumnProps(nameof<PatientEntry>('hf001')),
                ...hfxxxColumnsProps,
                body: (rowData: PatientEntry) => <HFXXXTableCellMemo cellValue={rowData.hf001} />
            },
            {
                ...getColumnProps(nameof<PatientEntry>('hf003')),
                ...hfxxxColumnsProps,
                body: (rowData: PatientEntry) => <HFXXXTableCellMemo cellValue={rowData.hf003} />,
            },
            {
                ...getColumnProps(nameof<PatientEntry>('hf006')),
                ...hfxxxColumnsProps,
                body: (rowData: PatientEntry) => <HFXXXTableCellMemo cellValue={rowData.hf006} />
            },
            {
                ...getColumnProps(nameof<PatientEntry>('hf007')),
                ...hfxxxColumnsProps,
                body: (rowData: PatientEntry) => <HFXXXTableCellMemo cellValue={rowData.hf007} />
            },
            {
                ...getColumnProps(nameof<PatientEntry>('hf008')),
                ...hfxxxColumnsProps,
                body: (rowData: PatientEntry) => <HFXXXTableCellMemo cellValue={rowData.hf008} />
            },
            {
                ...getColumnProps(nameof<PatientEntry>('hf003Exc')),
                body: (rowData: PatientEntry) => <HFXXXExcTableCellMemo cellValue={rowData.hf003Exc} />
            },
            {
                ...getColumnProps(nameof<PatientEntry>('hf006Exc')),
                body: (rowData: PatientEntry) => <HFXXXExcTableCellMemo cellValue={rowData.hf006Exc} />
            },
            {
                ...getColumnProps(nameof<PatientEntry>('hf007Exc')),
                body: (rowData: PatientEntry) => <HFXXXExcTableCellMemo cellValue={rowData.hf007Exc} />
            },
            {
                ...getColumnProps(nameof<PatientEntry>('hf008Exc')),
                body: (rowData: PatientEntry) => <HFXXXExcTableCellMemo cellValue={rowData.hf008Exc} />
            },
            {
                ...getColumnProps(nameof<PatientEntry>('potentialLvsdCode')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('lvsdDate')),
                dataType: 'date',
                body: (rowData: PatientEntry) => rowData.lvsdDate ?
                    formatDate(rowData.lvsdDate, DATE_FORMAT.dd_mm_yyyy_SLASHED) : null,
            },
            {
                ...getColumnProps(nameof<PatientEntry>('hoEchocardiagram')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('treatmentLevel')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('preserveEfCode')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('efDate')),
                dataType: 'date',
                body: (rowData: PatientEntry) => rowData.efDate ?
                    formatDate(rowData.efDate, DATE_FORMAT.dd_mm_yyyy_SLASHED) : null,
            },
            {
                ...getColumnProps(nameof<PatientEntry>('efRecord')),
            },
            {
                ...getColumnProps(nameof<PatientEntry>('timestamp')),
                dataType: 'date',
                body: (rowData: PatientEntry) => rowData.timestamp ?
                    formatDate(rowData.timestamp, DATE_FORMAT.dd_mm_yyyy_HH_MM_ss_SLASHED) : null,
            },
        ] as ColumnProps[], [hfxxxColumnsProps, onDecisionChanged, onQofAcceptedCodeTermChanged, headerFilterDropdownCommonProps]);

    const refreshPagedPatientsList = useCallback(() => {
        const columnFilters = getColumnFilters();
        const columnsSortingCriteria = getColumnsSortingCriteria();
        getPagedPatientsList(currentPaginationCriteria, columnFilters, columnsSortingCriteria, quickFilterKey);
    }, [getPagedPatientsList, currentPaginationCriteria, getColumnFilters, quickFilterKey, getColumnsSortingCriteria]);
    
    const restorePatientTableScrollPosition = useCallback(() => {
        const hasRows = rows?.length > 0;
        if (hasRows) {
            getPatientTableScrollableElement()
                ?.scrollTo(scrollbarPosition, 0);
        }
    }, [getPatientTableScrollableElement, rows, scrollbarPosition]);

    const {
        publishEvent: publishScrollToLastSavedPositionEvent
    } = useCustomEvent(PatientListCustomEvent.ScrollToLastLastSavedPosition, restorePatientTableScrollPosition);
    
    useEffect(() => {
        if (!loading){
            publishScrollToLastSavedPositionEvent();
        }
    }, [publishScrollToLastSavedPositionEvent, loading]);
    
    useEffect(() => {
        setShowTable(false);
        closeAllEditingRows();
    }, [closeAllEditingRows]);

    useEffect(() => {
        setSelectedRows(
            Array.from<PatientEntry>([]));
    }, [practiceInfo])

    useEffect(() => {
        getUserColumnsSettings()
            .finally(() => {
                refreshPagedPatientsList();
            });
    }, [getUserColumnsSettings, refreshPagedPatientsList]);
    
    const scrollToFirstEditableColumn = useCallback(() => {
        const firstEditableColumnClass =
            _.chain(
                _.merge(
                    _.keyBy(columnDefinitions, nameof<ColumnProps>('field')),
                    _.keyBy(currentColumnSettings, nameof<UserColumnSettings>('columnKey'))
                )
            )
                .filter(m => m.editor !== undefined)
                .sortBy(m => m.position)
                .map(m => m.className)
                .first()
                .value();
        
        if (firstEditableColumnClass) {
            getPatientTableScrollableElement().getElementsByClassName(firstEditableColumnClass)[0]?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center"
            });
        }
    }, [columnDefinitions, currentColumnSettings, getPatientTableScrollableElement]);
    
    const deselectRows = useCallback((patientIds?: string[]) => {
        let updatedSelectedRows = Array.from<PatientEntry>([]);
        if (patientIds) {
            updatedSelectedRows = _.reject(selectedRows, row => patientIds.includes(row.patientId));
        }
        setSelectedRows(updatedSelectedRows);
    }, [selectedRows]);

    const {
        publishEvent: publishScrollToFirstEditableColumnEvent
    } = useCustomEvent(PatientListCustomEvent.ScrollToFirstEditableColumn, scrollToFirstEditableColumn);
    
    const onRowEditInit = useCallback(() => {
        publishScrollToFirstEditableColumnEvent();
    }, [publishScrollToFirstEditableColumnEvent]);

    const onRowEditCancel = useCallback((evt: DataTableRowEditEvent) => {
        let { data } = evt;
        const row = data as PatientEntry;
        const rowInitialValues = _.find(pagedPatientsList?.items, pe => pe.patientId === row.patientId)!;

        if (!rowInitialValues) {
            console.error('Could not find original entry of the updating row!');
            return;
        }        
        updateRowValues(rowInitialValues);
    }, [pagedPatientsList?.items, updateRowValues]);
    
    const updatePatientRow = useCallback((rowIndex: number, newPatientRowData: PatientEntry, oldPatientData: PatientEntry) => {
        setLoading(true);

        const patientEntryUpdateModel = newPatientRowData as PatientEntryUpdateModel;
        updateRowValues(newPatientRowData);
        updatePracticeMarketPatientData(
            practiceInfo!.id,
            marketId!,
            patientEntryUpdateModel)
            .then((updatedPatientEntry) => {
                if (updatedPatientEntry) {
                    updateRowValues(updatedPatientEntry);
                    onDataChange();
                    console.log(`Row '${rowIndex}' updated successfully.`);
                    refreshPagedPatientsList();
                }
            })
            .catch((_) => {
                oldPatientData.snomedCode = getSnomedCodesByQofAcceptedCodeTerm(oldPatientData.qofAcceptedCodeTerm);
                updateRowValues(oldPatientData);
            })
            .finally(() => {
                setLoading(false);
            });
    },[practiceInfo, marketId, updateRowValues, getSnomedCodesByQofAcceptedCodeTerm, onDataChange, refreshPagedPatientsList]);

    const onRowEditComplete = useCallback((evt: DataTableRowEditCompleteEvent) => {
        let {index: rowIndex, newData} = evt;
        const newRowValues = newData as PatientEntry;
        const oldRowValues = _.find(pagedPatientsList?.items, pe => pe.patientId === newRowValues.patientId);
        if (!oldRowValues) {
            console.error('Could not find original entry of the updated row!');
            return;
        }

        let executeSave = false;
        if (newRowValues.addedBy !== oldRowValues.addedBy) {
            executeSave = TYPE_OF_USERS.includes(newRowValues.addedBy ?? '');
        }
        if (newRowValues.decision !== oldRowValues.decision) {
            if(DECISIONS.includes(newRowValues.decision ?? '')) {
                newRowValues.authorizer = loggedUser?.name ?? null;
                executeSave = true;
            }
        }
        if (newRowValues.comments !== oldRowValues.comments) {
            executeSave = true;
        }
        if (newRowValues.qofAcceptedCodeTerm !== oldRowValues.qofAcceptedCodeTerm) {
            if (!newRowValues.qofAcceptedCodeTerm || Object.keys(SNOMED_CODES_BY_QOF_ACCEPTED_CODE_TERM).includes(newRowValues.qofAcceptedCodeTerm)) {
                newRowValues.snomedCode = getSnomedCodesByQofAcceptedCodeTerm(newRowValues.qofAcceptedCodeTerm);
                executeSave = true;
            }
        }

        if (executeSave) {
            updatePatientRow(rowIndex, newRowValues, oldRowValues);
        }
    }, [getSnomedCodesByQofAcceptedCodeTerm, loggedUser?.name, pagedPatientsList?.items, updatePatientRow]);
    
    const onPageChange = useCallback(async (event: PaginatorPageChangeEvent) => {
        const criteria : PaginationCriteria = {
            pageSize: event.rows,
            pageNumber: event.page + 1
        };
        closeAllEditingRows();
        dispatch(setFirstPage(event.first));
        dispatch(setRowsNumber(event.rows));
        dispatch(setTotalRecordsCount(pagedPatientsList?.totalItemsCount ?? 0));
        const columnFilters = getColumnFilters();
        const columnsSortingCriteria = getColumnsSortingCriteria();
        await getPagedPatientsList(criteria, columnFilters, columnsSortingCriteria, quickFilterKey); 
    }, [getPagedPatientsList, pagedPatientsList, getColumnFilters, quickFilterKey, getColumnsSortingCriteria, closeAllEditingRows, dispatch]);
    
    const columns: React.ReactElement[] = useMemo(() => {
        const getHeaderWithTooltip = (columnKey: string, columnName: string, tooltipContent: React.ReactNode | string) => {
            const tooltipTargetId = `${columnKey}-col-tooltip`;
            return <>
                <i id={tooltipTargetId}
                   style={{marginRight: '3px'}}
                   className="fa fa-fw fa-info-circle"
                   data-pr-position="bottom"/>
                {columnName}
                <Tooltip
                    target={`#${tooltipTargetId}`}
                    className="hf-patients-list-header-info-tooltip">
                    <strong>{columnName}</strong><br/>
                    <div>
                        {tooltipContent}
                    </div>
                </Tooltip>
            </>;
        };
        
        return _
            .chain(currentColumnSettings)
            .sortBy((cs) => cs.position)
            .filter(cs => cs.isVisible && cs.columnKey !== FrozenColumnsSeparatorKey)
            .map((cs) => {
                let style: React.CSSProperties = { minWidth: '50px' };
                if (cs.isFrozen) {
                    style = {
                        ...style,
                        zIndex: 1
                        //zIndex: 1 required for frozen columns to remain 
                        //on top while scrolling horizontally in edit mode
                    }
                }

                const columnDefinition = columnDefinitions.find(cp => cp.columnKey === cs.columnKey);
                const columnHeaderTooltip = headerTooltips.find(cp => cp.columnKey === cs.columnKey);
                const columnHeader = columnHeaderTooltip 
                    ? getHeaderWithTooltip(cs.columnKey, cs.columnName, columnHeaderTooltip.tooltipContent)
                    : cs.columnName;
                    
                return <Column {...columnDefinition}
                               key={cs.columnKey}
                               header={columnHeader}
                               frozen={cs.isFrozen}
                               alignFrozen={cs.isFrozen ? 'left' : undefined}
                               alignHeader={cs.isFrozen ? 'left' : undefined}
                               style={style}
                               headerStyle={style}
                />
            }).value() as React.ReactElement[];
    }, [columnDefinitions, currentColumnSettings]);

    const isRowSelectable = useCallback((event: DataTableDataSelectableEvent) => {
        const entry = (event.data as PatientEntry);
        return entry.lvsdStatus !== LvsdStatusEnum.LvsdConfirmed && !rowsInEditMode[entry.patientId];
    }, [rowsInEditMode]);
    
    const onHeaderFilterApplied = useCallback((event: DataTableStateEvent) => {
        deselectRows();
        dispatch(goToPatientsListFirstPage());
        dispatch(setHeaderFilters(event.filters));
    }, [deselectRows, dispatch]);

    const onQuickFilterApplied = useCallback((filterKey: string | null) => {
        deselectRows();
        closeAllEditingRows();
        dispatch(goToPatientsListFirstPage());
        dispatch(setQuickFilterKey(filterKey));
    }, [deselectRows, closeAllEditingRows, dispatch]);
    
    const onBulkEditStarted = () => {
        setLoading(true);
    }

    const onBulkEditFinished = (isSuccessful: boolean) => {
        if (isSuccessful) {
            onDataChange();
            refreshPagedPatientsList();
            deselectRows();
        } else {
            setLoading(false);
        }
    }
    
    const showPaginator = (pagedPatientsList?.totalPages ?? 0) > 0;

    const onUpdateColumnsSettingsCallback = useCallback((updatedUserColumnsSettings: UserColumnSettings[]) => {
        setCurrentColumnSettings(updatedUserColumnsSettings);
        refreshPagedPatientsList();
    }, [refreshPagedPatientsList]);

    const onColumnSort = useCallback((event: DataTableSortEvent) => {
        dispatch(setSortedColumns(event.multiSortMeta));        
    }, [dispatch]);
    
    return (
        <div className="hf-patients-list-card-container">
            <Card className="hf-patients-list-card"> 
                <CardBody>
                    <div className="card-header">
                        <div className="card-header-left">
                            <CardTitle className="card-header-title">Patients</CardTitle>
                            <PatientsListQuickFilterMemo
                                show={showTable}
                                updateQuickFilterKeyCallback={onQuickFilterApplied}
                            />
                        </div>
                        <div className="card-header-right">
                            <PatientsBulkEditMemo
                                show={showTable}
                                selectedRows={selectedRows}
                                onBulkEditStarted={onBulkEditStarted}
                                onBulkEditFinished={onBulkEditFinished}
                            />
                            <ExportSectionMemo
                                show={showTable}
                                columnFilters={getColumnFilters()}
                            />
                            <PatientsListColumnsSettingsBoxMemo
                                show={showTable}
                                columnsSettings={currentColumnSettings}
                                defaultColumnsSettings={assignNamesToUserColumnsSettings(defaultColumnSettings)}
                                updateColumnsSettingsCallback={onUpdateColumnsSettingsCallback}
                            />
                        </div>
                    </div>
                    {showTable && !loading ? (
                        <div className={patientListTableWrapperClass}>
                            <PatientsDataTable
                                ref={patientsDataTableRef}
                                key="patients-list"
                                scrollable
                                size='small'
                                loading={loading}
                                editMode="row"
                                editingRows={rowsInEditMode}
                                onRowEditChange={(e) => setRowsInEditMode(e.data)}
                                onRowEditInit={onRowEditInit}
                                onRowEditCancel={onRowEditCancel}
                                onRowEditComplete={onRowEditComplete}
                                selectionMode="checkbox"
                                selection={selectedRows}
                                selectionAutoFocus={false}
                                isDataSelectable={isRowSelectable}
                                onSelectionChange={event => setSelectedRows(event.value)}
                                dataKey="patientId"
                                removableSort={true}
                                multiSortMeta={sortedColumns}
                                sortMode="multiple"
                                onSort={onColumnSort}
                                cellSelection={false}
                                value={rows}
                                filterDisplay="row"
                                filters={headerFiltersEnabled ? headerFilters : undefined}
                                onFilter={onHeaderFilterApplied}
                                resizableColumns={true}
                                tableStyle={{
                                    width: 'max-content'
                                }}
                            >
                                    <Column
                                        selectionMode="multiple"
                                        frozen={true}
                                        alignFrozen='left'
                                        style={{ zIndex: 1 }}
                                        headerStyle={{ width: '30px' }}
                                        bodyStyle={{ width: '30px' }}
                                    />
                                    <Column
                                        rowEditor={true}
                                        frozen={true}
                                        alignFrozen='left'
                                        style={{ zIndex: 1 }}
                                        headerStyle={{ width: '88px' }}
                                        bodyStyle={{ textAlign: 'center', width: '88px' }}
                                    />
                                    {columns}
                            </PatientsDataTable>
                            {showPaginator ? (
                                <Paginator
                                    first={firstPage}
                                    rows={rowsNumber}
                                    totalRecords={totalRecordsCount}
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    onPageChange={onPageChange}
                                    template={{layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'}}
                                />
                            ) : (<></>)}
                        </div>
                    ) : ( 
                        <BlueLoader />
                    )}
                </CardBody>
            </Card>
        </div>
    );
}

export const PatientsListMemo = React.memo(PatientsList);
