//@ts-ignore
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MarketState {
    marketId: string | undefined;
}

const initialState: MarketState = {
    marketId: undefined,
};

export const marketSlice = createSlice({
  name: 'market',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setMarketId: (state: MarketState, action: PayloadAction<string>) => {
        const marketId: string = action.payload;
        state.marketId = marketId;
    }
  },
  extraReducers: (builder: any) => {
  },
});

export const { setMarketId } = marketSlice.actions;

export default marketSlice.reducer;
