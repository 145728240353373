import './PrevalenceAchievementMetrics.scss'
import React, { useCallback, useMemo, useState } from 'react';
import { Metric } from '../../../../models/Metric';
import { CardLoaderMemo } from '../../../CardLoader/CardLoader';
import {Card, CardBody, CardTitle} from '@e360/react-core';
import { MetricTypeIdEnum } from '../../../../models/MetricTypeIdEnum';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import {MetricsData} from "../../../../models/MetricsData";
import {PracticeData} from "../../../../models/PracticeData";
import {numericFormatter} from "react-number-format";
import {DEFAULT_PLOTLY_CONFIG} from "../../../../utils/plotly-commons";

interface PrevalenceAchievementMetricsProps {
    metricsData: MetricsData,
    practiceData: PracticeData
}

const PrevalenceAchievementMetrics: React.FunctionComponent<PrevalenceAchievementMetricsProps> = ({metricsData, practiceData}) => {
    const [resize, setResize] = useState<boolean>(false);

    const getMetricTypeIdCallback = useCallback(
        (metricTypeId: MetricTypeIdEnum) => {
            if (metricsData.metrics) {
                return _.find(metricsData.metrics, (metric: Metric) => metric.metricTypeId === metricTypeId);
            }
            return null;
        }, [metricsData.metrics]);

    const formatValue = (metric: Metric | undefined, decimalScale: number) => {
        return numericFormatter(metric?.value ?? '0', {
            decimalSeparator: '.',
            decimalScale: decimalScale
        });
    };
    
    const qofPointsAchieved = useMemo(
        () => {
            const metric = getMetricTypeIdCallback(
                MetricTypeIdEnum.QOF_POINTS_ACHIEVED);

            if (metric) {
                return formatValue(metric, 2);
            }

            return '';
        }, [getMetricTypeIdCallback]);


    const qofPointsTotal = useMemo(
        () => {
            const metric = getMetricTypeIdCallback(
                MetricTypeIdEnum.TOTAL_QOF_POINTS);

            if (metric) {
                return formatValue(metric, 0);
            }

            return '';
        }, [getMetricTypeIdCallback]);
    
    const qolCard = useMemo(
        () => {
            return (
                <div className='prevalence-achievement-qol-metric-wrapper'>
                    <div className='prevalence-achievement-qol-metric-labels-wrapper'>
                        <span className='prevalence-achievement-qol-metric-label'>
                            Total QOF
                        </span><span className='prevalence-achievement-qol-metric-label'>
                            Points
                        </span>
                        <span className='prevalence-achievement-qol-metric-value'>
                            {qofPointsAchieved}
                        </span>
                        <span className='prevalence-achievement-qol-metric-label'>
                            of {qofPointsTotal}
                        </span>
                    </div>
                </div>
            )
        }, [qofPointsAchieved, qofPointsTotal])

    const getMetricsArrayOrEmptyCallback = useCallback(
        (metricTypeIds: MetricTypeIdEnum[]) => {
            if(metricsData.metrics) {
                return metricTypeIds.map((
                    (metricTypeId:MetricTypeIdEnum) => getMetricTypeIdCallback(
                        metricTypeId)
                ))
            }

            return [];
        }, [metricsData.metrics, getMetricTypeIdCallback]);
    
    const practicePrevalenceMetrics = useMemo(
        () => getMetricsArrayOrEmptyCallback([
            MetricTypeIdEnum.LVSD_PREV_LAST_YR,
            MetricTypeIdEnum.HF_PREV_LAST_YR,
        ]), [getMetricsArrayOrEmptyCallback]);
    
    const cdgPrevalenceMetrics = useMemo(
        () => getMetricsArrayOrEmptyCallback([
            MetricTypeIdEnum.CDG_LVSD_PERCENT,
            MetricTypeIdEnum.CDG_HF_PERCENT,
        ]), [getMetricsArrayOrEmptyCallback]);
    
    const cdgRegionMetric = useMemo(
        () => getMetricTypeIdCallback(
            MetricTypeIdEnum.REGION_NAME),
        [getMetricTypeIdCallback]);

    const countryPrevalenceMetrics = useMemo(
        () => getMetricsArrayOrEmptyCallback([
            MetricTypeIdEnum.ENG_LVSD_PERCENT,
            MetricTypeIdEnum.ENG_HF_PERCENT,
        ]), [getMetricsArrayOrEmptyCallback]);

    // required to correctly rerender legend position
    // otherwise overlaps chart on resize
    // ie. setting fullscreen from half of screen
    const onAutosizeCallback = useCallback(
        (shouldResize: boolean)=> {
            if (metricsData.metrics && !metricsData.isLoading && !practiceData?.isLoading) {
                setResize(shouldResize);
            }
        }, [metricsData.metrics, metricsData.isLoading, practiceData.isLoading]);

    const layout: Partial<Plotly.Layout> = useMemo(
        () => {
            return { 
                autosize: true,
                margin: {
                    t: 0,
                    b: 0,
                    // otherwise hides left yaxis scale labels
                    l: 110, 
                    //i.e. when resized to half of screen
                    // otherwise hides right yaxis scale labels 
                    r: 20, 
                    pad: 0
                },
                xaxis: {
                    domain: [0, 100],
                    tickformat: '0%',
                    fixedrange: true
                },
                yaxis: {
                    side:'left',
                    fixedrange: true
                },
                legend: {
                    orientation: 'h',
                    xanchor: "center",
                    x: 0.5,
                    traceorder: 'reversed',
                    yanchor: 'top',
                },
                bargroupgap: 0.2,
                font: {
                    family: "Proxima Nova"
                }
            };
        }, []);
    
    const metricToNumberValueCallback = useCallback(
        (metric: Metric | null | undefined) => Number(metric?.value ?? -1) / 100,
        []);

    const metricsChart = useMemo(
        () => {
            const yAxisLabels = [ 
                'LVSD Prevalence',
                'HF Prevalence',
            ];
            const data : Plotly.Data[] = [
                {
                    type: 'bar',
                    name: 'England',
                    x: countryPrevalenceMetrics.map(
                        metricToNumberValueCallback),
                    y: yAxisLabels,
                    orientation: 'h',
                    marker: {
                        color: 'rgba(255,147,0,1)'
                    }
                },
                {
                    type: 'bar',
                    name: cdgRegionMetric?.value ?? 'Unknown',
                    x: cdgPrevalenceMetrics.map(
                        metricToNumberValueCallback),
                    y: yAxisLabels,
                    orientation: 'h',
                    marker: {
                        color: 'rgba(1,95,241,1)'
                    }
                },
                {
                    type: 'bar',
                    name: practiceData?.practiceInfo?.name,
                    x: practicePrevalenceMetrics.map(
                        metricToNumberValueCallback),
                    y: yAxisLabels,
                    orientation: 'h',
                    marker: {
                        color: 'rgba(147,84,176,1)'
                    }
                },
            ];
                
            return(
                <Plot
                    className='prevalence-achievement-chart-wrapper'
                    data={data}
                    layout={layout}
                    config={DEFAULT_PLOTLY_CONFIG}
                    useResizeHandler={true}
                    onAutoSize={()=> onAutosizeCallback(!resize)}
                    />);
        }, [
            resize,
            layout,
            countryPrevalenceMetrics,
            cdgPrevalenceMetrics,
            cdgRegionMetric,
            practicePrevalenceMetrics,
            practiceData.practiceInfo,
            onAutosizeCallback,
            metricToNumberValueCallback,
        ]);

    return (
        <div className="prevalence-achievement-container">
            {metricsData.metrics && !metricsData.isLoading ? (
                <Card className="prevalence-achievement-card">
                    <CardBody>
                        <CardTitle>HF and LVSD Prevalence Achievement: Last QOF Year </CardTitle>
                        <div className='prevalence-achievement-metrics-wrapper'>
                            <div className='prevalence-achievement-qol-wrapper'>
                                {qolCard}
                            </div>
                            {metricsChart}
                        </div>
                    </CardBody>
                </Card>
            ) : (
                <CardLoaderMemo cardClassName='prevalence-achievement-cards-loader'/>
            )}
        </div>
    );
};

export const PrevalenceAchievementMetricsMemo = React.memo(PrevalenceAchievementMetrics);