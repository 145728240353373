//@ts-ignore
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface LoggedUser {
    name: string,
    email: string | undefined
}

export interface AuthState {
    loggedUser: LoggedUser | undefined;
    isAuthenticated: boolean | undefined;
    nonSilentAuthProcessing: boolean | undefined;
}

const initialState: AuthState = {
    loggedUser: undefined,
    isAuthenticated: undefined,
    nonSilentAuthProcessing: undefined,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoggedUser: (state: AuthState, action: PayloadAction<LoggedUser>) => {
            state.loggedUser = action.payload;
        },
        setIsAuthenticated: (state: AuthState, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        setNonSilentAuthProcessing: (state: AuthState, action: PayloadAction<boolean>) => {
            state.nonSilentAuthProcessing = action.payload;
        }
    },
    extraReducers: (builder: any) => {
    },
});

export const { 
    setLoggedUser, 
    setIsAuthenticated, 
    setNonSilentAuthProcessing 
} = authSlice.actions;

export default authSlice.reducer;
