import './ChangeInRegisterPrevalenceMetrics.scss'
import React, {useCallback, useMemo, useState} from 'react';
import {Card, CardBody, CardTitle} from '@e360/react-core';
import Plot from 'react-plotly.js';
import {CardLoaderMemo} from "../../../CardLoader/CardLoader";
import {chainGroupBaselineRelatedMetrics} from "../../../../utils/metric";
import {MetricsData} from "../../../../models/MetricsData";
import {DEFAULT_PLOTLY_CONFIG} from "../../../../utils/plotly-commons";

interface ChangeInRegisterPrevalenceMetricsProps {
    metricsData: MetricsData
}

const ChangeInRegisterPrevalenceMetrics: React.FunctionComponent<ChangeInRegisterPrevalenceMetricsProps> = ({metricsData}) => {
    const [resize, setResize] = useState<boolean>(false);
    
    // required to correctly rerender legend position
    // otherwise overlaps chart on resize
    // ie. setting fullscreen from half of screen
    const onAutosizeCallback = useCallback(
        (shouldResize: boolean)=> {
            if (metricsData.metrics && !metricsData.isLoading) {
                setResize(shouldResize);
            }
        }, [metricsData.metrics, metricsData.isLoading]);

    const layout: Partial<Plotly.Layout> = useMemo(
        () => {
            return { 
                autosize: true,
                margin: {
                    t: 0,
                    b: 0,
                    // otherwise hides left yaxis scale labels
                    l: 40, 
                    //i.e. when resized to half of screen
                    // otherwise hides right yaxis scale labels 
                    r: 20, 
                    pad: 5
                },
                xaxis: {
                    domain: [0, 0.9],
                    fixedrange: true
                },
                yaxis: {
                    side:'left',
                    fixedrange: true
                },
                yaxis2: {
                    overlaying: 'y',
                    side: 'right',
                    // otherwise values are from minValue - threshold
                    rangemode: 'tozero', 
                    ticksuffix: '%',
                    // yaxis2 labels are misaligned
                    showgrid: false,
                    fixedrange: true
                },
                legend: {
                    orientation: 'h',
                    xanchor: "center",
                    x: 0.5,
                    traceorder: 'grouped',
                    yanchor: 'top',
                },
                bargroupgap: 0.2,
                font: {
                    family: "Proxima Nova"
                }
            };
        }, []);

    const metricsChart = useMemo(
        () => {
            const xAxisLabels = [ 'Baseline', 'Updated' ];
            const data : Plotly.Data[] = 
                chainGroupBaselineRelatedMetrics(metricsData.metrics)
                .map(groupedMetrics => {
                    const metricName = groupedMetrics[0].metricTypeName;
                    const baselineValue = groupedMetrics
                        .find(m => m.metricTypeId.endsWith("FIRST") || m.metricTypeId.endsWith("LAST"))
                        ?.value;
                    const updatedValue = groupedMetrics
                        .find(m => m.metricTypeId.endsWith("UPDATED"))
                        ?.value;
                    const color = metricName.startsWith("HF") ? '#9E54B0' : '#015FF1';
                    const legendgroup = metricName.startsWith("HF") ? 'HF' : 'LVSD';
                    if (metricName.endsWith("Register")) {
                        return {
                            type: 'bar',
                            name: metricName,
                            x: xAxisLabels,
                            y: [baselineValue, updatedValue],
                            legendgroup,
                            marker: {color: color},
                            yaxis: 'y',
                        } as Plotly.Data;
                    }
                    if (metricName.endsWith("Prevalence")) {
                        return {
                            type: 'scatter',
                            name: metricName,
                            x: xAxisLabels,
                            y: [baselineValue, updatedValue],
                            legendgroup,
                            marker: {color: color},
                            mode: 'lines+markers',
                            line: {
                                dash: 'dash'
                            },
                            yaxis: "y2",
                        } as Plotly.Data;
                    }
                    return {};
                }).value();

            return(
                <Plot
                    className='change-in-register-prevalence-plot'
                    data={data}
                    layout={layout}
                    config={DEFAULT_PLOTLY_CONFIG}
                    useResizeHandler={true}
                    onAutoSize={()=> onAutosizeCallback(!resize)}
                    />);
        }, [resize, layout, onAutosizeCallback, metricsData.metrics]);

    return (
        <div className="change-in-register-prevalence-container">
            {metricsData.metrics && !metricsData.isLoading ? (
                <Card className="change-in-register-prevalence-card">
                    <CardBody>
                        <CardTitle>Change in register prevalence</CardTitle>
                        {metricsChart}
                    </CardBody>
                </Card>
            ) : (
                <CardLoaderMemo cardClassName='change-in-register-prevalence-loader'/>
            )}
        </div>
    );
};

export const ChangeInRegisterPrevalenceMetricsMemo = React.memo(ChangeInRegisterPrevalenceMetrics);