import React, {useCallback, useEffect, useState} from 'react'
import './KPIMetricsDashboard.scss';
import { HeartFailureMenuMemo } from '../HeartFailureMenu/HeartFailureMenu';
import { selectPracticeSelector } from '../../../app/selectors/practiceSelectors';
import {MetricTypeIdEnum} from "../../../models/MetricTypeIdEnum";
import {Metric} from "../../../models/Metric";
import {useAppSelector} from "../../../app/hooks";
import {selectMarketId} from "../../../app/selectors/marketSelectors";
import {
    fetchMetricsForPracticeMarketByMetricTypeIds,
    fetchTemporalMetricsForPracticeMarketByMetricTypeId
} from "../../../app/api/metricsApi";
import {PatientsKpiSingleValueMetricsMemo} from "./PatientsKpiSingleValueMetrics/PatientsKpiSingleValueMetrics";
import {RegisterOverTimeMetricsMemo} from "./RegisterOverTimeMetrics/RegisterOverTimeMetrics";
import {TemporalMetricsCollection} from "../../../models/TemporalMetricsCollection";
import {TemporalMetricTypeIdEnum} from "../../../models/TemporalMetricTypeIdEnum";
import {LvsdPatientsByTherapyMetricsMemo} from "./LvsdPatientsByTherapyMetrics/LvsdPatientsByTherapyMetrics";
import {
    TotalLvsdPatientsByTherapyMetricsMemo
} from "./TotalLvsdPatientsByTherapyMetrics/TotalLvsdPatientsByTherapyMetrics";
import _ from "lodash";
import {WebAnalyticsTrackerMemo} from "../../WebAnalyticsTracker/WebAnalyticsTracker";
import { ModuleContainerMemo } from '../../ModuleContainer/ModuleContainer';

const patientSingleValueBoxesMetricTypeIds = [
    MetricTypeIdEnum.HF_REVIEW_PAT_COUNT,
    MetricTypeIdEnum.LVSD_ACE_ARB_PAT_COUNT,
    MetricTypeIdEnum.LVSD_BB_PAT_COUNT
];

const KPIMetricsDashboard = () => {
    const [kpiSingleValueMetrics, setKpiSingleValueMetrics] = useState<Metric[]|undefined>(undefined)
    const [lvsdCodedTemporalMetrics, setLvsdCodedTemporalMetrics] = useState<TemporalMetricsCollection[]|undefined>(undefined);
    const [lvsdQueryTemporalMetrics, setLvsdQueryTemporalMetrics] = useState<TemporalMetricsCollection[]|undefined>(undefined);
    const [lvsdExcludedTemporalMetrics, setLvsdExcludedTemporalMetrics] = useState<TemporalMetricsCollection[]|undefined>(undefined);
    const [lvsdAceArbTemporalMetrics, setLvsdAceArbTemporalMetrics] = useState<TemporalMetricsCollection[]|undefined>(undefined);
    const [lvsdBbTemporalMetrics, setLvsdBbTemporalMetrics] = useState<TemporalMetricsCollection[]|undefined>(undefined);
    const [lvsdAceArbBbTemporalMetrics, setLvsdAceArbBbTemporalMetrics] = useState<TemporalMetricsCollection[]|undefined>(undefined);
    const [lvsdAceArbOnlyTemporalMetrics, setLvsdAceArbOnlyTemporalMetrics] = useState<TemporalMetricsCollection[]|undefined>(undefined);
    const [lvsdBbOnlyTemporalMetrics, setLvsdBbOnlyTemporalMetrics] = useState<TemporalMetricsCollection[]|undefined>(undefined);
    const [lvsdNoneTemporalMetrics, setLvsdNoneTemporalMetrics] = useState<TemporalMetricsCollection[]|undefined>(undefined);
    
    const {practiceInfo} = useAppSelector(selectPracticeSelector);
    const marketId = useAppSelector(selectMarketId);
    
    const getKpiSingleValueMetrics = async () => {
        if (practiceInfo && marketId) {
            const metrics = await fetchMetricsForPracticeMarketByMetricTypeIds(
                practiceInfo.id,
                marketId,
                patientSingleValueBoxesMetricTypeIds);
            if (metrics) { setKpiSingleValueMetrics(metrics); }
        }
        else {
            setKpiSingleValueMetrics(undefined);
        }
    }

    const getTemporalMetricsByMetricTypeId = useCallback(async (
        temporalMetricTypeId: TemporalMetricTypeIdEnum[], 
        setTemporalMetrics: (result: TemporalMetricsCollection[] | undefined) => void
    ) => {
        if (practiceInfo && marketId) {
            const temporalMetricCollections = await fetchTemporalMetricsForPracticeMarketByMetricTypeId(
                practiceInfo.id,
                marketId,
                temporalMetricTypeId);
            if (temporalMetricCollections) {
                const adjustedTemporalMetricsCollections = ensureTemporalMetricDataConsistencyPerDate(temporalMetricCollections);
                setTemporalMetrics(adjustedTemporalMetricsCollections); 
            }
        }
        else {
            setTemporalMetrics(undefined);
        }
    }, [practiceInfo, marketId]);
    
    const getRegisterOverTimeTemporalMetrics = async() => {
        await Promise.all([
            getTemporalMetricsByMetricTypeId([TemporalMetricTypeIdEnum.LVSD_PERC, TemporalMetricTypeIdEnum.LVSD_COUNT], (result) => setLvsdCodedTemporalMetrics(result)),
            getTemporalMetricsByMetricTypeId([TemporalMetricTypeIdEnum.LVSD_QUERY_PERC, TemporalMetricTypeIdEnum.LVSD_QUERY_COUNT], (result) => setLvsdQueryTemporalMetrics(result)),
            getTemporalMetricsByMetricTypeId([TemporalMetricTypeIdEnum.LVSD_EXCLUDED_PERC, TemporalMetricTypeIdEnum.LVSD_EXCLUDED_COUNT], (result) => setLvsdExcludedTemporalMetrics(result)),
        ]);
    };
    
    const getLvsdPatientsByTherapyTemporalMetrics = async() => {
        await Promise.all([
            getTemporalMetricsByMetricTypeId([TemporalMetricTypeIdEnum.LVSD_ACE_ARB_PERC, TemporalMetricTypeIdEnum.LVSD_ACE_ARB_COUNT], (result) => setLvsdAceArbTemporalMetrics(result)),
            getTemporalMetricsByMetricTypeId([TemporalMetricTypeIdEnum.LVSD_BB_PERC, TemporalMetricTypeIdEnum.LVSD_BB_COUNT], (result) => setLvsdBbTemporalMetrics(result)),
        ]);
    };
    
    const getTotalLvsdPatientsByTherapyTemporalMetrics = async() => {
        await Promise.all([
            getTemporalMetricsByMetricTypeId([TemporalMetricTypeIdEnum.LVSD_ACE_ARB_BB_PERC, TemporalMetricTypeIdEnum.LVSD_ACE_ARB_BB_COUNT], (result) => setLvsdAceArbBbTemporalMetrics(result)),
            getTemporalMetricsByMetricTypeId([TemporalMetricTypeIdEnum.LVSD_ACE_ARB_ONLY_PERC, TemporalMetricTypeIdEnum.LVSD_ACE_ARB_ONLY_COUNT], (result) => setLvsdAceArbOnlyTemporalMetrics(result)),
            getTemporalMetricsByMetricTypeId([TemporalMetricTypeIdEnum.LVSD_BB_ONLY_PERC, TemporalMetricTypeIdEnum.LVSD_BB_ONLY_COUNT], (result) => setLvsdBbOnlyTemporalMetrics(result)),
            getTemporalMetricsByMetricTypeId([TemporalMetricTypeIdEnum.LVSD_NONE_PERC, TemporalMetricTypeIdEnum.LVSD_NONE_COUNT], (result) => setLvsdNoneTemporalMetrics(result)),
        ]);
    };
    
    useEffect(() => {
        getKpiSingleValueMetrics();
        getRegisterOverTimeTemporalMetrics();
        getLvsdPatientsByTherapyTemporalMetrics();
        getTotalLvsdPatientsByTherapyTemporalMetrics();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceInfo, marketId]);

    const ensureTemporalMetricDataConsistencyPerDate = (
        temporalMetricsCollections: TemporalMetricsCollection[] | undefined
    ) => {
        const datesPerCollection = _.chain(temporalMetricsCollections)
            .map((collection) =>
                collection.values.map(val => val.date))
            .value();
        
        const uniqueDates = _.chain(datesPerCollection).flatten().uniq().value();
        const commonDates = _.intersection(...datesPerCollection);
        const missingDates = _.difference(uniqueDates, commonDates)
        
        return _.map(temporalMetricsCollections, (collection) => {
            _.forEach(missingDates, date => {
                _.remove(collection.values, v => v.date === date);
            });
            return collection;            
        });
    };
    
    return (
        <ModuleContainerMemo>
            <HeartFailureMenuMemo/>
            <WebAnalyticsTrackerMemo/>
            <div className="kpi-metrics-single-value-metrics-container">
                <PatientsKpiSingleValueMetricsMemo
                    metrics={kpiSingleValueMetrics}/> 
            </div>
            <div className="kpi-metrics-rot-chart-container-wrapper">
                <div className="kpi-metrics-rot-chart-container">
                    <RegisterOverTimeMetricsMemo
                        lvsdCodedTemporalMetrics={lvsdCodedTemporalMetrics}
                        lvsdQueryTemporalMetrics={lvsdQueryTemporalMetrics}
                        lvsdExcludedTemporalMetrics={lvsdExcludedTemporalMetrics} />
                </div>
            </div>
            <div className="kpi-metrics-lvsd-patients-by-therapy-container">
                <div className="lvsd-patients-by-therapy-card-container">
                    <LvsdPatientsByTherapyMetricsMemo
                        lvsdAceArbTemporalMetrics={lvsdAceArbTemporalMetrics}
                        lvsdBbTemporalMetrics={lvsdBbTemporalMetrics} />
                </div>
                <div className="total-lvsd-patients-by-therapy-card-container">
                    <TotalLvsdPatientsByTherapyMetricsMemo
                        lvsdAceArbBbTemporalMetrics={lvsdAceArbBbTemporalMetrics}
                        lvsdAceArbOnlyTemporalMetrics={lvsdAceArbOnlyTemporalMetrics}
                        lvsdBbOnlyTemporalMetrics={lvsdBbOnlyTemporalMetrics}
                        lvsdNoneTemporalMetrics={lvsdNoneTemporalMetrics}
                    />
                </div>
            </div>
        </ModuleContainerMemo>
    );
}

export const KPIMetricsDashboardMemo = React.memo(KPIMetricsDashboard);

