import './ExportSection.scss';
import React, {useCallback, useState} from "react";
import {exportToExcelPracticePatientData} from "../../../../../app/api/patientsApi";
import {saveAs} from "file-saver";
import {useAppSelector} from "../../../../../app/hooks";
import {selectPracticeSelector} from "../../../../../app/selectors/practiceSelectors";
import {selectMarketId} from "../../../../../app/selectors/marketSelectors";
import {PatientsListExportFilterCriteria} from "../../../../../models/PatientsListExportFilterCriteria";
import {ColumnFilterViewModel} from "../../../../../models/ColumnFilterViewModel";
import {Button} from "@e360/react-core";
import {formatDate} from "../../../../../utils/date";
import {DATE_FORMAT} from "../../../../../utils/date-format";
import {patientsListFiltersSelector} from "../../../../../app/selectors/patientsListSelectors";

interface ExportSectionProps {
    show: boolean,
    columnFilters: ColumnFilterViewModel[]
}

const ExportSection: React.FunctionComponent<ExportSectionProps> = ({show, columnFilters, }) => {
    const {quickFilterKey} = useAppSelector(patientsListFiltersSelector);
    const {practiceInfo} = useAppSelector(selectPracticeSelector);
    const marketId = useAppSelector(selectMarketId);
    const [excelExportInProgress, setExcelExportInProgress] = useState<boolean>(false);
    
    const excelExportPatientsList = useCallback(() => {
        setExcelExportInProgress(true);
        if (!practiceInfo || !marketId) {
            return;
        }

        const filterCriteria : PatientsListExportFilterCriteria = {
            columnFilters: columnFilters,
            quickFilterKey: quickFilterKey,
        };

        exportToExcelPracticePatientData(
            practiceInfo!.id,
            filterCriteria)
            .then((response: any) => {
                const date = formatDate(new Date(), DATE_FORMAT.dd_mm_yyyy_SPACELESS);
                const filename = `IHAN_PracticeList_HF_${practiceInfo.id}_${date}.xlsx`;
                saveAs(response, filename);
            }).finally(() => {
                setExcelExportInProgress(false);
            });
    }, [practiceInfo, marketId, quickFilterKey, columnFilters]);

    return show ? 
        <div className="export-section-wrapper">
            <Button 
                className="excel-export-button"
                variant="secondary"
                size="sm"
                isBusy={excelExportInProgress}
                onClick={() => excelExportPatientsList()}
            >
                Export
            </Button>
        </div> : 
        <></>;
}

export const ExportSectionMemo = React.memo(ExportSection);