export enum TemporalMetricTypeIdEnum {
    LVSD_ACE_ARB_BB_COUNT = 'LVSD_ACE_ARB_BB_COUNT',
    LVSD_ACE_ARB_BB_PERC = 'LVSD_ACE_ARB_BB_PERC',
    LVSD_ACE_ARB_COUNT = 'LVSD_ACE_ARB_COUNT',
    LVSD_ACE_ARB_PERC = 'LVSD_ACE_ARB_PERC',
    LVSD_ACE_ARB_ONLY_COUNT = 'LVSD_ACE_ARB_ONLY_COUNT',
    LVSD_ACE_ARB_ONLY_PERC = 'LVSD_ACE_ARB_ONLY_PERC',
    LVSD_BB_COUNT = 'LVSD_BB_COUNT',
    LVSD_BB_PERC = 'LVSD_BB_PERC',
    LVSD_BB_ONLY_COUNT = 'LVSD_BB_ONLY_COUNT',
    LVSD_BB_ONLY_PERC = 'LVSD_BB_ONLY_PERC',
    LVSD_COUNT = 'LVSD_COUNT',
    LVSD_PERC = 'LVSD_PERC',
    LVSD_EXCLUDED_COUNT = 'LVSD_EXCLUDED_COUNT',
    LVSD_EXCLUDED_PERC = 'LVSD_EXCLUDED_PERC',
    LVSD_NONE_COUNT = 'LVSD_NONE_COUNT',
    LVSD_NONE_PERC = 'LVSD_NONE_PERC',
    LVSD_QUERY_COUNT = 'LVSD_QUERY_COUNT',
    LVSD_QUERY_PERC = 'LVSD_QUERY_PERC',
}