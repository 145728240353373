import './PatientsBulkEdit.scss';
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Button, 
    Modal, 
    ModalHeader, 
    ModalTitle, 
    ModalSubtitle, 
    ModalBody, 
    ModalFooter
} from "@e360/react-core";
import {PatientEntry} from "../../../../../models/PatientEntry";
import _ from "lodash";
import {PatientEntryUpdateModel} from "../../../../../models/PatientEntryUpdateModel";
import {nameof} from "../../../../../utils/nameof";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {
    DECISIONS,
    SNOMED_CODES_BY_QOF_ACCEPTED_CODE_TERM,
    TYPE_OF_USERS
} from "../patients-list-constants";
import {classNames} from "primereact/utils";
import {Checkbox, CheckboxChangeEvent} from "primereact/checkbox";
import {bulkUpdatePracticePatientData} from "../../../../../app/api/patientsApi";
import {useAppSelector} from "../../../../../app/hooks";
import {selectPracticeSelector} from "../../../../../app/selectors/practiceSelectors";
import {PatientEntriesBulkUpdateModel} from "../../../../../models/PatientEntriesBulkUpdateModel";

interface PatientsBulkEditProps {
    show: boolean,
    selectedRows: PatientEntry[],
    onBulkEditStarted: () => void;
    onBulkEditFinished: (isSuccessful: boolean) => void;
}

interface FormField {
    containsEqualValues: boolean,
    value?: any,
}

type FormState = { [fieldName: string]: FormField };

const emptyFormState: FormState = {
    [nameof<PatientEntryUpdateModel>("decision")]: {
        containsEqualValues: false,
        value: undefined
    },
    [nameof<PatientEntryUpdateModel>("qofAcceptedCodeTerm")]: {
        containsEqualValues: false,
        value: undefined
    },
    [nameof<PatientEntryUpdateModel>("addedBy")]: {
        containsEqualValues: false,
        value: undefined
    },
    [nameof<PatientEntryUpdateModel>("comments")]: {
        containsEqualValues: false,
        value: undefined
    }
};

const BLANK_DROPDOWN_VALUE: string = '';
const MAX_COMMENTS_VALUE_LENGTH = 255;
const MAX_COMMENTS_VALUE_LENGTH_IN_APPEND_MODE = MAX_COMMENTS_VALUE_LENGTH - 1;

const PatientsBulkEdit: React.FunctionComponent<PatientsBulkEditProps> = ({show, selectedRows, onBulkEditStarted, onBulkEditFinished}) => {
    const {practiceInfo} = useAppSelector(selectPracticeSelector);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [initialFormState, setInitialFormState] = useState<FormState>(structuredClone(emptyFormState));
    const [formState, setFormState] = useState<FormState>(structuredClone(emptyFormState));
    const [clearAllComments, setClearAllComments] = useState<boolean>(false);
    const [appendToExistingComments, setAppendToExistingComments] = useState<boolean>(false);
    const [longestCommentLength, setLongestCommentLength] = useState<number>(0);
    
    const containsSameValues = useCallback((array: any[]) =>
        array.every((value, i, values) => _.isEqual(value, values[0])), []);
    
    const evaluateFormFieldValueByValuesUniqueness = useCallback((array: any[]) => 
        containsSameValues(array) ? array[0] : undefined, [containsSameValues]);

    const toggle = useCallback(() => {
        setShowModal(false);
        setFormState(structuredClone(initialFormState));
        setClearAllComments(false);
        setAppendToExistingComments(false);
    }, [initialFormState]);
    
    const evaluateFormState = useCallback((): FormState => {
        const decisions = selectedRows.map(r => r.decision);
        const qofAcceptedCodeTerms = selectedRows.map(r => r.qofAcceptedCodeTerm);
        const addedBys = selectedRows.map(r => r.addedBy);
        const comments = selectedRows.map(r => r.comments);
        
        setLongestCommentLength(_
            .chain(comments)
            .map(c => c?.length ?? 0)
            .max()
            .valueOf());
        
        return {
            [nameof<PatientEntryUpdateModel>("decision")]: {
                containsEqualValues: containsSameValues(decisions),
                value: evaluateFormFieldValueByValuesUniqueness(decisions)
            },
            [nameof<PatientEntryUpdateModel>("qofAcceptedCodeTerm")]: {
                containsEqualValues: containsSameValues(qofAcceptedCodeTerms),
                value: evaluateFormFieldValueByValuesUniqueness(qofAcceptedCodeTerms)
            },
            [nameof<PatientEntryUpdateModel>("addedBy")]: {
                containsEqualValues: containsSameValues(addedBys),
                value: evaluateFormFieldValueByValuesUniqueness(addedBys)
            },
            [nameof<PatientEntryUpdateModel>("comments")]: {
                containsEqualValues: containsSameValues(comments),
                value: evaluateFormFieldValueByValuesUniqueness(comments)
            }
        } as FormState;
    }, [selectedRows, containsSameValues, evaluateFormFieldValueByValuesUniqueness])
    
    useEffect(() => {
        const originalFormState = evaluateFormState();
        setInitialFormState(structuredClone(originalFormState));
        setFormState(structuredClone(originalFormState));
        //eslint-disable-next-line
    }, [showModal]);
    
    const bulkEditDisabled = _.isEmpty(selectedRows) || selectedRows.length < 2;

    const closeBtn = <button className="close" onClick={toggle} aria-label="close"></button>;
    
    const blankValueInfo = (field: FormField) => {
        const className = classNames(
            'bulk-edit-blank-value-field',
            {
                'hidden': field.containsEqualValues
            });
        return (        
            <span className={className}>
                Leave blank to make no bulk changes to this field
            </span>
        );
    }    
    
    const clearAllCommentsWarningText = useMemo(() => {
        return (clearAllComments ?
            <span className="bulk-edit-clear-all-comments-warning-text">
                This will clear all Comments fields
            </span>
                : <></>
        );
    }, [clearAllComments]);

    const setupSelectableOptions = useCallback((fieldName: keyof PatientEntryUpdateModel, selectableOptions: any[]) => {
        const field = initialFormState[nameof<PatientEntryUpdateModel>(fieldName)];
        if (!field.containsEqualValues) {
            return [BLANK_DROPDOWN_VALUE, ...selectableOptions];
        }
        return selectableOptions;
    }, [initialFormState]);
    
    const selectableDecisions = useMemo(() => 
        setupSelectableOptions('decision', DECISIONS), [setupSelectableOptions]);
    const selectableQofAcceptedCodeTerms = useMemo(() => 
        setupSelectableOptions('qofAcceptedCodeTerm', Object.keys(SNOMED_CODES_BY_QOF_ACCEPTED_CODE_TERM)), [setupSelectableOptions]);
    const selectableAddedBys = useMemo(() => 
        setupSelectableOptions('addedBy', TYPE_OF_USERS), [setupSelectableOptions]);

    const updateFormStateForField = useCallback((fieldName: keyof PatientEntryUpdateModel, newValue: any) => {
        const updatedDecision = {
            ...formState,
            [nameof<PatientEntryUpdateModel>(fieldName)]: {
                ...[nameof<PatientEntryUpdateModel>(fieldName)],
                value: newValue,
            },
        } as FormState;
        setFormState(updatedDecision);
    }, [formState]);
    
    const onCommentsChanged = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (_.isEmpty(value)) {
            setAppendToExistingComments(false);
        }
        updateFormStateForField("comments", e.target.value);
    }, [updateFormStateForField]);
    
    const onDecisionChanged = useCallback((e: DropdownChangeEvent) => 
        updateFormStateForField("decision", e.value === BLANK_DROPDOWN_VALUE ? null : e.value),
        [updateFormStateForField]);

    const onQofAcceptedCodeTermChanged = useCallback((e: DropdownChangeEvent) => 
        updateFormStateForField("qofAcceptedCodeTerm", e.value === BLANK_DROPDOWN_VALUE ? null : e.value),
        [updateFormStateForField]);

    const onAddedByChanged = useCallback((e: DropdownChangeEvent) => 
        updateFormStateForField("addedBy", e.value === BLANK_DROPDOWN_VALUE ? null : e.value),
        [updateFormStateForField]);
    
    const getDropdownItem = (option: string) =>
        !option || option === BLANK_DROPDOWN_VALUE ?
            <div className="bulk-edit-blank-dd-item">(blank)</div> : option;

    const applyBulkEdit = useCallback(() => {
        setIsProcessing(true);
        onBulkEditStarted();

        const updatePayload: PatientEntriesBulkUpdateModel = {
            patientIds: selectedRows.map(p => p.patientId),
            decision: formState[nameof<PatientEntryUpdateModel>("decision")].value,
            qofAcceptedCodeTerm: formState[nameof<PatientEntryUpdateModel>("qofAcceptedCodeTerm")].value,
            addedBy: formState[nameof<PatientEntryUpdateModel>("addedBy")].value,
            comments: formState[nameof<PatientEntryUpdateModel>("comments")].value,
            appendToExistingComments: appendToExistingComments,
            clearAllComments: clearAllComments,
        }
        
        bulkUpdatePracticePatientData(practiceInfo!.id, updatePayload!)
            .then(() => {
                console.log(`${selectedRows.length} rows updated successfully.`);
                onBulkEditFinished(true);
                toggle();
            })
            .catch((_) => {
                onBulkEditFinished(false);
            })
            .finally(() => {
                setIsProcessing(false);
            });
    },[practiceInfo, formState, selectedRows, clearAllComments, appendToExistingComments, onBulkEditStarted, onBulkEditFinished, toggle]);

    const onClearCommentsChanged = (event: CheckboxChangeEvent) => {
        const isChecked = event.checked ?? false;
        if (isChecked) {
            setAppendToExistingComments(false);
        }
        setClearAllComments(isChecked);
    };    
    
    const onAppendToExistingCommentsChanged = (event: CheckboxChangeEvent) => {
        const isChecked = event.checked ?? false;
        setAppendToExistingComments(isChecked);
    }

    const isAppendToExistingCommentsDisabled = useMemo(() => {
        const originalValue = initialFormState[nameof<PatientEntryUpdateModel>("comments")].value ?? '';
        const currentValue = formState[nameof<PatientEntryUpdateModel>("comments")].value ?? '';
        return clearAllComments || _.isEqual(originalValue, currentValue);
    }, [initialFormState, formState, clearAllComments]);
    
    const commentFieldMetadata = useMemo(() => {
        const allowedLength = appendToExistingComments ? MAX_COMMENTS_VALUE_LENGTH_IN_APPEND_MODE - longestCommentLength : MAX_COMMENTS_VALUE_LENGTH;
        const maxLength = allowedLength < 1 ? 0 : allowedLength;
        const currentLength = formState[nameof<PatientEntryUpdateModel>("comments")].value?.length ?? 0;
        const lengthExceededBy = maxLength - currentLength < 0 ? Math.abs(maxLength - currentLength) : 0;
        const isInvalid = appendToExistingComments && lengthExceededBy > 0;
        
        return {
            maxLength: maxLength,
            currentLength: currentLength,
            lengthExceededBy: lengthExceededBy,
            isInvalid: isInvalid
        }
    }, [appendToExistingComments, longestCommentLength, formState]);

    const commentLengthExceededErrorInfo = useMemo(() => {
        const className = classNames(
            'bulk-edit-invalid-comment-error-text',
            {
                'hidden': !commentFieldMetadata.isInvalid
            });
        return (
            <span className={className}>
                Character limit exceeded: <strong>{commentFieldMetadata.lengthExceededBy} too many</strong>
            </span>
        );
    }, [commentFieldMetadata]);

    const isSubmitButtonDisabled = useMemo(() =>
            isProcessing
            || (_.isEqual(initialFormState, formState) && !clearAllComments)
            || commentFieldMetadata.isInvalid,
        [isProcessing, initialFormState, formState, clearAllComments, commentFieldMetadata]);
    
    return show ?
        (<div className="bulk-edit-button-wrapper">
            <Button
                className="bulk-edit-button"
                variant="secondary"
                size="sm"
                disabled={bulkEditDisabled}
                onClick={() => setShowModal(true)}  
            >
                Bulk Edit
            </Button>
            <Modal 
                isOpen={showModal}
                toggle={toggle}
                backdrop="static"
            >
                <ModalHeader 
                    toggle={toggle} 
                    close={closeBtn}>
                    <ModalTitle>Bulk Edit</ModalTitle>
                    <ModalSubtitle>{selectedRows.length} rows selected</ModalSubtitle>
                </ModalHeader>
                <ModalBody>
                    <div className="bulk-edit-form-wrapper">
                        <div className="bulk-edit-form-item">
                            <span id={`${nameof<PatientEntryUpdateModel>("decision")}-dd`}>Decision</span>
                            <Dropdown
                                itemTemplate={getDropdownItem}
                                appendTo="self"
                                value={formState.decision.value}
                                options={selectableDecisions}
                                onChange={(e) => onDecisionChanged(e)}
                            />
                            {blankValueInfo(initialFormState.decision)}
                        </div>
                        <div className="bulk-edit-form-item">
                            <span id={`${nameof<PatientEntryUpdateModel>("qofAcceptedCodeTerm")}-dd`}>QOF Accepted Code Term</span>
                            <Dropdown
                                itemTemplate={getDropdownItem}
                                appendTo="self"
                                value={formState.qofAcceptedCodeTerm.value}
                                options={selectableQofAcceptedCodeTerms}
                                onChange={(e) => onQofAcceptedCodeTermChanged(e)}
                            />
                            {blankValueInfo(initialFormState.qofAcceptedCodeTerm)}
                        </div>
                        <div className="bulk-edit-form-item">
                            <span id={`${nameof<PatientEntryUpdateModel>("addedBy")}-dd`}>Added By</span>
                            <Dropdown
                                itemTemplate={getDropdownItem}
                                appendTo="self"
                                value={formState.addedBy.value}
                                options={selectableAddedBys}
                                onChange={(e) => onAddedByChanged(e)}
                            />
                            {blankValueInfo(initialFormState.addedBy)}
                        </div>
                        <div className="bulk-edit-form-item">
                            <span id={`${nameof<PatientEntryUpdateModel>("comments")}-input`}>
                                Comments (max. characters to append <strong>{commentFieldMetadata.currentLength}/{commentFieldMetadata.maxLength}</strong>)                                
                            </span>
                            <InputTextarea
                                id={`${nameof<PatientEntryUpdateModel>("comments")}-input`}
                                value={formState.comments.value ?? ''}
                                autoResize={true}
                                rows={3}
                                maxLength={MAX_COMMENTS_VALUE_LENGTH}
                                disabled={clearAllComments}
                                className={classNames({'p-invalid': commentFieldMetadata.isInvalid})}
                                onChange={(e) => onCommentsChanged(e)}
                                style={{width: '100%'}}
                            />
                            {commentLengthExceededErrorInfo}
                            {blankValueInfo(initialFormState.comments)}
                            <div className="bulk-edit-comments-checkbox-wrapper">
                                <div>
                                    <Checkbox
                                        inputId="append-comments-cb"
                                        name="append-comments"
                                        disabled={isAppendToExistingCommentsDisabled}
                                        onChange={onAppendToExistingCommentsChanged}
                                        checked={appendToExistingComments && !isAppendToExistingCommentsDisabled} />
                                    <label
                                        htmlFor="append-comments-cb"
                                        className="ml-2">
                                        Append to existing comments
                                    </label>
                                </div>
                            </div>
                            <div className="bulk-edit-comments-checkbox-wrapper">
                                <div>
                                    <Checkbox
                                        inputId="clear-comments-cb"
                                        name="clear-comments"
                                        onChange={onClearCommentsChanged}
                                        checked={clearAllComments} />
                                    <label
                                        htmlFor="clear-comments-cb"
                                        className="ml-2">
                                        Clear all comments
                                    </label>
                                </div>
                                {clearAllCommentsWarningText}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        variant="tertiary" 
                        className="mr-2"
                        disabled={isProcessing}
                        onClick={toggle}>
                        Cancel
                    </Button>
                    <Button 
                        variant="primary"
                        isBusy={isProcessing}
                        disabled={isSubmitButtonDisabled}
                        onClick={applyBulkEdit}>
                        Update All
                    </Button>
                </ModalFooter>
            </Modal>
        </div>) :
        <></>;
}

export const PatientsBulkEditMemo = React.memo(PatientsBulkEdit);
