import React from 'react';
import { PageNotFoundMemo } from '../GlobalErrorBoundary/PageNotFound/PageNotFound';
import './PageNotFoundLayout.scss';

const PageNotFoundLayout = () => {
    return(
        <div className='page-not-found-layout-container'>
            <div className='page-not-found-layout-wrapper'>
                <PageNotFoundMemo/>
            </div>
        </div>)
}

export const PageNotFoundLayoutMemo = React.memo(PageNotFoundLayout);