import React from 'react';
import './ModuleContainer.scss'


interface ModuleContainerProps {
    children: any
}

const ModuleContainer: React.FunctionComponent<ModuleContainerProps> = ({children}) => {
    return(
        <div className='module-container'>
            {children}
        </div>
    );
};

export const ModuleContainerMemo = React.memo(ModuleContainer);