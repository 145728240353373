import {LvsdStatusEnum} from "../../../../models/LvsdStatusEnum";
import {ICON_NAME} from "../../../../utils/icon-name";

export const EMPTY_DROPDOWN_OPTION_LABEL = 'None';
export const EMPTY_DROPDOWN_OPTION_VALUE = '';

export const LVSD_STATUS_ICONS = [
    {status: LvsdStatusEnum.LvsdConfirmed, icon: ICON_NAME.GREEN_CHECK_CIRCLE, name: 'LVSD Confirmed'},
    {status: LvsdStatusEnum.QueryLvsd, icon: ICON_NAME.ORANGE_EXCLAMATION_CIRCLE, name: 'Query LVSD'},
    {status: LvsdStatusEnum.LvsdExcluded, icon: ICON_NAME.RED_TIMES_CIRCLE, name: 'LVSD Excluded'},
];

export const TYPE_OF_USERS = [
    "GP",
    "Nurse",
    "Practice Pharmacist",
    "Coder"];

export const DECISIONS = [
    "LVSD Confirmed",
    "Query LVSD",
    "LVSD Excluded",
    "Not Screened"];

export const SNOMED_CODES_BY_QOF_ACCEPTED_CODE_TERM = {
    "Echocardiogram shows left ventricular systolic dysfunction": "407596008",
    "Left ventricular systolic dysfunction": "13440100"
};

export const HEADER_FILTERS_DISABLED_IN_EDIT_MODE_MESSAGE = "Filtering is not available if at least one row is in edit mode";