import {HubConnectionBuilder} from '@microsoft/signalr';
import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {MetricTypeIdEnum} from "../models/MetricTypeIdEnum";
import {useAppSelector} from "../app/hooks";
import {selectPracticeSelector} from "../app/selectors/practiceSelectors";
import {selectMarketId} from "../app/selectors/marketSelectors";
import {fetchMetricsForPracticeMarketByMetricTypeIds} from "../app/api/metricsApi";
import {Metric} from "../models/Metric";
import { useRelativeSiteUri } from "../utils/useRelativeSiteUri";
import _ from "lodash";
import {MetricsDataContext} from "../models/MetricsDataContext";

interface DataChangedNotification {
    PracticeIds: string[];    
    MarketId: string;
}

const DataChangedContext = createContext<DataChangedNotification | undefined>(undefined);

export const MetricsProvider = ({children}: {children: React.ReactNode}) => {
    const [notification, setNotification] = useState<DataChangedNotification | undefined>();
    const relativeSiteUri: string = useRelativeSiteUri();
    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${relativeSiteUri}hubs/DataChanged`)
            .build();

        connection.on("DataChanged", data => {
            const typedData = JSON.parse(data) as DataChangedNotification;
            setNotification(typedData);
        });

        connection
            .start()
            .then(o => console.log('connection started', o))
            .catch(e => console.error('connection start failed', e));

        return () => {
            connection
                .stop()
                .then(o => console.log('connection stopped', o))
                .catch(e => console.error('connection stop failed', e));
        }
    }, [relativeSiteUri]);
    
    return <DataChangedContext.Provider value={notification}>
        {children}
    </DataChangedContext.Provider>;
}

export const useMetrics = (metricIds: MetricTypeIdEnum[]) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { practiceInfo } = useAppSelector(
        selectPracticeSelector);
    
    const [localMetricTypeIds, setLocalMetricTypeIds] = useState<MetricTypeIdEnum[]>([]);
    
    useEffect(() => {
        if (!_.isEqual(localMetricTypeIds, metricIds)) {
            setLocalMetricTypeIds(metricIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metricIds])

    const practiceId = practiceInfo?.id;
    
    const marketId = useAppSelector(
        selectMarketId);

    const [metrics, setMetrics] = useState<Metric[]|undefined>(undefined)
    
    const loadMetricsSilently = useCallback(() => {
        if (practiceId && marketId && localMetricTypeIds.length > 0) {
            fetchMetricsForPracticeMarketByMetricTypeIds(
                practiceId,
                marketId,
                localMetricTypeIds).then((metrics: Metric[]) => {
                if(metrics) {
                    setMetrics(
                        metrics);
                }
            });
        } else {
            setMetrics(undefined);
        }
    }, [practiceId, marketId, localMetricTypeIds]);
    
    const loadMetrics = useCallback(() => {
        if (practiceId && marketId && localMetricTypeIds.length > 0) {
            setIsLoading(true);
            setMetrics(
                undefined);
            fetchMetricsForPracticeMarketByMetricTypeIds(
                practiceId,
                marketId,
                localMetricTypeIds).then((metrics: Metric[]) => {
                if(metrics) {
                    setMetrics(
                        metrics);
                }
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            setMetrics(undefined);
        }
    }, [practiceId, marketId, localMetricTypeIds]);
    
    useEffect(() => {
        loadMetrics();
    }, [loadMetrics]);
    
    const dataChangedNotification = useContext(DataChangedContext);
    
    useEffect(() => {
        if (!dataChangedNotification || !practiceInfo) {
            return;
        }
        console.log('Ondatachanged', dataChangedNotification);
        if (dataChangedNotification.MarketId === marketId 
            && dataChangedNotification.PracticeIds.includes(practiceInfo.id)) {
            loadMetricsSilently();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataChangedNotification])
    
    return {
        isLoading,
        metrics,
        loadMetricsSilently
    } as MetricsDataContext;
}