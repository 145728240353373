import './HFXXXExcTableCell.scss';
import React, {useMemo} from "react";

const redCircleCrossStatusIcon =
    <i className="fa fa-fw fa-2x fa-times text-danger"></i>;
const greenCheckStatusIcon =
    <i className="fa fa-fw fa-2x fa-check text-success"></i>;

interface HFXXXExcTableCellProps {
    cellValue: number
}

interface HFXXXExcCellContent {
    cellContent: React.ReactElement
}

const HFXXXExcTableCell: React.FunctionComponent<HFXXXExcTableCellProps> = ({cellValue}) => {    
    const cellContent : HFXXXExcCellContent  = useMemo(() => {
        switch (cellValue){
            case 0:
                return {cellContent: greenCheckStatusIcon}
            case 1:
                return {cellContent: redCircleCrossStatusIcon}
            default:
                return {cellContent: <span style={{fontWeight: 'bold'}}>-</span>}
        }
    }, [cellValue]);
    
    return <div className="hfxxx-exc-icon-wrapper">
        {cellContent.cellContent}
    </div>;
}

export const HFXXXExcTableCellMemo = React.memo(HFXXXExcTableCell);