import "./PatientsStatistics.scss";
import {Card, CardBody, CardTitle} from "@e360/react-core";
import React, {useCallback, useMemo} from "react";
import {MetricTypeIdEnum} from "../../../../models/MetricTypeIdEnum";
import {getMetricsSum} from "../../../../utils/metric";
import {Metric} from "../../../../models/Metric";
import {numericFormatter} from "react-number-format";
import Plot from "react-plotly.js";
import {Layout, PlotData} from "plotly.js";
import {CardLoaderMemo} from "../../../CardLoader/CardLoader";
import {Blue, DarkGray, Fuchsia, LightGray, Orange, Purple} from "../../../../utils/palette-hf";
import _ from "lodash";
import {DEFAULT_PLOTLY_CONFIG} from "../../../../utils/plotly-commons";
import {MetricsData} from "../../../../models/MetricsData";

const patientGroupsMetricTypeIds = [
    MetricTypeIdEnum.CODEMED_CONFIRMED,
    MetricTypeIdEnum.CODEMED_QUERY,
    MetricTypeIdEnum.CODEMED_EXCLUDED,
    MetricTypeIdEnum.CODE_CONFIRMED,
    MetricTypeIdEnum.CODE_QUERY,
    MetricTypeIdEnum.CODE_EXCLUDED,
    MetricTypeIdEnum.MED_CONFIRMED,
    MetricTypeIdEnum.MED_QUERY,
    MetricTypeIdEnum.MED_EXCLUDED,
    MetricTypeIdEnum.CODE_NOTSCREEN,
    MetricTypeIdEnum.CODEMED_NOTSCREEN,
    MetricTypeIdEnum.MED_NOTSCREEN
];

const priorityGroupMetricTypes = [
    MetricTypeIdEnum.CODEMED_CONFIRMED,
    MetricTypeIdEnum.CODEMED_QUERY,
    MetricTypeIdEnum.CODEMED_EXCLUDED,
    MetricTypeIdEnum.CODE_CONFIRMED,
    MetricTypeIdEnum.CODE_QUERY,
    MetricTypeIdEnum.CODE_EXCLUDED,
    MetricTypeIdEnum.MED_CONFIRMED,
    MetricTypeIdEnum.MED_QUERY,
    MetricTypeIdEnum.MED_EXCLUDED
];

interface PriorityGroupProps {
    metrics: Metric[]
}

// In the end number of patients would bve equal to the sum of all patient-group metrics
const getNumberOfPatients = (metrics: Metric[]) => getMetricsSum(metrics, ...patientGroupsMetricTypeIds);

const PriorityGroup = ({metrics}: PriorityGroupProps) => {
    const numberOfPatients = getNumberOfPatients(metrics);
    const safeNumberOfPatients = numberOfPatients <= 0 ? 1 : numberOfPatients;
    
    const priorityGroupRatio = (getMetricsSum(metrics, ...priorityGroupMetricTypes) * 100.0 / safeNumberOfPatients); 
    const formattedValue = numericFormatter(String(priorityGroupRatio), {
        decimalSeparator: '.',
        suffix: '%',
        decimalScale: 0,
        fixedDecimalScale: true
    })
    return <>
        <div className="patients-statistics-priority-group-value">{formattedValue}</div>
        <div className="patients-statistics-priority-group-caption">Complete</div>
    </>
}

interface AdditionalIncomeProps {
    metrics: Metric[]
}

const AdditionalIncome = ({metrics} : AdditionalIncomeProps) => {
    const formatValue = (metric: Metric | null | undefined) =>
        numericFormatter(metric?.value ?? '', {
            thousandSeparator: ',',
            decimalSeparator: '.',
            prefix: '£',
            decimalScale: 2,
            fixedDecimalScale: true
        });

    const getMetricTypeIdCallback = useCallback(
        (metricTypeId: MetricTypeIdEnum) => {
            if (metrics) {
                return _.find(metrics, (metric: Metric) => metric.metricTypeId === metricTypeId);
            }
            return null;
        }, [metrics]);
    
    const additionalIncomeMetric = formatValue(getMetricTypeIdCallback(MetricTypeIdEnum.ADDN_INCOME_LAST));

    const getMetricTypeIdNumericValueCallback = useCallback(
        (metricTypeId: MetricTypeIdEnum) => {
            if (metrics) {
                return +(getMetricTypeIdCallback(metricTypeId)?.value ?? 0) ;
            }
            return 0;
        }, [metrics, getMetricTypeIdCallback]);
    
    const patientsAdded = useMemo(
        () => {
            const lvsdRegUpdatedMetricValue = getMetricTypeIdNumericValueCallback(MetricTypeIdEnum.LVSD_REG_UPDATED);
            const lvsdRegLastMetricValue = getMetricTypeIdNumericValueCallback(MetricTypeIdEnum.LVSD_REG_LAST);
            return  lvsdRegUpdatedMetricValue - lvsdRegLastMetricValue;
        }, [getMetricTypeIdNumericValueCallback]);
    
    return <>
        <CardTitle>Value of {patientsAdded} Added Patients</CardTitle>
        <p className="patients-statistics-additional-income-value">{additionalIncomeMetric}</p>
    </>
}

interface ScreeningOutcomeProps {
    metrics: Metric[]
}

const tickBase = [0, 20, 40, 60, 80, 100];

const layoutTemplate : Partial<Layout> = {
    autosize: true,
    bargap: 0,
    bargroupgap: 0,
    margin: {
        t: 0,
        b: 20,
        l: 0,
        r: 0,
        pad: 5
    },
    barmode: "stack",
    legend: {
        orientation: "h",
        xanchor: "left",
        traceorder: "normal",
        x: 0,
        yanchor: 'bottom',
        y: 1.0
    },
    font: {
        family: "Proxima Nova"
    },
    yaxis: {
      showticklabels: false, 
      fixedrange: true        
    },
    xaxis: {
        autotick: false,
        tickmode: "array",
        fixedrange: true,
        ticktext: tickBase.map(o => `${o}%`)
    }
}


const traceTemplate: Partial<PlotData> = {
    y: ['Screening outcome'],
    type: "bar",
    orientation: "h",
    width: 0.5,
    hoverinfo: "skip",
    textposition: "middle center",
    // This property works but, it is not exposed by typings
    // @ts-ignore
    insidetextanchor: "middle"
}

const ScreeningOutcome = ({metrics}: ScreeningOutcomeProps) => {
    const getMetricValue = useCallback((metricId: MetricTypeIdEnum) => {
        const value = metrics.find(m => m.metricTypeId === metricId)?.value;
        return [value ?? 0];
    }, [metrics]);
    
    const data: Partial<PlotData>[] = [
        {
            ...traceTemplate,
            x: getMetricValue(MetricTypeIdEnum.LVSD_REG_LAST),
            name: 'LVSD Register',
            marker: {
                color: Purple
            }
        },
        {
            ...traceTemplate,
            x: getMetricValue(MetricTypeIdEnum.SCREEN_LVSD_CONFIRMED),
            name: 'LVSD Confirmed',
            marker: {
                color: Blue
            }
        },
        {
            ...traceTemplate,
            x: getMetricValue(MetricTypeIdEnum.SCREEN_NOT_SCREEN_PRIORITY),
            name: 'Not Assessed (Priority group)',
            marker: {
                color: DarkGray
            }
        },
        {
            ...traceTemplate,
            x: getMetricValue(MetricTypeIdEnum.SCREEN_NOT_SCREEN_OTHER),
            name: 'Not Assessed (Other)',
            marker: {
                color: LightGray
            }
        },
        {
            ...traceTemplate,
            x: getMetricValue(MetricTypeIdEnum.SCREEN_QUERY),
            name: 'Query LVSD',
            marker: {
                color: Orange
            }
        },
        {
            ...traceTemplate,
            x: getMetricValue(MetricTypeIdEnum.SCREEN_HFPEF),
            name: 'HFpEF',
            marker: {
                color: Fuchsia
            }
        }
    ].map(o => ({
        ...o,
        text: o.x.toString()
    }));
    
    const maxValue = _
        .chain(data)
        .flatMap(o => o.x)
        .sum()
        .valueOf();
    
    const layout : Partial<Layout> = {
        ...layoutTemplate,
        xaxis: {
            ...layoutTemplate.xaxis,
            domain: [0, maxValue],
            tickvals: tickBase.map(o => o * maxValue / 100.0)
        }
    }
    
    return <Plot
        className="patients-statistics-chart"
        data={data}
        layout={layout}
        config={DEFAULT_PLOTLY_CONFIG}/>
};

interface PatientsStatisticsProps {
    metricsData: MetricsData
}

const PatientsStatistics: React.FC<PatientsStatisticsProps> = ({metricsData}) => {    
    const {metrics, isLoading } = metricsData;
    
    if (isLoading || !metrics) {
        return <CardLoaderMemo cardClassName="patients-statistics-root"/>;
    }
    
    return <Card className="patients-statistics-root">
        <CardBody className="patients-statistics-card-body">
            <div className="patients-statistics-priority-group">
                <CardTitle>Priority Group</CardTitle>
                <PriorityGroup metrics={metrics}/>
            </div>
            <div className="patients-statistics-chart-container">
                <CardTitle>Screening Outcome</CardTitle>
                <ScreeningOutcome metrics={metrics}/>
            </div>
            <div className="patients-statistics-additional-income ">
                {metrics && <AdditionalIncome metrics={metrics}/>}
            </div>
        </CardBody>
    </Card>;
}

export const PatientsStatisticsMemo = React.memo(PatientsStatistics);