import './AdditionalIncomeMetrics.scss'

import {Metric} from "../../../../models/Metric";
import React, {useMemo} from "react";
import {CardLoaderMemo} from "../../../CardLoader/CardLoader";
import {Card, CardBody} from "reactstrap";
import {numericFormatter} from 'react-number-format';
import {MetricsData} from "../../../../models/MetricsData";

interface AdditionalIncomeMetricsProps {
    metricsData: MetricsData
}

const AdditionalIncomeMetrics: React.FunctionComponent<AdditionalIncomeMetricsProps> = ({metricsData}) => {
    const formatValue = (metric: Metric) =>
        numericFormatter(metric?.value ?? '', {
            thousandSeparator: ',',
            decimalSeparator: '.',
            prefix: '£',
            decimalScale: 2,
            fixedDecimalScale: true
        });

    const metric = useMemo(() => {
        if (metricsData.metrics) {
            const item = metricsData.metrics[0];
            return (
                <>
                    <div className="additional-income-metrics-card-text">
                        <div className="additional-income-metrics-card-title">{item?.metricTypeName}</div>
                        <div className="additional-income-metrics-card-description">
                            based on 100% achievement
                        </div>
                    </div>
                    <div className="additional-income-metrics-card-value">
                        {formatValue(item)}
                    </div>
                </>
            );
        }
    }, [metricsData.metrics]);

    return (
        <div className="additional-income-metrics-container">
            {metricsData.metrics && !metricsData.isLoading ? (
                <Card className="additional-income-metrics-card">
                    <CardBody className="additional-income-metrics-card-body">
                        {metric}                        
                    </CardBody>
                </Card>
            ) : (
                <CardLoaderMemo cardClassName='additional-income-metrics-loader'/>
            )}
        </div>
    );
};

export const AdditionalIncomeMetricsMemo = React.memo(AdditionalIncomeMetrics);