import './PatientsListColumnsSettingsBox.scss';

import { Button } from "@e360/react-core";
import { Button as PrimeButton } from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {FrozenColumnsSeparatorKey} from "../PatientsList";
import {CheckboxChangeEvent} from "primereact/checkbox";
import _ from "lodash";
import {ScrollPanel} from "primereact/scrollpanel";
import {DndProvider} from "react-dnd";
import {ColumnSettingLabelMemo} from "./ColumnSettingLabel/ColumnSettingLabel";
import {HTML5Backend} from "react-dnd-html5-backend";
import {UserColumnSettings} from "../../../../../models/UserColumnSettings";
import {
    deleteUserColumnsSettings,
    updateUserColumnsSettings
} from "../../../../../app/api/userPreferencesApi";

interface PatientsListColumnsSettingsBoxProps {
    show: boolean,
    columnsSettings: UserColumnSettings[],
    defaultColumnsSettings: UserColumnSettings[],
    updateColumnsSettingsCallback: (columnsSettings: UserColumnSettings[]) => void,
}

const PatientsListColumnsSettingsBox: React.FunctionComponent<PatientsListColumnsSettingsBoxProps> = ({show, columnsSettings, defaultColumnsSettings, updateColumnsSettingsCallback}) => {    
    const overlayPanelRef = useRef<OverlayPanel>(null);
    const [localColumnDefinitions, setLocalColumnsSettings] = useState<UserColumnSettings[]>(columnsSettings);
    
    useEffect(() => {
        setLocalColumnsSettings(columnsSettings);
    }, [columnsSettings])
    
    const onVisiblePropertyChanged = (e: CheckboxChangeEvent) => {  
        let _localColumnsSettings = [...localColumnDefinitions];
        
        const columnSetup = e.value as UserColumnSettings;
        const isChecked = e.checked as boolean;

        const columnSettingIndex = _localColumnsSettings.findIndex((cd => cd.columnKey === columnSetup.columnKey));
        _localColumnsSettings[columnSettingIndex].isVisible = isChecked;

        setLocalColumnsSettings(_localColumnsSettings);
    };

    const saveUserColumnsSettings = useCallback(async () => {
        updateUserColumnsSettings({
            userColumnsSettings: localColumnDefinitions
        }).then(() => {
            console.log('User columns settings have been saved successfully.');
        }).catch(() => {
            console.error('Could not save user columns settings.')
        });
    }, [localColumnDefinitions]);

    const clearUserColumnsSettings = useCallback(async () => {
        deleteUserColumnsSettings().catch(() => {
            console.error('Could not clear user columns settings.')
        });
    }, []);
    
    const onApplyButtonClicked = () => {
        updateColumnsSettingsCallback(localColumnDefinitions)
        setLocalColumnsSettings(localColumnDefinitions);
        saveUserColumnsSettings();
    };    
    
    const onRestoreDefaultButtonClicked = () => {
        updateColumnsSettingsCallback(defaultColumnsSettings);
        setLocalColumnsSettings(defaultColumnsSettings);
        clearUserColumnsSettings();
    };

    const setColumnPosition = useCallback((columnSettings: UserColumnSettings, position: number) =>
        ({
            ...columnSettings,
            position: position
        } as UserColumnSettings), []);
    
    const toggleColumnFreezeMode = useCallback((columnSettings: UserColumnSettings, freeze: boolean) =>
        ({
            ...columnSettings,
            isFrozen: freeze
        } as UserColumnSettings), []);

    const onLabelDrop = useCallback((sourceLabelKey: string, targetLabel: UserColumnSettings) => {
        const sourceLabelNewPosition = targetLabel.position;
        const repositionedLocalColumnDefinitions = _
            .map(localColumnDefinitions, colSetting => {
                if (colSetting.columnKey === sourceLabelKey) {
                    return setColumnPosition(colSetting, sourceLabelNewPosition);
                }
                if (colSetting.columnKey !== sourceLabelKey && colSetting.position >= sourceLabelNewPosition) {
                    return setColumnPosition(colSetting, ++colSetting.position);
                }
                return colSetting;
            });
        
        const frozenColumnsSeparatorPosition = repositionedLocalColumnDefinitions
            .find(cd => cd.columnKey === FrozenColumnsSeparatorKey)?.position ?? 0;
        const newLocalColumnDefinitions = _
            .map(repositionedLocalColumnDefinitions, colSetting => 
                toggleColumnFreezeMode(colSetting, colSetting.position < frozenColumnsSeparatorPosition));
        
        setLocalColumnsSettings(newLocalColumnDefinitions);
    }, [localColumnDefinitions, toggleColumnFreezeMode, setColumnPosition]);

    return (show ?
      <>
          <PrimeButton
              icon="fa fa-cog" 
              rounded 
              text 
              severity="secondary"
              size="large"
              onClick={(e) => overlayPanelRef.current?.toggle(e)}/>
          <OverlayPanel ref={overlayPanelRef} className='column-settings-overlaypanel'>
              <ScrollPanel style={{width: '100%', height: '300px'}} className='column-settings-list-scrollpanel'>
                  <DndProvider backend={HTML5Backend}>
                      {_.chain(localColumnDefinitions)
                          .sortBy((colSetting) => colSetting.position)
                          .map((colSettings) => {
                              return <ColumnSettingLabelMemo
                                  key={colSettings.columnKey}
                                  columnSettings={colSettings}
                                  onVisiblePropertyChanged={onVisiblePropertyChanged}
                                  onDrop={(sourceLabelKey, targetLabel) => onLabelDrop(sourceLabelKey, targetLabel)}
                              />
                          })
                          .value()}
                  </DndProvider>
              </ScrollPanel>
              <hr/>
              <div className='buttons-wrapper'>
                  <Button
                      block
                      variant="primary"
                      onClick={onApplyButtonClicked}>
                      Apply
                  </Button>
                  <Button
                      block
                      variant="bare"
                      onClick={onRestoreDefaultButtonClicked}>
                      Restore default
                  </Button>
              </div>
          </OverlayPanel>
      </> : <></>
  );
};

export const PatientsListColumnsSettingsBoxMemo = React.memo(PatientsListColumnsSettingsBox);