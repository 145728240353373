import './InlineLoader.scss';
import React from 'react';
//@ts-ignore
import { Loader } from '@e360/react-loader';

interface InlineLoaderProps {
    size:string;
    className?: string;
}

const InlineLoader: React.FunctionComponent<
    InlineLoaderProps> = ({size, className}) => {
    return (
        <Loader
            className={className}
            size={size}/>
    );
};

export const InlineLoaderMemo = React.memo(InlineLoader);