//@ts-ignore
import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import hfPracticeReducer, { PracticeState as HFPracticeState } from './slices/practiceSlice';
import heartFailureMenuReducer, { HeartFailureMenuState } from './slices/heartFailureMenuSlice';
import marketReducer, { MarketState } from './slices/marketSlice';
import userPreferencesReducer, { UserPreferencesState } from './slices/userPreferencesSlice';
import authReducer, { AuthState } from "./slices/authSlice";
import globalNavigationReducer, { GlobalNavigationState } from './slices/globalNavigationSlice';
import patientsListReducer, {PatientsListState} from "./slices/patientsListSlice";

const combinedReducers = combineReducers({
  practice: hfPracticeReducer,
  heartFailureMenu: heartFailureMenuReducer,
  market: marketReducer,
  userPreferences: userPreferencesReducer,
  auth: authReducer,
  globalNavigation: globalNavigationReducer,
  patientsList: patientsListReducer
});

export const store = configureStore({
  reducer: combinedReducers
});

export interface AppState {
  practice: HFPracticeState,
  heartFailureMenu: HeartFailureMenuState,
  market: MarketState,
  userPreferences: UserPreferencesState,
  auth: AuthState,
  globalNavigation: GlobalNavigationState,
  patientsList: PatientsListState
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

