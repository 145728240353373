import './PatientsKpiSingleValueMetrics.scss'
import React, {useMemo} from 'react';
import {getIconUrl} from '../../../../utils/api';
import {ICON_NAME} from '../../../../utils/icon-name';
import {Metric} from '../../../../models/Metric';
import {CardLoaderMemo} from '../../../CardLoader/CardLoader';
import {MetricTypeIdEnum} from '../../../../models/MetricTypeIdEnum';
import {
    PatientsKpiSingleValueMetricCardMemo
} from "./PatientsKpiSingleValueMetricCard/PatientsKpiSingleValueMetricCard";
import {formatMetricValue} from "../../../../utils/metric";

interface PatientsPrevalenceMetricsProps {
    metrics: Metric[] | undefined
}

const PatientsKpiSingleValueMetrics: React.FunctionComponent<PatientsPrevalenceMetricsProps> = ({metrics}) => {
    
    
    const metricCards = useMemo(
        () => {
            const iconPerMetricTypeId : {[id in string]: ICON_NAME} = {
                [MetricTypeIdEnum.HF_REVIEW_PAT_COUNT]: ICON_NAME.PEOPLE_PATIENT_GROUP,
                [MetricTypeIdEnum.LVSD_BB_PAT_COUNT]: ICON_NAME.HEALTHCARE_PILL,
                [MetricTypeIdEnum.LVSD_ACE_ARB_PAT_COUNT]: ICON_NAME.HEALTHCARE_PILL
            };
            if(metrics) {
                return metrics.map((metric: Metric, index: number) => {
                    const cardClassName = "patient-metric-card" + (index > 0 
                        ? ' next-card'
                        : '');

                    return (
                        <PatientsKpiSingleValueMetricCardMemo
                            key={'patient-metric-card-' + metric.metricTypeId}
                            cardClassName={cardClassName}
                            cardTitle={metric.metricTypeName}
                            cardValue={formatMetricValue(metric.value, metric.metricTypeValueType)}
                            iconSource={getIconUrl(iconPerMetricTypeId[metric.metricTypeId])}/>
                    );
                });
            }
        }, [metrics]);

    return (
        <div className="patients-kpi-single-value-cards-container">
            {metrics 
                ? metricCards
                : (<CardLoaderMemo 
                    cardClassName='patients-kpi-single-value-cards-loader'/>)}
        </div>
    );
};

export const PatientsKpiSingleValueMetricsMemo = React.memo(PatientsKpiSingleValueMetrics);