import { ReactSVG } from "react-svg";

interface PracticeInfoIconProps {
    iconSource: string;
}

export const PracticeInfoIcon: React.FunctionComponent<
PracticeInfoIconProps> = ({iconSource}) => {
    return (<ReactSVG 
        src={iconSource}
        afterInjection={(svg) => {
            svg.setAttribute('style', 'width: 50px')
            svg.setAttribute('style', 'height: 50px')
        }}/>
    );
};