import React from 'react';
import './TimeoutError.scss';
import { ErrorBlue, ErrorGray } from '../../../utils/palette';
import { GoHomeContactSupportButtonsMemo } from '../GoHomeContactSupportButtons/GoHomeContactSupportButtons';

interface TimeoutErrorProps {
    aborted: boolean;
}

const TimeoutError: React.FunctionComponent<
    TimeoutErrorProps> = ({aborted}) => {

    return(
        <div className='timeout-error-container'>
            <h1 
                className='timeout-error-title'
                style={{color: ErrorBlue}}>
                Request Timeout
            </h1>
            <h2 
                className='timeout-error-details'
                style={{color: ErrorGray}}>
                {aborted ? 'Request Aborted - request timeout reached' : 'The request has timed out'}
            </h2>
            <div className='timeout-error-buttons-container-wrapper'>
                <GoHomeContactSupportButtonsMemo/>
            </div>
        </div>
    );
}

export const TimeoutErrorMemo = React.memo(TimeoutError)