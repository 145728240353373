import './OverallScreeningProgressMetrics.scss'
import React, {useCallback, useMemo} from 'react';
import {Metric} from '../../../../models/Metric';
import {Card, CardBody, CardTitle} from '@e360/react-core';
import {MetricTypeIdEnum} from '../../../../models/MetricTypeIdEnum';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import {useAppSelector} from '../../../../app/hooks';
import {selectBaselineSelector} from '../../../../app/selectors/userPreferencesSelectors';
import {useMetrics} from '../../../../utils/useMetrics';
import {BaselineEnum} from '../../../../models/BaselineEnum';
import {Fuchsia, LightGray, Purple} from '../../../../utils/palette-hf';
import {BlueLoader} from '../../../BlueLoader/BlueLoader';
import {DEFAULT_PLOTLY_CONFIG} from "../../../../utils/plotly-commons";

const metricIds = [
    MetricTypeIdEnum.LVSD_REG_UPDATED,
    MetricTypeIdEnum.LVSD_REG_LAST,
    MetricTypeIdEnum.LVSD_REG_FIRST,
    MetricTypeIdEnum.QUERY_LVSD_FIRST,
    MetricTypeIdEnum.QUERY_LVSD_LAST,
    MetricTypeIdEnum.LVSD_QUERY_UPDATED,
    MetricTypeIdEnum.EXCLUDED_LVSD_FIRST,
    MetricTypeIdEnum.EXCLUDED_LVSD_LAST,
    MetricTypeIdEnum.LVSD_HFPEF_UPDATED,
];

const OverallScreeningProgressMetrics: React.FunctionComponent = () => {
    const baseline = useAppSelector(selectBaselineSelector);
    
    const {metrics, isLoading} = useMetrics(metricIds);

    const getMetricTypeIdCallback = useCallback(
        (metricTypeId: MetricTypeIdEnum) => {
            if (metrics) {
                return _.find(metrics, (metric: Metric) => metric.metricTypeId === metricTypeId);
            }
            return null;
        }, [metrics]);
    
    const getMetricTypeIdNumericValueCallback = useCallback(
        (metricTypeId: MetricTypeIdEnum) => {
            if (metrics) {
                return +(getMetricTypeIdCallback(metricTypeId)?.value ?? 0) ;
            }
            return 0;
        }, [metrics, getMetricTypeIdCallback]);

    const patientsAdded = useMemo(
        () => {
            const metricLvsdRegUpdatedValue = getMetricTypeIdNumericValueCallback(
                MetricTypeIdEnum.LVSD_REG_UPDATED);
            
            const metricLvsdRegLastValue = getMetricTypeIdNumericValueCallback(
                MetricTypeIdEnum.LVSD_REG_LAST);

            const value = metricLvsdRegUpdatedValue - metricLvsdRegLastValue;

            return value?.toString();
        }, [getMetricTypeIdNumericValueCallback]);
    
    const patientsAddedCard = useMemo(
        () => {
            return (
                <div className='overall-screening-progress-patients-added-wrapper'>
                    <div className='overall-screening-progress-patients-added-labels-wrapper'>
                        <span className='overall-screening-progress-patients-added-value'>
                            {patientsAdded}
                        </span>
                        <span className='overall-screening-progress-patients-added-label'>
                            patients added to register
                        </span>
                    </div>
                </div>
            )
        }, [patientsAdded])

    const codedLvsdMetrics = useMemo(
        () => {
            const updatedValue = getMetricTypeIdNumericValueCallback(
                MetricTypeIdEnum.LVSD_REG_UPDATED);

            let baselineValue: number = 0;

            switch (baseline) {
                case BaselineEnum.SINCE_LAST_REVIEW:
                    baselineValue = getMetricTypeIdNumericValueCallback(
                        MetricTypeIdEnum.LVSD_REG_LAST);
                    break;
                case BaselineEnum.SINCE_SERVICE_STARTED:
                    baselineValue = getMetricTypeIdNumericValueCallback(
                        MetricTypeIdEnum.LVSD_REG_FIRST);
                    break;
            }

            return [
                updatedValue,
                baselineValue,
            ];
        }, [baseline, getMetricTypeIdNumericValueCallback]);
    
    const notCodedMetrics = useMemo(
        () => {
            const updatedValue = getMetricTypeIdNumericValueCallback(MetricTypeIdEnum.LVSD_QUERY_UPDATED);

            let baselineValue: number = 0;

            switch (baseline) {
                case BaselineEnum.SINCE_LAST_REVIEW:
                    baselineValue = getMetricTypeIdNumericValueCallback(MetricTypeIdEnum.QUERY_LVSD_LAST);
                    break;
                case BaselineEnum.SINCE_SERVICE_STARTED:
                    baselineValue = getMetricTypeIdNumericValueCallback(MetricTypeIdEnum.QUERY_LVSD_FIRST);
                    break;
            }

            return [
                updatedValue,
                baselineValue,
            ];
        }, [baseline, getMetricTypeIdNumericValueCallback]);

    const codedHfpefMetrics = useMemo(
        () => {
            const updatedValue = getMetricTypeIdNumericValueCallback(MetricTypeIdEnum.LVSD_HFPEF_UPDATED)
            let baselineValue: number = 0;

            switch (baseline) {
                case BaselineEnum.SINCE_LAST_REVIEW:
                    baselineValue = getMetricTypeIdNumericValueCallback(MetricTypeIdEnum.EXCLUDED_LVSD_LAST);
                    break;
                case BaselineEnum.SINCE_SERVICE_STARTED:
                    baselineValue = getMetricTypeIdNumericValueCallback(MetricTypeIdEnum.EXCLUDED_LVSD_FIRST);
                    break;
            }

            return [
                updatedValue,
                baselineValue,
            ];
        }, [baseline, getMetricTypeIdNumericValueCallback])

    const layout: Partial<Plotly.Layout> = useMemo(
        () => {
            return { 
                autosize: true,
                barmode: 'stack',
                margin: {
                    t: 0,
                    b: 0,
                    // otherwise hides left yaxis scale labels
                    l: 110, 
                    //i.e. when resized to half of screen
                    // otherwise hides right yaxis scale labels 
                    r: 20, 
                    pad: 0
                },
                xaxis: {
                    fixedrange: true
                },
                yaxis: {
                    side:'left',
                    fixedrange: true
                },
                legend: {
                    orientation: 'h',
                    xanchor: "center",
                    x: 0.5,
                    traceorder: 'normal',
                    yanchor: 'top',
                },
                bargroupgap: 0.2,
                font: {
                    family: "Proxima Nova"
                }
            };
        }, []);

    const data = useMemo(
        () => {
            const yAxisLabels = [ 
                'Updated',
                'Baseline',
            ];

            const dataObject: Partial<Plotly.Data> = {
                type: 'bar',
                textposition: 'none',
                hoverinfo: 'x+y+text',
                y: yAxisLabels,
                orientation: 'h',
            }
            
            const data : Plotly.Data[] = [
                {
                    ...dataObject,
                    text: 'Coded LVSD (QOF)',
                    name: 'Coded LVSD (QOF)',
                    x: codedLvsdMetrics,
                    marker: {
                        color: Purple
                    },
                },
                {
                    ...dataObject,
                    text: 'HF Not Coded LVSD/HFpEF',
                    name: 'HF Not Coded LVSD/HFpEF',
                    x: notCodedMetrics,
                    marker: {
                        color: LightGray
                    },
                },
                {
                    ...dataObject,
                    text: 'Coded HFpEF',
                    name: 'Coded HFpEF',
                    x: codedHfpefMetrics,
                    marker: {
                        color: Fuchsia
                    }
                },
            ];
                
            return data;
        }, [
            codedLvsdMetrics,
            notCodedMetrics,
            codedHfpefMetrics,
        ]);

    return (
        <div className="overall-screening-progress-container">
                <Card className="overall-screening-progress-card">
                    <CardBody>
                        <CardTitle>Overall Screening Progress</CardTitle>
                        {metrics && !isLoading ? (
                            <div className='overall-screening-progress-metrics-wrapper'>
                                <div className='overall-screening-progress-patients-added-card-wrapper'>
                                    {patientsAddedCard}
                                </div>
                                <Plot
                                    className='overall-screening-progress-chart-wrapper'
                                    data={data}
                                    layout={layout}
                                    config={DEFAULT_PLOTLY_CONFIG}
                                    useResizeHandler={true}
                                    />
                            </div>
                        ) : (
                            <BlueLoader/>
                        )}
                    </CardBody>
                </Card>
        </div>
    );
};

export const OverallScreeningProgressMetricsMemo = React.memo(OverallScreeningProgressMetrics);