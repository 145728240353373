import React from "react";
import './PatientsKpiSingleValueMetricCard.scss';
//@ts-ignore
import { Card, CardBody, CardTitle} from '@e360/react-core';
import {
    PatientsKpiSingleValueMetricIconMemo
} from "../PatientsKpiSingleValueMetricIcon/PatientsKpiSingleValueMetricIcon";

interface PatientsPrevalenceMetricCardProps {
    iconSource: string;
    cardClassName: string;
    cardTitle: string;
    cardValue: string;
}

const PatientsKpiSingleValueMetricCard: React.FunctionComponent<
PatientsPrevalenceMetricCardProps> = ({iconSource, cardClassName, cardTitle, cardValue}) => {
    return (
        <Card className={cardClassName}>
            <CardBody className="patient-metric-card-body">
                <CardTitle className="patient-metric-card-title">
                    {cardTitle}
                </CardTitle>
                <div className="patient-metric-card-content">
                    <span className="patient-metric-card-card-value">
                        {cardValue}
                    </span>
                    <PatientsKpiSingleValueMetricIconMemo
                        className="patient-metric-icon"
                        iconSource={iconSource}/>
                </div>
            </CardBody>
        </Card>
    );
};

export const PatientsKpiSingleValueMetricCardMemo = React.memo(PatientsKpiSingleValueMetricCard);