import axios from "axios";
import { User } from "oidc-client-ts";
import {OIDC_CONFIG} from "../../index";

const getUser = () => {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${OIDC_CONFIG.authority}:${OIDC_CONFIG.client_id}`);
    if (!oidcStorage) {
        return null;
    }
    return User.fromStorageString(oidcStorage);
}

const _axios = axios.create();

const configure = () => {
    _axios.defaults.headers.common['Content-Type'] = 'application/json';
    _axios.defaults.withCredentials = true;
    _axios.defaults.timeout = 30 * 1000;
    _axios.interceptors.request.use((config) => {
        const user = getUser();
        const token = user?.id_token;
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    });
};

const configureHttpResponseErrorHandler = (httpResponseErrorHandler: (error: any) => void) => {
    _axios.interceptors.response.use(
        (response) => response,
        (error) => httpResponseErrorHandler(error)
    );
}

const configureBaseUrl = (baseUrl: string) => {
    _axios.defaults.baseURL = baseUrl;
}

const getAxiosClient = () => _axios;

const HttpService = {
    configure,
    configureHttpResponseErrorHandler,
    configureBaseUrl,
    getAxiosClient,
};

export default HttpService;
