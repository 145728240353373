import './PatientsListQuickFilter.scss';
import React, {useCallback, useMemo, useState} from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from '@e360/react-core';
import {useAppSelector} from "../../../../../app/hooks";
import {patientsListFiltersSelector} from "../../../../../app/selectors/patientsListSelectors";

interface PatientsListQuickFilterProps {
    show: boolean,
    updateQuickFilterKeyCallback: (filterKey: string | null) => void,
}

interface FilterDropdownItem {
    key?: string | null,
    label: string,
    isGroupHeader: boolean,
}

const EMPTY_FILTER_LABEL = 'No Filter';

const filterDropdownItems : FilterDropdownItem[] = [
    {key: null, label: EMPTY_FILTER_LABEL, isGroupHeader: false},
    {label: 'Non-LVSD Priority Groups', isGroupHeader: true},
    {key: 'code_ef_50', label: 'Code or EF <50%', isGroupHeader: false},
    {key: 'meds_code', label: 'LVSD Meds + Code', isGroupHeader: false},
    {key: 'lvsd_meds', label: 'LVSD Meds', isGroupHeader: false},
    {label: 'Other Non-LVSD', isGroupHeader: true},
    {key: 'ace_beta', label: 'ACE-i + Beta', isGroupHeader: false},
    {key: 'ho_echo', label: 'H/O Echo', isGroupHeader: false},
    {key: 'nil', label: 'NIL', isGroupHeader: false},
    {label: 'Register Groups', isGroupHeader: true},
    {key: 'lvsd_status_1', label: 'LVSD Confirmed', isGroupHeader: false},
    {key: 'hfpef', label: 'HFpEF', isGroupHeader: false},
    {key: 'lvsd_status_2', label: 'Query LVSD', isGroupHeader: false},
    {key: 'lvsd_status_3', label: 'LVSD Excluded', isGroupHeader: false}
];

const PatientsListQuickFilter: React.FunctionComponent<PatientsListQuickFilterProps> = ({show, updateQuickFilterKeyCallback}) => {
    const {quickFilterKey} = useAppSelector(patientsListFiltersSelector);
    const [selectedFilterIndex, setSelectedFilterIndex] = useState(filterDropdownItems.findIndex(i => i.key === quickFilterKey));
    
    const onFilterSelected = useCallback((index: number, item: FilterDropdownItem) => {
        setSelectedFilterIndex(index);
        updateQuickFilterKeyCallback(item.key ?? null);
    }, [updateQuickFilterKeyCallback]);
    
    const dropdownItemElements = useMemo(() => filterDropdownItems.map((item, index) => {
        const groupDivider = item.isGroupHeader ? <DropdownItem key={`divider-${index}`} divider /> : <></>;
        return (
            <React.Fragment key={index}>
                {groupDivider}
                <DropdownItem
                    header={item.isGroupHeader}
                    className={item.isGroupHeader ? 'quick-filter-item-header' : ''}
                    key={item.key}
                    active={selectedFilterIndex === index}
                    onClick={() => onFilterSelected(index, item)}>
                        {item.label}
                </DropdownItem>
            </React.Fragment>
        );
    }), [selectedFilterIndex, onFilterSelected]);
    
    return (show ?
        <div className="patients-list-quick-filter-wrapper">
            <h6 className="quick-filter-title">Quick Filter:</h6>
            <Dropdown className="quick-filter-dropdown">
                <DropdownToggle variant="secondary">{filterDropdownItems[selectedFilterIndex].label}</DropdownToggle>
                <DropdownMenu>
                    {dropdownItemElements}
                </DropdownMenu>
            </Dropdown>
        </div>
    : <></>);
};

export const PatientsListQuickFilterMemo = React.memo(PatientsListQuickFilter);