import React, { useMemo } from 'react';
import './ScheduledMaintenance.scss';
import { getIconUrl } from '../../../utils/api';
import { ReactSVG } from 'react-svg';
import { ICON_NAME } from '../../../utils/icon-name';
import { ErrorBlue, ErrorGray } from '../../../utils/palette';

const ScheduledMaintenance = () => {

    const maintenanceEndTime = window?.appSettings?.maintenanceEndTimeString;
    const maintenanceEndTimeLabel = maintenanceEndTime
        ? `Our site is undergoing scheduled maintenance until`
        : `Our site is undergoing scheduled maintenance`;
    
    const companyIconUrl = useMemo(
        () => getIconUrl(ICON_NAME.COMPANY_LOGO_HORIZONTAL),
        []);
        
    const coneIconUrl = useMemo(
        () => getIconUrl(ICON_NAME.CONE),
        []);

    return(
        <div className='scheduled-maintenance-container'>
            <ReactSVG 
                className='scheduled-maintenance-company-icon'
                src={companyIconUrl}
                afterInjection={(svg) => {
                    svg.setAttribute('style', 'height: 50px; width: 150px')
                }}/>
            <ReactSVG 
                className='scheduled-maintenance-cone-icon'
                src={coneIconUrl}
                afterInjection={(svg) => {
                    svg.setAttribute('style', 'height: 90px; width: 90px')
                }}/>

            <h1 
                className='scheduled-maintenance-title'
                style={{color: ErrorBlue}}>
                We'll be back shortly
            </h1>
            <p 
                className='scheduled-maintenance-description'
                style={{color: ErrorGray}}>
                {maintenanceEndTimeLabel}
            </p>
                { maintenanceEndTime 
                    ? (<p 
                        className='scheduled-maintenance-end-time'
                        style={{color: ErrorGray}}>
                        {maintenanceEndTime}
                    </p>)
                    : (<></>)
                }
        </div>
    );
}

export const ScheduledMaintenanceMemo = React.memo(ScheduledMaintenance)