//@ts-ignore
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaselineEnum } from '../../models/BaselineEnum';

export interface UserPreferencesState {
  baseline: BaselineEnum | undefined;
}

const initialState: UserPreferencesState = {
  baseline: undefined,
};

export const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setBaseline: (state: UserPreferencesState, action: PayloadAction<BaselineEnum>) => {
        const baseline: BaselineEnum = action.payload;
        state.baseline = baseline;
    }
  },
  extraReducers: (builder: any) => {
  },
});

export const { setBaseline } = userPreferencesSlice.actions;

export default userPreferencesSlice.reducer;
