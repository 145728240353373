import './ChangeInHFXXXMetric.scss'
import React, {useCallback, useMemo, useState} from 'react';
import {chainGroupBaselineRelatedMetrics} from "../../../../../utils/metric";
import Plot from "react-plotly.js";
import {MetricsData} from "../../../../../models/MetricsData";
import {numericFormatter} from "react-number-format";
import {Metric} from "../../../../../models/Metric";
import {DEFAULT_PLOTLY_CONFIG} from "../../../../../utils/plotly-commons";

interface ChangeInHFXXXMetricProps {
    metricsData: MetricsData,
    metricNumber: string,
    metricTooltip: JSX.Element
}

const ChangeInHFXXXMetric: React.FunctionComponent<ChangeInHFXXXMetricProps> = ({metricsData, metricNumber, metricTooltip}) => {
    const [resize, setResize] = useState<boolean>(false);
    
    const onAutosizeCallback = useCallback(
        (shouldResize: boolean)=> {
            if (metricsData.metrics && !metricsData.isLoading) {
                setResize(shouldResize);
            }
        }, [metricsData.metrics, metricsData.isLoading]);

    const formatValue = useCallback((metric: Metric | undefined, decimalScale: number) => {
        return numericFormatter(metric?.value ?? '0', {
            decimalSeparator: '.',
            decimalScale: decimalScale
        });
    }, []);
    
    const layout: Partial<Plotly.Layout> = useMemo(
        () => {
            const showXAxisLabels = metricNumber === 'HF003' || metricNumber === 'HF006';
            return {
                autosize: true,
                width: 380,
                height: 220,
                margin: {
                    t: 20,
                    b: 10,
                    l: 0,
                    r: 0,
                    pad: 10
                },
                yaxis: {
                    side: 'left',
                    range: [0, 100],
                    rangemode: "tozero",
                    ticksuffix: '%',
                    automargin: true,
                    showgrid: true,
                    fixedrange: true
                },
                xaxis: {
                    showgrid: false,
                    automargin: true,
                    showticklabels: showXAxisLabels,
                    showline: false,
                    tickvals: [-1, 0, 1],
                    ticktext: ['Baseline','','Updated'],
                    tickmode: "array",
                    range: [-1.75, 1.75],
                    zerolinecolor: "#d9d9d9",
                    tickfont: {
                        size: 16,
                        color: 'black',
                        
                    },
                    ticklabelposition: 'outside',
                    fixedrange: true
                },
                showlegend: false,
                font: {
                    family: "Proxima Nova"
                }
            };
        }, [metricNumber]);

    const metricsChart = useMemo(
        () => {
            const xAxisLabels = [ -1, 1 ];
            const data : Plotly.Data[] =
                    chainGroupBaselineRelatedMetrics(metricsData.metrics)
                    .map((metricsGroup) => {
                        const metricType = metricsGroup[0].metricTypeId;                        
                        const metricName = metricsGroup[0].metricTypeName;
                        const isQoFMetric = metricType.includes("QOF");
                        const isTrueMetric = metricType.includes("TRUE");
                        if (isQoFMetric || isTrueMetric) {
                            const baselineMetric = metricsGroup
                                ?.find(mg => mg.metricTypeId.includes("FIRST") || mg.metricTypeId.includes("LAST"));
                            const updatedMetric = metricsGroup
                                ?.find(mg => mg.metricTypeId.includes("UPDATED"));
                            
                            const baselineValue = formatValue(baselineMetric, 0);
                            const updatedValue = formatValue(updatedMetric, 0);
                            const color = isQoFMetric ? '#CFA9D8' : '#9E54B0';
                            return {
                                type: 'scatter',
                                name: metricName,
                                x: xAxisLabels,
                                y: [baselineValue, updatedValue],
                                marker: {
                                    size: 12,
                                    color: color
                                },
                                mode: 'lines+markers',
                                line: {
                                    dash: isQoFMetric ? 'dot' : ''
                                }
                            } as Plotly.Data;
                        }
                        return {};
                    }).value();

            const getPointMetricAnnotations = () => {
                const pointMetrics = metricsData.metrics?.filter(m => m.metricTypeId.includes("POINT"));
                const baselineMetric = pointMetrics?.find(mg => mg.metricTypeId.includes("FIRST") || mg.metricTypeId.includes("LAST"));
                const updatedMetric = pointMetrics?.find(mg => mg.metricTypeId.includes("UPDATED"));
                const totalMetric = pointMetrics?.find(mg => mg.metricTypeId.includes("TOTAL"));

                const baselineMetricValue = formatValue(baselineMetric, 1);
                const updatedMetricValue = formatValue(updatedMetric, 1);
                const totalMetricValue = formatValue(totalMetric, 0);
                
                const annotation = {
                    xanchor: 'center',
                    y: 1,
                    yanchor: 'bottom',
                    showarrow: false,
                    font: {
                        color: '#9b9b9b'
                    }
                };

                return [
                    {
                        ...annotation,
                        x: -0.875,
                        text: `${baselineMetricValue} / ${totalMetricValue}pts`,
                    },
                    {
                        ...annotation,
                        x: 0.875,
                        text: `${updatedMetricValue} / ${totalMetricValue}pts`,
                    }];
            };
            
            return(
                <Plot
                    className='change-in-hfxxx-plot'
                    data={data}
                    layout={{
                        ...layout,
                        annotations: getPointMetricAnnotations()
                    } as Partial<Plotly.Layout>}
                    config={DEFAULT_PLOTLY_CONFIG}
                    useResizeHandler={true}
                    onAutoSize={()=> onAutosizeCallback(!resize)}
                />);
        }, [resize, layout, onAutosizeCallback, metricsData.metrics, formatValue]);
    
    
    return (
        <>
            {metricTooltip}
            <h5 className="hfxxx-metric-title">
                Change in {metricNumber}
                <i id={`${metricNumber}-tooltip-target`}
                   className='fa fa-info-circle hfxxx-metric-info-icon'                   
                   aria-hidden="true"
                   data-pr-position="right">
                </i>
            </h5>
            {metricsChart}
        </>
    );
};

export const ChangeInHFXXXMetricMemo = React.memo(ChangeInHFXXXMetric);