import { useMemo } from "react";
import { MODULE_URLS } from "../../app/module-urls";
import { getAppUrl } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { useInit } from "../../utils/custom-react-hooks";
import React from "react";

import "./RedirectPage.scss"


const RedirectPage = () => {
    const navigate = useNavigate();
    const redirectUrl = useMemo(
        () => getAppUrl(
            [MODULE_URLS.PERFORMANCE_SUMMARY_DASHBOARD]),
        []);
    
        useInit(
            () => navigate(MODULE_URLS.PERFORMANCE_SUMMARY_DASHBOARD)
        );
    
    return (
        <div>
            Redirecting to: {redirectUrl}
        </div>
    )
};

export const RedirectPageMemo = React.memo(RedirectPage);