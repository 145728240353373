import _, {chain, join, split} from "lodash";
import { MetricValueTypeEnum } from "../models/MetricValueTypeEnum";
import {Metric} from "../models/Metric";
import {MetricTypeIdEnum} from "../models/MetricTypeIdEnum";

export const formatMetricValue = (
    value: string,
    metricValueType: MetricValueTypeEnum,
    unit?: string | null,
    unitAsPrefix?: boolean) => {
        let result = "";
        switch (metricValueType) {
            case MetricValueTypeEnum.DECIMAL:
                result = parseFloat(value.replace(',', '.')).toFixed(2).toString();
                break;
            default:
                result = value;
                break;
        }
        if (unit) {
            return unitAsPrefix ? 
                `${unit}${result}` : 
                `${result}${unit}`;
        }
        return result;
    };

export const getMetricsSum = (metrics: Metric[] | undefined, ...metricTypeIds: MetricTypeIdEnum[]) => {
    if (metrics === undefined) {
        return 0.0;
    }
    
    return _
        .chain(metrics)
        .filter(o => metricTypeIds.includes(o.metricTypeId))
        .sumBy(o => parseFloat(o.value.replace(',', '.')))
        .valueOf();
};

export const chainGroupBaselineRelatedMetrics = (metrics: Metric[] | undefined) => chain(metrics)
    .groupBy(metric => join(split(metric.metricTypeId, '_').slice(0, -1), '_'));