import './RegisterOverTimeMetrics.scss'
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Card, CardBody, CardTitle } from '@e360/react-core';
import Plot from 'react-plotly.js';
import {TemporalMetricsCollection} from "../../../../models/TemporalMetricsCollection";
import {CardLoaderMemo} from "../../../CardLoader/CardLoader";
import {TemporalMetricTypeIdEnum} from "../../../../models/TemporalMetricTypeIdEnum";
import {Purple, Fuchsia, Orange} from "../../../../utils/palette-hf";
import {DEFAULT_PLOTLY_CONFIG} from "../../../../utils/plotly-commons";

interface RegisterOverTimeMetricsProps {
    lvsdCodedTemporalMetrics: TemporalMetricsCollection[] | undefined,
    lvsdQueryTemporalMetrics: TemporalMetricsCollection[] | undefined,
    lvsdExcludedTemporalMetrics: TemporalMetricsCollection[] | undefined,
}

const RegisterOverTimeMetrics: React.FunctionComponent<RegisterOverTimeMetricsProps> = ({lvsdCodedTemporalMetrics, lvsdQueryTemporalMetrics, lvsdExcludedTemporalMetrics}) => {
    const [resize, setResize] = useState<boolean>(false);
    
    const [lvsdCodedPercTemporalMetrics, setLvsdCodedPercTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdCodedCountTemporalMetrics, setLvsdCodedCountTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdQueryPercTemporalMetrics, setLvsdQueryPercTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdQueryCountTemporalMetrics, setLvsdQueryCountTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdExcludedPercTemporalMetrics, setLvsdExcludedPercTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdExcludedCountTemporalMetrics, setLvsdExcludedCountTemporalMetrics] = useState<TemporalMetricsCollection>();
    
    const containsData = useMemo(() => {
        return lvsdCodedTemporalMetrics?.length 
            && lvsdQueryTemporalMetrics?.length
            && lvsdExcludedTemporalMetrics?.length;
    }, [lvsdCodedTemporalMetrics, lvsdQueryTemporalMetrics, lvsdExcludedTemporalMetrics])
    
    useEffect(() => {
        if (containsData) {
            setLvsdCodedPercTemporalMetrics(lvsdCodedTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_PERC));
            setLvsdCodedCountTemporalMetrics(lvsdCodedTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_COUNT));
            setLvsdQueryPercTemporalMetrics(lvsdQueryTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_QUERY_PERC));
            setLvsdQueryCountTemporalMetrics(lvsdQueryTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_QUERY_COUNT));
            setLvsdExcludedPercTemporalMetrics(lvsdExcludedTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_EXCLUDED_PERC));
            setLvsdExcludedCountTemporalMetrics(lvsdExcludedTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_EXCLUDED_COUNT));
        }
    },[containsData, lvsdCodedTemporalMetrics, lvsdQueryTemporalMetrics, lvsdExcludedTemporalMetrics]);
    
    const onAutosizeCallback = useCallback(
        (shouldResize: boolean)=> {
            setResize(shouldResize);
        }, []);

    const layout: Partial<Plotly.Layout> = useMemo(
        () => {
            return {
                autosize: true,
                margin: {
                    t: 0,
                    b: 50,
                    l: 50,
                    r: 20,
                    pad: 5
                },
                yaxis: {
                    side: 'left',
                    rangemode: 'tozero',
                    title: 'Patients',
                    titlefont: {
                        family: "Proxima Nova"
                    },
                    ticksuffix: '%',
                    fixedrange: true
                },
                xaxis: {
                    showgrid: false,
                    dtick: 'M1',
                    tickformat: "%B\n%Y",
                    ticks: 'inside',
                    fixedrange: true
                },
                legend: {
                    orientation: 'h',
                    xanchor: "center",
                    x: 0.5,
                    y: 1.1,
                    yanchor: 'top',
                },
                font: {
                    family: "Proxima Nova"
                }
            };
        }, []);
    
    const metricsChart = useMemo(
        () => {            
            const getTemporalMetricsTrace = (
                temporalMetricsPercCollection: TemporalMetricsCollection, 
                temporalMetricsCountCollection: TemporalMetricsCollection, 
                colorHex: string) => 
            {
                
                const xValues : Date[] = [];
                const yValues : number[] = [];
                const textValues : string[] = [];

                temporalMetricsPercCollection?.values?.forEach(item => {
                    xValues.push(item.date);
                    yValues.push(Number(item.value));
                });

                temporalMetricsCountCollection?.values?.forEach(item => {
                    textValues.push(item.value);
                });

                const data: Plotly.Data = {
                    mode: 'lines+markers',
                    name: temporalMetricsPercCollection?.metricTypeName ?? 'Unknown metric',
                    marker: {
                        color: colorHex,
                        size: 6
                    },
                    x: xValues,
                    y: yValues,
                    text: textValues,
                    hoverinfo: 'x+text'
                };
                return data;
            };
            
            const getTracesData = [
                    getTemporalMetricsTrace(lvsdCodedPercTemporalMetrics!, lvsdCodedCountTemporalMetrics!, Purple),
                    getTemporalMetricsTrace(lvsdQueryPercTemporalMetrics!, lvsdQueryCountTemporalMetrics!, Orange),
                    getTemporalMetricsTrace(lvsdExcludedPercTemporalMetrics!, lvsdExcludedCountTemporalMetrics!, Fuchsia),
                ];
            
            return(
                <Plot
                    className='register-over-time-plot'
                    data={getTracesData}
                    layout={layout}
                    config={DEFAULT_PLOTLY_CONFIG}
                    useResizeHandler={true}
                    onAutoSize={()=> onAutosizeCallback(!resize)}/>);
        }, [
            resize, 
            layout, 
            onAutosizeCallback, 
            lvsdCodedPercTemporalMetrics,
            lvsdCodedCountTemporalMetrics,
            lvsdQueryPercTemporalMetrics,
            lvsdQueryCountTemporalMetrics,
            lvsdExcludedPercTemporalMetrics,
            lvsdExcludedCountTemporalMetrics]);

    return (
        <div className="register-over-time-container">
            {containsData ? (
                <Card className="register-over-time-card">
                    <CardBody>
                        <CardTitle>HF/LVSD diagnosis classification over time</CardTitle>
                        {metricsChart}
                    </CardBody>
                </Card>
            ) : (
                <CardLoaderMemo
                    cardClassName='register-over-time-loader'/>
            )}

        </div>
    );
};

export const RegisterOverTimeMetricsMemo = React.memo(RegisterOverTimeMetrics);