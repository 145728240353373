//@ts-ignore
import {Provider} from "react-redux";
import {store} from "./app/store";
import { createRoot } from 'react-dom/client';
import { App } from './App';
import React from 'react';
import { AuthProvider } from "react-oidc-context";
import HttpService from "./app/services/http-service";

export const OIDC_CONFIG = {
    authority: window.appSettings.auth.authority,
    client_id: window.appSettings.auth.clientId,
    redirect_uri: window.appSettings.auth.redirectUri,
    response_type: window.appSettings.auth.responseType,
    scope: window.appSettings.auth.scope,
    automaticSilentRenew: window.appSettings.auth.automaticSilentRenew
};

const root = createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AuthProvider {...OIDC_CONFIG}>
                <App />
            </AuthProvider>
        </Provider>
    </React.StrictMode>
);

HttpService.configure();