import "./TopCellTemplate.scss";
import {Card, CardBody, CardTitle} from "@e360/react-core";
import {useMetrics} from "../../../../utils/useMetrics";
import {MetricTypeIdEnum} from "../../../../models/MetricTypeIdEnum";
import {useMemo} from "react";
import _ from "lodash";
import {getMetricsSum} from "../../../../utils/metric";
import Plot from "react-plotly.js";
import {Metric} from "../../../../models/Metric";
import {Layout, PieData} from "plotly.js";
import {DarkGray, Ultramarine} from "../../../../utils/palette-hf";
import {BlueLoader} from "../../../BlueLoader/BlueLoader";
import {PieFont} from "plotly.js/lib/traces/pie";
import {DEFAULT_PLOTLY_CONFIG} from "../../../../utils/plotly-commons";

export interface DonutProps {
    groupSize: number,
    metrics: Metric[],
    completedSizeMetrics: MetricTypeIdEnum[]
}

const donutLayout: Partial<Layout> = {
    autosize: true,
    showlegend: false,
    font: {
        family: "Proxima Nova"
    },
    margin: {
        t: 0,
        b: 0,
        l: 0,
        r: 0,
        pad: 5
    }
}

const Donut = ({groupSize, metrics, completedSizeMetrics}: DonutProps) => {
    const completedSize = getMetricsSum(metrics, ...completedSizeMetrics);
    const remainingSize = groupSize - completedSize;
    
    const data: Partial<PieData>[] = [
        {
            type: "pie",
            values: [completedSize, remainingSize],
            textinfo: "none",
            hoverinfo: "none",
            marker: {
                colors: [Ultramarine, DarkGray] 
            },
            hole: 0.8
        }
    ]
    
    return <div className="opportunity-dashboard-top-cell-template-donut-host">
        <Plot
            className="opportunity-dashboard-top-cell-template-donut"
            data={data}
            layout={donutLayout}
            config={DEFAULT_PLOTLY_CONFIG}/>
        <div className="opportunity-dashboard-top-cell-template-donut-label-container">
            <div className="opportunity-dashboard-top-cell-template-donut-label">
                {completedSize}
                <div className="opportunity-dashboard-top-cell-template-donut-label-caption">Completed</div>
            </div>
        </div>
    </div>
}

const pieLayout: Partial<Layout> = {
    autosize: true,
    font: {
        family: "Proxima Nova"
    },
    margin: {
        t: 0,
        b: 0,
        l: 0,
        r: 0,
        pad: 5
    }
}

export interface Slice {
    label: string,
    metricTypes: MetricTypeIdEnum[],
    color: string
}
interface PieProps {
    slices: Slice[],
    metrics: Metric[]
}

const Pie = ({slices, metrics}: PieProps) => {
    const values = slices.map(s => getMetricsSum(metrics, ...s.metricTypes));
    const data: Partial<PieData>[] = [{
        type: "pie",
        values: values,
        textinfo: "value",
        hoverinfo: "none",
        textposition: values.map(val => val > 0 ? 'auto' : 'none'),
        labels: slices.map(s => s.label),
        marker: {
            colors: slices.map(s => s.color),            
        },
        insidetextfont: {color: "white"} as PieFont,
        outsidetextfont: {color: "black"} as PieFont,
        sort: false
    }];
    return <div className="opportunity-dashboard-top-cell-template-pie-host">
        <Plot
            className="opportunity-dashboard-top-cell-template-pie"
            data={data}
            layout={pieLayout}
            config={DEFAULT_PLOTLY_CONFIG}/>
    </div>
}

export interface TopCellTemplateProps {
    title: string,
    groupSizeMetrics: MetricTypeIdEnum[],
    groupSizeCaption: React.ReactNode,
    completedSizeMetrics: MetricTypeIdEnum[],
    slices: Slice[]
}

export const TopCellTemplate = (
    {
        title,
        groupSizeMetrics,
        groupSizeCaption,
        completedSizeMetrics,
        slices
    } : TopCellTemplateProps) => {
    
    const allMetrics = useMemo(() => {
        const sliceMetrics = slices.flatMap(o => o.metricTypes);
        return _.uniq([...groupSizeMetrics, ...completedSizeMetrics, ...sliceMetrics]);
    }, [groupSizeMetrics, completedSizeMetrics, slices]);
    
    const {metrics, isLoading} = useMetrics(allMetrics);
    
    const groupSize = getMetricsSum(metrics, ...groupSizeMetrics);

    return <Card className="opportunity-dashboard-top-cell-template-root">
        <CardBody>
            <CardTitle>{title}</CardTitle>
            {isLoading && <BlueLoader/>}
            {metrics && <>
                <div className="opportunity-dashboard-top-cell-template-group-size">
                    {groupSize}
                </div>
                <div>{groupSizeCaption}</div>
                <hr/>
                <p>Screening summary</p>
                <div className="opportunity-dashboard-top-cell-template-chart-host">
                    <Donut
                        groupSize={groupSize}
                        metrics={metrics}
                        completedSizeMetrics={completedSizeMetrics}/>
                    <Pie metrics={metrics} slices={slices}/>
                </div>
            </>} 
        </CardBody>
    </Card>
}