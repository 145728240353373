import './ChangeInHFXXXMetrics.scss'
import React, {useMemo} from 'react';
import {CardLoaderMemo} from '../../../CardLoader/CardLoader';
import {Card, CardBody} from '@e360/react-core';
import {MetricsData} from "../../../../models/MetricsData";
import {ChangeInHFXXXMetricMemo} from "./ChangeInHFXXXMetric/ChangeInHFXXXMetric";
import {Tooltip} from "primereact/tooltip";
import {ICON_NAME} from "../../../../utils/icon-name";
import {getIconUrl} from "../../../../utils/api";
import {
    hf003MetricDescription,
    hf006MetricDescription,
    hf007MetricDescription,
    hf008MetricDescription
} from "../../HfCommonItems";

interface ChangeInHFXXXMetricsProps {
    metricsData: MetricsData,
}

const ChangeInHFXXXMetrics: React.FunctionComponent<ChangeInHFXXXMetricsProps> = ({metricsData}) => {
    
    const hf008Metrics = useMemo(() => {
        return {
            metrics: metricsData.metrics?.filter(m => m.metricTypeId.startsWith("HF008")) ?? [],
            isLoading: metricsData.isLoading
        };
    }, [metricsData.metrics, metricsData.isLoading]);
    const hf007Metrics = useMemo(() => {
        return {
            metrics: metricsData.metrics?.filter(m => m.metricTypeId.startsWith("HF007")) ?? [],
            isLoading: metricsData.isLoading
        };
    }, [metricsData.metrics, metricsData.isLoading]);
    const hf003Metrics = useMemo(() => {
        return {
            metrics: metricsData.metrics?.filter(m => m.metricTypeId.startsWith("HF003")) ?? [],
            isLoading: metricsData.isLoading
        };
    }, [metricsData.metrics, metricsData.isLoading]);
    const hf006Metrics = useMemo(() => {
        return {
            metrics: metricsData.metrics?.filter(m => m.metricTypeId.startsWith("HF006")) ?? [],
            isLoading: metricsData.isLoading
        };
    }, [metricsData.metrics, metricsData.isLoading]);
    
    
    const getHFMetricTooltip = (metricNumber: string, metricDescriptionContent: JSX.Element | string) => {
        const tooltipTargetClass = `#${metricNumber}-tooltip-target`;
        return (
            <Tooltip target={tooltipTargetClass} className="hfxxx-metric-info-tooltip">
                <div>
                    <strong>{metricNumber}</strong><br/>
                    <div>
                        {metricDescriptionContent}
                    </div>
                </div>
            </Tooltip>
        );
    };

    const getLegendImage = () => {
        const iconSrc = getIconUrl(ICON_NAME.CHANGE_IN_HFXXX_CHARTS_LEGEND);
        return (
            <div className="hfxxx-metric-charts-legend">
                <img alt={iconSrc} src={iconSrc} />
            </div>
        );
    };
    
    return (
        <div className="change-in-hfxxx-metrics-container">
            {metricsData.metrics && !metricsData.isLoading ? (
                <Card className="change-in-hfxxx-metrics-card">
                    <CardBody className="change-in-hfxxx-metrics-card-body">
                        <div className="change-in-hfxxx-row">
                            <div className="change-in-hfxxx-plot-wrapper">
                                <ChangeInHFXXXMetricMemo 
                                    metricNumber="HF008"
                                    metricTooltip={getHFMetricTooltip("HF008", hf008MetricDescription)}
                                    metricsData={hf008Metrics}/>
                            </div>
                            <div className="change-in-hfxxx-plot-wrapper">
                                <ChangeInHFXXXMetricMemo
                                    metricNumber="HF007"
                                    metricTooltip={getHFMetricTooltip("HF007", hf007MetricDescription)}
                                    metricsData={hf007Metrics}/>
                            </div>
                        </div>
                        <div className="change-in-hfxxx-row">
                            <div className="change-in-hfxxx-plot-wrapper">
                                <ChangeInHFXXXMetricMemo
                                    metricNumber="HF003"
                                    metricTooltip={getHFMetricTooltip("HF003", hf003MetricDescription)}
                                    metricsData={hf003Metrics}/>
                            </div>
                            <div className="change-in-hfxxx-plot-wrapper">
                                <ChangeInHFXXXMetricMemo
                                    metricNumber="HF006"
                                    metricTooltip={getHFMetricTooltip("HF006", hf006MetricDescription)}
                                    metricsData={hf006Metrics}/>
                            </div>
                        </div>
                        <hr className="legend-divider"/>
                        {getLegendImage()}
                    </CardBody>
                </Card>
            ) : (
                <CardLoaderMemo cardClassName='change-in-hfxxx-metrics-loader'/>
            )}
        </div>
    );
};

export const ChangeInHFXXXMetricsMemo = React.memo(ChangeInHFXXXMetrics);