import React, {useMemo} from 'react'
import './PerformanceSummaryDashboard.scss';
import {PatientsPrevalenceMetricsMemo} from './PatientsPrevalenceMetrics/PatientsPrevalenceMetrics';
import {HeartFailureMenuMemo} from '../HeartFailureMenu/HeartFailureMenu';
import {
    ChangeInRegisterPrevalenceMetricsMemo
} from './ChangeInRegisterPrevalenceMetrics/ChangeInRegisterPrevalenceMetrics';
import {ListOfMetricsMemo} from "./ListOfMetrics/ListOfMetrics";
import {AdditionalIncomeMetricsMemo} from "./AdditionalIncomeMetrics/AdditionalIncomeMetrics";
import { PrevalenceAchievementMetricsMemo } from './PrevalenceAchievementMetrics/PrevalenceAchievementMetrics';
import {useAppSelector} from "../../../app/hooks";
import {selectBaselineSelector} from "../../../app/selectors/userPreferencesSelectors";
import {useMetrics} from "../../../utils/useMetrics";
import {BaselineEnum} from "../../../models/BaselineEnum";
import {MetricTypeIdEnum} from "../../../models/MetricTypeIdEnum";
import {selectPracticeSelector} from "../../../app/selectors/practiceSelectors";
import {ChangeInHFXXXMetricsMemo} from "./ChangeInHFXXXMetrics/ChangeInHFXXXMetrics";
import {WebAnalyticsTrackerMemo} from "../../WebAnalyticsTracker/WebAnalyticsTracker";
import { ModuleContainerMemo } from '../../ModuleContainer/ModuleContainer';

const patientPrevalenceMetricTypeIds = [
    MetricTypeIdEnum.HF_REG_TOTAL,
    MetricTypeIdEnum.LVSD_REG_TOTAL,
    MetricTypeIdEnum.HF_PERCENT,
    MetricTypeIdEnum.LVSD_PERCENT
];

const prevalenceAchievementMetricTypeIds = [
    MetricTypeIdEnum.QOF_POINTS_ACHIEVED,
    MetricTypeIdEnum.TOTAL_QOF_POINTS,
    MetricTypeIdEnum.CDG_HF_PERCENT,
    MetricTypeIdEnum.CDG_LVSD_PERCENT,
    MetricTypeIdEnum.ENG_HF_PERCENT,
    MetricTypeIdEnum.ENG_LVSD_PERCENT,
    MetricTypeIdEnum.HF_PREV_LAST_YR,
    MetricTypeIdEnum.LVSD_PREV_LAST_YR,
    MetricTypeIdEnum.REGION_NAME
];

const listOfMetricTypeIdsPerBaseline = {
    [BaselineEnum.SINCE_SERVICE_STARTED]: [
        MetricTypeIdEnum.HF_QOF_AMT_FIRST,
        MetricTypeIdEnum.HF_QOF_AMT_UPDATED,
        MetricTypeIdEnum.LVSD_QOF_AMT_FIRST,
        MetricTypeIdEnum.LVSD_QOF_AMT_UPDATED,
        MetricTypeIdEnum.LVSD_QOF_COUNT_FIRST,
        MetricTypeIdEnum.LVSD_QOF_COUNT_UPDATED,
        MetricTypeIdEnum.HF_DOMAIN_YR_AMT_FIRST,
        MetricTypeIdEnum.HF_DOMAIN_YR_AMT_UPDATED,
        MetricTypeIdEnum.HF_DOMAIN_PERC_AMT_FIRST,
        MetricTypeIdEnum.HF_DOMAIN_PERC_AMT_UPDATED
    ],
    [BaselineEnum.SINCE_LAST_REVIEW]: [
        MetricTypeIdEnum.HF_QOF_AMT_LAST,
        MetricTypeIdEnum.HF_QOF_AMT_UPDATED,
        MetricTypeIdEnum.LVSD_QOF_AMT_LAST,
        MetricTypeIdEnum.LVSD_QOF_AMT_UPDATED,
        MetricTypeIdEnum.LVSD_QOF_COUNT_LAST,
        MetricTypeIdEnum.LVSD_QOF_COUNT_UPDATED,
        MetricTypeIdEnum.HF_DOMAIN_YR_AMT_LAST,
        MetricTypeIdEnum.HF_DOMAIN_YR_AMT_UPDATED,
        MetricTypeIdEnum.HF_DOMAIN_PERC_AMT_LAST,
        MetricTypeIdEnum.HF_DOMAIN_PERC_AMT_UPDATED
    ]
};

const additionalIncomeMetricPerBaseline = {
    [BaselineEnum.SINCE_SERVICE_STARTED]: [MetricTypeIdEnum.ADDN_INCOME_FIRST],
    [BaselineEnum.SINCE_LAST_REVIEW]: [MetricTypeIdEnum.ADDN_INCOME_LAST]
};

const changeInRegisterPrevalenceMetricTypeIdsPerBaseline = {
    [BaselineEnum.SINCE_SERVICE_STARTED]: [
        MetricTypeIdEnum.HF_REG_FIRST,
        MetricTypeIdEnum.HF_REG_UPDATED,
        MetricTypeIdEnum.LVSD_REG_FIRST,
        MetricTypeIdEnum.LVSD_REG_UPDATED,
        MetricTypeIdEnum.HF_PERCENT_FIRST,
        MetricTypeIdEnum.HF_PERCENT_UPDATED,
        MetricTypeIdEnum.LVSD_PERCENT_FIRST,
        MetricTypeIdEnum.LVSD_PERCENT_UPDATED
    ],
    [BaselineEnum.SINCE_LAST_REVIEW]: [
        MetricTypeIdEnum.HF_REG_LAST,
        MetricTypeIdEnum.HF_REG_UPDATED,
        MetricTypeIdEnum.LVSD_REG_LAST,
        MetricTypeIdEnum.LVSD_REG_UPDATED,
        MetricTypeIdEnum.HF_PERCENT_LAST,
        MetricTypeIdEnum.HF_PERCENT_UPDATED,
        MetricTypeIdEnum.LVSD_PERCENT_LAST,
        MetricTypeIdEnum.LVSD_PERCENT_UPDATED
    ]
};

const changeInHF00xMetricTypeIdsPerBaseline = {
    [BaselineEnum.SINCE_SERVICE_STARTED]: [
        MetricTypeIdEnum.HF003_TRUE_FIRST,
        MetricTypeIdEnum.HF003_TRUE_UPDATED,
        MetricTypeIdEnum.HF003_QOF_FIRST,
        MetricTypeIdEnum.HF003_QOF_UPDATED,
        MetricTypeIdEnum.HF006_TRUE_FIRST,
        MetricTypeIdEnum.HF006_TRUE_UPDATED,
        MetricTypeIdEnum.HF006_QOF_FIRST,
        MetricTypeIdEnum.HF006_QOF_UPDATED,
        MetricTypeIdEnum.HF007_TRUE_FIRST,
        MetricTypeIdEnum.HF007_TRUE_UPDATED,
        MetricTypeIdEnum.HF007_QOF_FIRST,
        MetricTypeIdEnum.HF007_QOF_UPDATED,
        MetricTypeIdEnum.HF008_TRUE_FIRST,
        MetricTypeIdEnum.HF008_TRUE_UPDATED,
        MetricTypeIdEnum.HF008_QOF_FIRST,
        MetricTypeIdEnum.HF008_QOF_UPDATED,
        MetricTypeIdEnum.HF003_POINT_FIRST,
        MetricTypeIdEnum.HF003_POINT_UPDATED,
        MetricTypeIdEnum.HF003_TOTAL_POINT,
        MetricTypeIdEnum.HF006_POINT_FIRST,
        MetricTypeIdEnum.HF006_POINT_UPDATED,
        MetricTypeIdEnum.HF006_TOTAL_POINT,
        MetricTypeIdEnum.HF007_POINT_FIRST,
        MetricTypeIdEnum.HF007_POINT_UPDATED,
        MetricTypeIdEnum.HF007_TOTAL_POINT,
        MetricTypeIdEnum.HF008_POINT_FIRST,
        MetricTypeIdEnum.HF008_POINT_UPDATED,
        MetricTypeIdEnum.HF008_TOTAL_POINT
    ],
    [BaselineEnum.SINCE_LAST_REVIEW]: [
        MetricTypeIdEnum.HF003_TRUE_LAST,
        MetricTypeIdEnum.HF003_TRUE_UPDATED,
        MetricTypeIdEnum.HF003_QOF_LAST,
        MetricTypeIdEnum.HF003_QOF_UPDATED,
        MetricTypeIdEnum.HF006_TRUE_LAST,
        MetricTypeIdEnum.HF006_TRUE_UPDATED,
        MetricTypeIdEnum.HF006_QOF_LAST,
        MetricTypeIdEnum.HF006_QOF_UPDATED,
        MetricTypeIdEnum.HF007_TRUE_LAST,
        MetricTypeIdEnum.HF007_TRUE_UPDATED,
        MetricTypeIdEnum.HF007_QOF_LAST,
        MetricTypeIdEnum.HF007_QOF_UPDATED,
        MetricTypeIdEnum.HF008_TRUE_LAST,
        MetricTypeIdEnum.HF008_TRUE_UPDATED,
        MetricTypeIdEnum.HF008_QOF_LAST,
        MetricTypeIdEnum.HF008_QOF_UPDATED,
        MetricTypeIdEnum.HF003_POINT_LAST,
        MetricTypeIdEnum.HF003_POINT_UPDATED,
        MetricTypeIdEnum.HF003_TOTAL_POINT,
        MetricTypeIdEnum.HF006_POINT_LAST,
        MetricTypeIdEnum.HF006_POINT_UPDATED,
        MetricTypeIdEnum.HF006_TOTAL_POINT,
        MetricTypeIdEnum.HF007_POINT_LAST,
        MetricTypeIdEnum.HF007_POINT_UPDATED,
        MetricTypeIdEnum.HF007_TOTAL_POINT,
        MetricTypeIdEnum.HF008_POINT_LAST,
        MetricTypeIdEnum.HF008_POINT_UPDATED,
        MetricTypeIdEnum.HF008_TOTAL_POINT
    ]
};

const PerformanceSummaryDashboard = () => {
    const baseline = useAppSelector(selectBaselineSelector);
    const practiceData = useAppSelector(selectPracticeSelector);
    
    const patientPrevalenceMetricsData = useMetrics(patientPrevalenceMetricTypeIds);

    const prevalenceAchievementMetricsData = useMetrics(prevalenceAchievementMetricTypeIds);

    const listOfMetricsPerBaselineMemo = useMemo(() =>
            baseline ? listOfMetricTypeIdsPerBaseline[baseline] : [],
        [baseline]);
    const listOfMetricsData  = useMetrics(listOfMetricsPerBaselineMemo);

    const additionalIncomeMetricsPerBaselineMemo = useMemo(() =>
            baseline ? additionalIncomeMetricPerBaseline[baseline] : [],
        [baseline]);
    const additionalIncomeMetricsData  = useMetrics(additionalIncomeMetricsPerBaselineMemo);

    const changeInRegisterPrevalenceMetricsPerBaselineMemo = useMemo(() =>
            baseline ? changeInRegisterPrevalenceMetricTypeIdsPerBaseline[baseline] : [],
        [baseline]);
    const changeInRegisterPrevalenceMetricsData = useMetrics(changeInRegisterPrevalenceMetricsPerBaselineMemo);

    const changeInHF00xMetricsPerBaselineMemo = useMemo(() =>
            baseline ? changeInHF00xMetricTypeIdsPerBaseline[baseline] : [],
        [baseline]);
    const changeInHF00xMetricsData = useMetrics(changeInHF00xMetricsPerBaselineMemo);
    
    return (
        <ModuleContainerMemo>
            <HeartFailureMenuMemo/>
            <WebAnalyticsTrackerMemo/>
            <div className="performance-summary-metrics-container">
                <div className="performance-summary-patients-prevalence-container">
                    <div className="performance-summary-patients-prevalence-values-container">
                        <PatientsPrevalenceMetricsMemo metricData={patientPrevalenceMetricsData}/>
                    </div>
                    <div className="performance-summary-hf-lvsd-prevalance-chart-container">
                        <PrevalenceAchievementMetricsMemo metricsData={prevalenceAchievementMetricsData} practiceData={practiceData} />
                    </div>
                </div>
                <div className="performance-summary-metric-income-container">
                    <div className="performance-summary-metric-list-container">
                        <ListOfMetricsMemo metricsData={listOfMetricsData} />
                    </div>
                    <div className="performance-summary-additional-income-container">
                        <AdditionalIncomeMetricsMemo metricsData={additionalIncomeMetricsData} />
                    </div>
                </div>
            </div>
            <div className="performance-summary-change-charts-container">
                <div className="performance-summary-change-in-hfxxx-metrics-container">
                    <ChangeInHFXXXMetricsMemo metricsData={changeInHF00xMetricsData} />
                </div>
                <div className="performance-summary-change-in-register-prevalence-container">
                    <div className="performance-summary-change-in-register-prevalence-card-container">
                        <ChangeInRegisterPrevalenceMetricsMemo metricsData={changeInRegisterPrevalenceMetricsData} />
                    </div>
                </div>
            </div>
            <div className="hfpef-disclaimer-text">
                HFpEF = Heart failure with preserved on normal ejection fraction
            </div>
        </ModuleContainerMemo>
    );
}

export const PerformanceSummaryDashboardMemo = React.memo(PerformanceSummaryDashboard);