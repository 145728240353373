import './ListOfMetrics.scss'

import {Metric} from "../../../../models/Metric";
import React, {useMemo} from "react";
import {CardLoaderMemo} from "../../../CardLoader/CardLoader";
import {Card, CardBody} from "reactstrap";
import {numericFormatter} from 'react-number-format';
import {MetricValueTypeEnum} from "../../../../models/MetricValueTypeEnum";
import {MetricTypeIdEnum} from "../../../../models/MetricTypeIdEnum";
import {chainGroupBaselineRelatedMetrics} from "../../../../utils/metric";
import {MetricsData} from "../../../../models/MetricsData";

interface ListOfMetricsProps {
    metricsData: MetricsData
}

const ListOfMetrics: React.FunctionComponent<ListOfMetricsProps> = ({metricsData}) => {
    const formatValue = (metric: Metric | undefined) => {
        if (metric?.metricTypeValueType === MetricValueTypeEnum.DECIMAL) {
            return numericFormatter(metric.value, {
                thousandSeparator: ',',
                decimalSeparator: '.',
                prefix: '£',
                decimalScale: 2,
                fixedDecimalScale: true
            });
        }
        if (metric?.metricTypeValueType === MetricValueTypeEnum.INTEGER) {
            return numericFormatter(metric.value, {
                thousandSeparator: ','
            });
        }
    };
    
    const getMetricDescription = (metricTypeId: MetricTypeIdEnum) => {
        const basedOnQofYearAchievement = 'based on previous QOF year achievement';
        const basedOnPercentageAchievement = 'based on 100% achievement';
        const metricByDescription = {
            [MetricTypeIdEnum.HF_DOMAIN_YR_AMT_FIRST]: basedOnQofYearAchievement,
            [MetricTypeIdEnum.HF_DOMAIN_YR_AMT_LAST]: basedOnQofYearAchievement,
            [MetricTypeIdEnum.HF_DOMAIN_YR_AMT_UPDATED]: basedOnQofYearAchievement,
            [MetricTypeIdEnum.HF_DOMAIN_PERC_AMT_FIRST]: basedOnPercentageAchievement,
            [MetricTypeIdEnum.HF_DOMAIN_PERC_AMT_LAST]: basedOnPercentageAchievement,
            [MetricTypeIdEnum.HF_DOMAIN_PERC_AMT_UPDATED]: basedOnPercentageAchievement
        }
        
        const description = metricByDescription[metricTypeId];        
        if (description)
        return (
            <div className="sub-row-item">
                {description}
            </div>
        )
    };
    
    const metricRows = useMemo(() => {
        if (metricsData.metrics) {         
            return chainGroupBaselineRelatedMetrics(metricsData.metrics)
                .map((groupedMetrics, idx) => {
                    const metricName = groupedMetrics[0].metricTypeName;
                    const metricTypeId = groupedMetrics[0].metricTypeId;
                    const baselineMetric = groupedMetrics
                        .find(m => m.metricTypeId.endsWith("FIRST") || m.metricTypeId.endsWith("LAST"));
                    const updatedValue = groupedMetrics
                        .find(m => m.metricTypeId.endsWith("UPDATED"));
                    return (
                        <div className="table-row" key={idx}>
                            <div className="row-item row-item-name">
                                {metricName}
                                {getMetricDescription(metricTypeId)}
                            </div>
                            <div className="row-item row-item-value">
                                {formatValue(baselineMetric)}
                            </div>
                            <div className="row-item row-item-value">
                                {formatValue(updatedValue)}
                            </div>
                        </div>
                    )
                }).value();
        }
    }, [metricsData.metrics]);

    return (
        <div className="list-of-metrics-container">
            {metricsData.metrics && !metricsData.isLoading ? (
                <Card className="list-of-metrics-card">
                    <CardBody className="list-of-metrics-card-body">
                        <div className="table-container">
                            <div className="table-row heading">
                                <div className="row-item row-item-name">Metric</div>
                                <div className="row-item row-item-value">Baseline</div>
                                <div className="row-item row-item-value">Updated</div>
                            </div>
                            {metricRows}
                        </div>
                    </CardBody>
                </Card>
            ) : (
                <CardLoaderMemo cardClassName='list-of-metrics-loader'/>
            )}
        </div>
    );
};

export const ListOfMetricsMemo = React.memo(ListOfMetrics);