export enum MODULE_URLS {
    EMPTY_MODULE = "/",
    SIGNIN_OIDC = "/signin-oidc",
    HEART_FAILURE_DASHBOARD = "/HF",
    NO_PRACTICES_AVAILABLE = "/HF/NoPracticesAvailable",
    PERFORMANCE_SUMMARY_DASHBOARD = "/HF/PerformanceSummary",
    OPPORTUNITY_DASHBOARD = "/HF/Opportunity",
    PATIENTS_DASHBOARD = "/HF/Patients",
    KPI_METRICS_DASHBOARD = "/HF/KPIMetrics"
}
