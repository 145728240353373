import React, { useMemo } from 'react';
import { Button } from "@e360/react-core";
import './GoHomeContactSupportButtons.scss';
import { getBaseUrl } from '../../../utils/api';

const GoHomeContactSupportButtons = () => {
    const appBaseUrl = useMemo(
        () => getBaseUrl(),
        []);
    
    const supportNumber = window?.appSettings?.serviceSupportPhoneNumber;
    const contactSupportLabel = supportNumber
        ? `Contact Support: ${window.appSettings.serviceSupportPhoneNumber}`
        : `Contact Support`;

    return(
        <div className='go-home-contact-support-buttons-container'>
            <a
                href={appBaseUrl}>
                <Button 
                    variant='secondary'>
                        Go Home
                </Button>
            </a>
            <a href={`tel:${supportNumber}`}>
                <Button
                    variant='secondary'> 
                    {contactSupportLabel}
                </Button>
            </a>
        </div>
    );
}

export const GoHomeContactSupportButtonsMemo = React.memo(GoHomeContactSupportButtons)