import { BaselineSelect } from "../../models/BaselineSelect";
import {fetchDelete, fetchGet, fetchPost} from "../../utils/api";
import {UserColumnsSettingsUpdateModel} from "../../models/UserColumnsSettingsUpdateModel";
import {UserColumnSettingsViewModel} from "../../models/UserColumnSettingsViewModel";

export const fetchBaselineOptions = () => fetchGet<BaselineSelect[]>(
    `/api/HF/userpreferences/baseline`);

export const fetchUserColumnsSettings = () => fetchGet<UserColumnSettingsViewModel[]>(`/api/HF/userpreferences/patients/columns-settings`);

export const updateUserColumnsSettings = (
    userColumnsSettings: UserColumnsSettingsUpdateModel
) => fetchPost<UserColumnSettingsViewModel[]>(`/api/HF/userpreferences/patients/columns-settings`, userColumnsSettings);

export const deleteUserColumnsSettings = () => fetchDelete(`/api/HF/userpreferences/patients/columns-settings`);