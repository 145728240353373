import './App.scss';

import { RouteObject } from "react-router";
import { MODULE_URLS } from "./app/module-urls";
import { KPIMetricsDashboardMemo } from "./features/HeartFailure/KPIMetricsDashboard/KPIMetricsDashboard";
import { OpportunityDashboardMemo } from "./features/HeartFailure/OpportunityDashboard/OpportunityDashboard";
import { PatientsDashboardMemo } from "./features/HeartFailure/PatientsDashboard/PatientsDashboard";
import { PerformanceSummaryDashboardMemo } from "./features/HeartFailure/PerformanceSummaryDashboard/PerformanceSummaryDashboard";
import { RedirectPageMemo } from "./features/RedirectPage/RedirectPage";
import { GlobalErrorBoundaryMemo } from "./features/GlobalErrorBoundary/GlobalErrorBoundary";
import { HeartFailureLayoutMemo } from './features/HeartFailure/HeartFailureLayout/HeartFailureLayout';
import { PageNotFoundLayoutMemo } from './features/PageNotFoundLayout/PageNotFoundLayout';
import {
    NoPracticesAvailableViewMemo
} from "./features/NoPracticesAvailableView/NoPracticesAvailableView";
import { GlobalLayoutMemo } from './features/HeartFailure/GlobalLayout/GlobalLayout';

const NotFoundStarRoute: RouteObject = {
        path: "*",
        element: <PageNotFoundLayoutMemo/>
    };

const AppRoutes: RouteObject[] = [
    {
        element: <GlobalLayoutMemo/>,
        ErrorBoundary: GlobalErrorBoundaryMemo,
        children: [
            {
                path: MODULE_URLS.EMPTY_MODULE,
                element: <RedirectPageMemo />,
            },
            {
                path: MODULE_URLS.SIGNIN_OIDC,
                element: <RedirectPageMemo />
            },
            {
                path: MODULE_URLS.HEART_FAILURE_DASHBOARD,
                element: <HeartFailureLayoutMemo/>,
                children: [
                    {
                        path: MODULE_URLS.NO_PRACTICES_AVAILABLE,
                        element: <NoPracticesAvailableViewMemo />
                    },                    
                    {
                        path: MODULE_URLS.PERFORMANCE_SUMMARY_DASHBOARD,
                        element: <PerformanceSummaryDashboardMemo />
                    },
                    {
                        path: MODULE_URLS.OPPORTUNITY_DASHBOARD,
                        element: <OpportunityDashboardMemo />,
                    },
                    {
                        path: MODULE_URLS.PATIENTS_DASHBOARD,
                        element: <PatientsDashboardMemo />
                    },
                    {
                        path: MODULE_URLS.KPI_METRICS_DASHBOARD,
                        element: <KPIMetricsDashboardMemo />
                    },
                    NotFoundStarRoute
                ]
            },
            NotFoundStarRoute
        ],
    }
];

export default AppRoutes;
