import './HFXXXTableCell.scss';
import React, {useMemo} from "react";
import {Tooltip} from "primereact/tooltip";
import {ColumnFilterElementTemplateOptions} from "primereact/column";
import {Dropdown, DropdownProps} from "primereact/dropdown";
import {HEADER_FILTERS_DISABLED_IN_EDIT_MODE_MESSAGE} from "../patients-list-constants";

const greenCircleCheckStatusIcon =
    <i className="fa fa-fw fa-2x fa-check-circle text-success" style={{fontSize: '28px'}}></i>;
const greenCircleRedFlagStatusIcon =
    <span className="fa-stack text-success">
        <i className="fa fa-circle-o fa-stack-2x"></i>
        <i className="fa fa-flag fa-stack-1x text-danger"></i>
    </span>;
const greenCheckStatusIcon =
    <i className="fa fa-fw fa-2x fa-check text-success"></i>;
const redCrossStatusIcon =
    <i className="fa fa-fw fa-2x fa-close text-danger"></i>;
const redCircleCrossedOutStatusIcon =
    <i className="fa fa-fw fa-2x fa-ban text-danger" style={{fontSize: '28px'}}></i>;

interface HFXXXTableCellProps {
    cellValue: number
}

interface HFXXXCellContent {
    cellContent: React.ReactElement,
    description?: string
}

const HFXXXTableCell: React.FunctionComponent<HFXXXTableCellProps> = ({cellValue}) => {    
    const cellContent: HFXXXCellContent = useMemo(() => {
        switch (cellValue) {
            case 1: return {
                cellContent: redCrossStatusIcon,
                description: 'Indicator not achieved but currently qualifies for indicator'
            }
            case 1.5: return {
                cellContent: greenCircleRedFlagStatusIcon,
                description: 'Indicator not achieved, but has appropriate exception code'
            }
            case 2: return {
                cellContent: redCircleCrossedOutStatusIcon,
                description: 'Indicator not achieved and does not currently qualify for indicator'
            }
            case 3: return {
                cellContent: greenCheckStatusIcon,
                description: 'Indicator achieved but does not currently qualify for indicator'
            }
            case 4: return {
                cellContent: greenCircleCheckStatusIcon,
                description: 'Indicator achieved and currently qualifies for indicator'
            }
            default: return {
                cellContent: <span style={{fontWeight: 'bold'}}>-</span>
            }
        }
    }, [cellValue]);
    
    return <>
        <Tooltip target=".hfxxx-icon-wrapper" disabled={!(cellContent.description)} />
        <div 
            className="hfxxx-icon-wrapper"
            data-pr-tooltip={cellContent.description}
            data-pr-position="mouse"
        >
            {cellContent.cellContent}
        </div>
    </> ;
}

export const HFXXXTableCellMemo = React.memo(HFXXXTableCell);

export const useHFXXXFilteringProps = (headerFiltersEnabled: boolean) => {
    const selectableOptions = [1, 1.5, 2, 3, 4];
    return {
        filter: true,
        showFilterMenu: false,
        filterElement: (options: ColumnFilterElementTemplateOptions) => {
            return (
                <Dropdown
                    disabled={!headerFiltersEnabled}
                    tooltip={headerFiltersEnabled ? undefined : HEADER_FILTERS_DISABLED_IN_EDIT_MODE_MESSAGE}
                    tooltipOptions={{showOnDisabled: true}}
                    value={options.value}
                    options={selectableOptions}
                    onChange={(e) => options.filterApplyCallback(e.value)}
                    itemTemplate={(hfxxxValue: number) => <HFXXXTableCellMemo cellValue={hfxxxValue} />}
                    valueTemplate={(hfxxxValue: number, props: DropdownProps) => {
                        if(!hfxxxValue) {
                            return <span>{props.placeholder}</span>;
                        }
                        return <HFXXXTableCellMemo cellValue={hfxxxValue} />
                    }}
                    placeholder={options.field.toUpperCase()}
                    showClear={true}
                />
            );
        }
    }
}