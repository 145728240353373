import React, {useCallback, useEffect, useMemo, useState} from "react"
//@ts-ignore
import {ActionBar} from '@e360/react-action-bar';
//@ts-ignore
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, TabStrip, TabStripItem} from '@e360/react-core';
import './HeartFailureMenu.scss';
import {MODULE_URLS} from "../../../app/module-urls";
import {useLocation, useNavigate} from "react-router-dom";
import {TabInfo} from "../../../models/TabInfo";
import {selectHeartFailureTabInfo} from "../../../app/selectors/heartFailureMenuSelectors";
import {useInit} from "../../../utils/custom-react-hooks";
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../../app/hooks";
import {setTabInfo} from "../../../app/slices/heartFailureMenuSlice";
import {setMarketId} from "../../../app/slices/marketSlice";
import {MARKET_ID_ENUM} from "../../../app/market-id-enum";
import {BaselineSelect} from "../../../models/BaselineSelect";
import {setBaseline} from "../../../app/slices/userPreferencesSlice";
import {InlineLoaderMemo} from "../../InlineLoader/InlineLoader";
import {BaselineEnum} from "../../../models/BaselineEnum";

interface HeartFailureMenuProps {
    disableBaselineDropdown?: boolean
}

const rdf46437TemporaryBaselineSelect: BaselineSelect = {
    name: 'Since last review',
    value: BaselineEnum.SINCE_LAST_REVIEW
};

// https://iqviatech.atlassian.net/browse/RDF-46437 - to be reverted ASAP
//const HeartFailureMenu: React.FunctionComponent<HeartFailureMenuProps> = ({disableBaselineDropdown = false}) => {
const HeartFailureMenu: React.FunctionComponent<HeartFailureMenuProps> = ({disableBaselineDropdown = true}) => {
    const dispatch = useAppDispatch();
    const [currentTab, setCurrentTab] = useState<number|undefined>(undefined);
    const currentTabInfo = useSelector(
        selectHeartFailureTabInfo);
    const [baselines, setBaselines] = useState<BaselineSelect[]|undefined>(undefined);
    const [selectedBaseline, setSelectedBaseline] = useState<BaselineSelect | null>(null);

    useEffect(
        () => {
            if(currentTabInfo) {
                setCurrentTab(
                    currentTabInfo.id)
            };
        }, [currentTabInfo, setCurrentTab])
    
    const navigate = useNavigate();
    const location = useLocation();
    const tabs: TabInfo[] = useMemo(
        () => [
            {
                id: 0,
                name: "Performance Summary",
                url: MODULE_URLS.PERFORMANCE_SUMMARY_DASHBOARD,
            },
            {
                id: 1,
                name: "Opportunity",
                url: MODULE_URLS.OPPORTUNITY_DASHBOARD,
            },
            {
                id: 2,
                name: "Patients",
                url: MODULE_URLS.PATIENTS_DASHBOARD,
            },
            {
                id: 3,
                name: "KPI Metrics",
                url: MODULE_URLS.KPI_METRICS_DASHBOARD,
            }
        ], []);
    
    const fetchBaselinesCallback = useCallback(() => {
        // fetchBaselineOptions().then(
        //     (baselineOptions: BaselineSelect[]) => {
        //         setBaselines(baselineOptions);
        //         if(baselineOptions && baselineOptions.length > 0) {
        //             setSelectedBaseline(
        //                 baselineOptions[0]);
        //         }
        //     });
        // https://iqviatech.atlassian.net/browse/RDF-46437 - to be reverted ASAP
        setBaselines([rdf46437TemporaryBaselineSelect]);
    }, []);

    useInit(()=>{
        if (location) {
            const matchingTab = tabs.find((tabItem: TabInfo) => tabItem.url === location.pathname);
            if (matchingTab) {
                dispatch(setTabInfo(matchingTab));
            }
        }

        dispatch(
            setMarketId(
                MARKET_ID_ENUM.HEART_FAILURE));
        
        fetchBaselinesCallback();
    })

    useEffect(
        () => {
            // if (selectedBaseline) {
            //     dispatch(
            //         setBaseline(
            //             selectedBaseline.value))
            // }
            // https://iqviatech.atlassian.net/browse/RDF-46437 - to be reverted ASAP
            dispatch(
                setBaseline(rdf46437TemporaryBaselineSelect.value))            
        },
        [selectedBaseline, dispatch]);
    
    const onTabSelectCallback = useCallback((tabInfo: TabInfo) => {
        setCurrentTab(tabInfo.id);
        navigate(tabInfo.url);
        dispatch(setTabInfo(tabInfo));
    }, [navigate, dispatch]);

    const baselineDropdown = useMemo(
        () => {
            if (disableBaselineDropdown) {
                return <></>;
            }
            const baselineDropdownItems = baselines?.map(
                (baseline: BaselineSelect) => (
                    <DropdownItem key={'baseline-dropdown-item-' + baseline.value}
                        onClick={() => setSelectedBaseline(baseline)}>
                        {baseline.name}
                    </DropdownItem>
                )
            );
            return (
                <div
                    key={'baseline-dropdown'}
                    className="baseline-dropdown-container">
                    <span 
                        className="baseline-dropdown-label">
                        Baseline:
                    </span>
                    {
                        baselineDropdownItems 
                            ? (
                                <Dropdown 
                                    className="baseline-dropdown">
                                    <DropdownToggle 
                                        variant="link"
                                        inline>
                                        {selectedBaseline?.name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {baselineDropdownItems}
                                    </DropdownMenu>
                                </Dropdown>
                            ) : (
                                <InlineLoaderMemo
                                    size="sm"
                                    className="baseline-dropdown-loader"/>
                            )
                    }
                </div>);
        }, [baselines, selectedBaseline, disableBaselineDropdown]);
    
    const tabStripChildren = useMemo(
        () => {
            const moduleTabs = tabs.map((tabInfo: TabInfo) => (
                <TabStripItem 
                    key={'heart-failure-tab-item-' + tabInfo.id}
                    active={currentTab === tabInfo.id}
                    onClick={() => onTabSelectCallback(tabInfo)}
                    className="heart-failure-tab-item">
                    {tabInfo.name}
                </TabStripItem>
            ));
            
            return [
                ...moduleTabs,
                baselineDropdown
            ];
        },
        [tabs, currentTab, baselineDropdown, onTabSelectCallback]);
    
    return (
        <div className="heart-failure-menu-wrapper">
            <ActionBar
                className="action-bar"
                heading={"Heart Failure"}
                fluid>
                <TabStrip className="tab-strip-menu">
                    {tabStripChildren}
                </TabStrip>
            </ActionBar>
        </div>  
    );
}

export const HeartFailureMenuMemo = React.memo(HeartFailureMenu);