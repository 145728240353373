import { HttpStatusCode } from "axios";
import { HttpServiceError } from "./HttpServiceError";

export class HttpServiceTimeoutError extends HttpServiceError {
    aborted: boolean;

    constructor(
        statusCode: HttpStatusCode,
        error: any,
        aborted: boolean) {
            super(
                statusCode,
                error);
            this.aborted = aborted;
    }
}