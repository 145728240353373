import {hasAuthParams, useAuth} from "react-oidc-context";
import {env} from "../env";
import {useEffect} from "react";
import {useAppDispatch} from "../app/hooks";
import {setIsAuthenticated, setLoggedUser, setNonSilentAuthProcessing} from "../app/slices/authSlice";

export const useAppAuthentication = () => {
    const auth = useAuth();
    const dispatch = useAppDispatch();    
    const devAuthDisabled = env["NODE_ENV"] === "development" && window.appSettings.auth.disableAuthForDev;
    
    useEffect(() => {
        if (!devAuthDisabled && !hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect();
        }
    }, [auth, devAuthDisabled]);
    
    useEffect(() => {
        if (!devAuthDisabled) {
            return auth.events.addAccessTokenExpiring(() => {
                window.console.log("Access Token Expiring, Renewing...");
                auth.signinSilent()
                    .then(usr => {
                        window.console.log("Access Token has been renewed silently...");
                    }).catch(err => {
                    window.console.error(`Unable to renew Access Token silently due to an error '${err.message}' - redirecting to manual form...`);
                    auth.signinRedirect();
                });
            });
        }
    }, [auth, devAuthDisabled]);

    useEffect(() => {
        const nonSilentAuthProcessing = auth.activeNavigator && auth.activeNavigator !== "signinSilent";
        dispatch(setNonSilentAuthProcessing(nonSilentAuthProcessing ?? false));
        
        if (devAuthDisabled) {
            dispatch(setIsAuthenticated(true));
            dispatch(setLoggedUser({
                name: 'Local User',
                email: 'local@user'
            }));
            return;
        }

        dispatch(setIsAuthenticated(auth.isAuthenticated));
        const userProfile = auth.user?.profile;
        dispatch(setLoggedUser({
            name: userProfile ? `${userProfile?.given_name} ${userProfile?.family_name}` : 'Unknown user',
            email: userProfile?.email,
        }));
    }, [auth, devAuthDisabled, dispatch]);
}