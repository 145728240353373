//@ts-ignore
import { TextWithLabel } from '@e360/react-core';

import './PracticeHelpLink.scss'
import { PracticeInfoIcon } from '../PracticeInfoIcon/PracticeInfoIcon';

interface PracticeHelpLinkProps {
    iconSource: string;
    label: string;
    link: string;
}

export const PracticeHelpLink: React.FunctionComponent<
PracticeHelpLinkProps> = ({iconSource, label, link}) => {

    return (
        <a href={link} target="_blank" rel="noreferrer">
            <div className="practice-help-link-wrapper">
                <div className="practice-info-link-icon-wrapper">
                    <PracticeInfoIcon iconSource={iconSource}/>
                </div>
                <div className="practice-help-link-label-wrapper">
                    <TextWithLabel
                        label={label}
                        strongLabel={false}
                        labelClassName="practice-help-link-label">
                    </TextWithLabel>
                </div>
            </div>
        </a>
    );
}