//@ts-ignore
import { TextWithLabel } from '@e360/react-core';

import './PracticeInfoLabelValueProps.scss';

interface PracticeInfoLabelValueProps {
    label?: string,
    value: string
}

export const PracticeInfoLabelValue: React.FunctionComponent<
    PracticeInfoLabelValueProps> = ({label, value}) => {
    return (
        <TextWithLabel 
            label={label ?? ""}
            strongLabel={false}
            labelClassName="practice-info-label"
            valueClassName="practice-info-value">
            {value}
        </TextWithLabel>
    );
}