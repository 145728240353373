import React from "react";
import { ReactSVG } from "react-svg";

interface PatientsPrevalenceMetricIconProps {
    iconSource: string;
    className: string;
}

const PatientsPrevalenceMetricIcon: React.FunctionComponent<
PatientsPrevalenceMetricIconProps> = ({iconSource, className}) => {
    return (<ReactSVG
        className={className}
        src={iconSource}
        afterInjection={(svg) => {
            svg.setAttribute('style', 'width: 30px')
            svg.setAttribute('style', 'height: 30px')
        }}/>
    );
};

export const PatientsPrevalenceMetricIconMemo = React.memo(PatientsPrevalenceMetricIcon);