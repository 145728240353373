import './PatientsPrevalenceMetrics.scss'
import React, { useMemo } from 'react';
import { PatientsPrevalenceMetricCardMemo } from './PatientsPrevalenceMetricCard/PatientsPrevalenceMetricCard';
import { getIconUrl } from '../../../../utils/api';
import { ICON_NAME } from '../../../../utils/icon-name';
import { Metric } from '../../../../models/Metric';
import { formatMetricValue } from '../../../../utils/metric';
import { CardLoaderMemo } from '../../../CardLoader/CardLoader';
import { MetricTypeIdEnum } from '../../../../models/MetricTypeIdEnum';
import {MetricsData} from "../../../../models/MetricsData";

interface PatientsPrevalenceMetricsProps {
    metricData: MetricsData
}

const PatientsPrevalenceMetrics: React.FunctionComponent<PatientsPrevalenceMetricsProps> = ({metricData}) => {
    const metricCards = useMemo(
        () => {
            if(metricData.metrics) {

                return metricData!.metrics!.map((metric: Metric, index: number) => {
                    const perMetricSetup = {
                        [MetricTypeIdEnum.HF_REG_TOTAL]: { unit: null, icon: ICON_NAME.PEOPLE_PATIENT_GROUP },
                        [MetricTypeIdEnum.LVSD_REG_TOTAL]: { unit: null, icon: ICON_NAME.PEOPLE_PATIENT_GROUP },
                        [MetricTypeIdEnum.HF_PERCENT]: { unit: '%', icon: ICON_NAME.HEALTHCARE_HEART_VASCULAR_SYSTEM },
                        [MetricTypeIdEnum.LVSD_PERCENT]: { unit: '%', icon: ICON_NAME.HEALTHCARE_HEART_VASCULAR_SYSTEM }
                    };
                    
                    const cardClassName = "patient-metric-card" + (index > 0 
                        ? ' next-card'
                        : '');

                    return (
                        <PatientsPrevalenceMetricCardMemo
                            key={'patient-metric-card-' + metric.metricTypeId}
                            cardClassName={cardClassName}
                            cardTitle={metric.metricTypeName}
                            cardValue={formatMetricValue(metric.value, metric.metricTypeValueType, perMetricSetup[metric.metricTypeId].unit)}
                            iconSource={getIconUrl(perMetricSetup[metric.metricTypeId].icon)}/>
                    );
                });
            };
        }, [metricData]);

    return (
        <div className="patients-prevalence-cards-container">
            {metricData.metrics && !metricData.isLoading
                ? metricCards
                : (<CardLoaderMemo 
                    cardClassName='patients-prevalence-cards-loader'/>)}
        </div>
    );
};

export const PatientsPrevalenceMetricsMemo = React.memo(PatientsPrevalenceMetrics);