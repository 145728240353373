//@ts-ignore
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DataTableFilterMeta, DataTableSortMeta} from "primereact/datatable";
import {nameof} from "../../utils/nameof";
import {PatientEntry} from "../../models/PatientEntry";
import {FilterMatchMode} from "primereact/api";
import {PaginationCriteria} from "../../models/PaginationCriteria";

interface Filters {
    quickFilterKey: string | null,
    headerFilters: DataTableFilterMeta
}
interface Sorting {
    sortedColumns: DataTableSortMeta[] | null | undefined
}
interface Pagination {
    paginationCriteria: PaginationCriteria,
    totalRecordsCount: number,
    firstPage: number,
    rowsNumber: number,
}

export interface PatientsListState {
    filters: Filters,
    sorting: Sorting,
    pagination: Pagination,
}

const defaultFilterMatchMode = FilterMatchMode.EQUALS;

const initialState: PatientsListState = {
    filters: {
        quickFilterKey: null,
        headerFilters: {
            [nameof<PatientEntry>('lvsdStatus')]: { value: undefined, matchMode: defaultFilterMatchMode },
            [nameof<PatientEntry>('decision')]: { value: undefined, matchMode: defaultFilterMatchMode },
            [nameof<PatientEntry>('hf001')]: { value: undefined, matchMode: defaultFilterMatchMode },
            [nameof<PatientEntry>('hf003')]: { value: undefined, matchMode: defaultFilterMatchMode },
            [nameof<PatientEntry>('hf006')]: { value: undefined, matchMode: defaultFilterMatchMode },
            [nameof<PatientEntry>('hf007')]: { value: undefined, matchMode: defaultFilterMatchMode },
            [nameof<PatientEntry>('hf008')]: { value: undefined, matchMode: defaultFilterMatchMode }
        }
    },
    sorting: {
        sortedColumns: [
            { field: nameof<PatientEntry>("name"), order: 1 },
            { field: nameof<PatientEntry>("patientId"), order: 1 }
        ]
    },
    pagination: {
        paginationCriteria: {pageNumber: 1, pageSize: 25},
        totalRecordsCount: 0,
        firstPage: 1,
        rowsNumber: 25,
    }
};

export const patientsListSlice = createSlice({
    name: 'patientsList',
    initialState,
    reducers: {
        setQuickFilterKey: (state: PatientsListState, action: PayloadAction<string | null>) => {
            state.filters.quickFilterKey = action.payload;
        },
        setHeaderFilters: (state: PatientsListState, action: PayloadAction<DataTableFilterMeta>) => {
            state.filters.headerFilters = action.payload;
        },
        setSortedColumns: (state: PatientsListState, action: PayloadAction<DataTableSortMeta[] | null | undefined>) => {
            state.sorting.sortedColumns = action.payload;
        },
        setPaginationCriteria: (state: PatientsListState, action: PayloadAction<PaginationCriteria>) => {
            state.pagination.paginationCriteria = action.payload;
        },
        setTotalRecordsCount: (state: PatientsListState, action: PayloadAction<number>) => {
            state.pagination.totalRecordsCount = action.payload;
        },
        setFirstPage: (state: PatientsListState, action: PayloadAction<number>) => {
            state.pagination.firstPage = action.payload;
        },
        setRowsNumber: (state: PatientsListState, action: PayloadAction<number>) => {
            state.pagination.rowsNumber = action.payload;
        },
        goToPatientsListFirstPage: (state: PatientsListState) => {
            const pageNumber = 1;
            state.pagination.paginationCriteria = {
                ...state.pagination.paginationCriteria, 
                pageNumber: pageNumber
            };
            state.pagination.firstPage = pageNumber;
        },
    },
    extraReducers: (builder: any) => {
    },
});

export const {
    setQuickFilterKey,
    setHeaderFilters,
    setSortedColumns,
    setPaginationCriteria,
    setTotalRecordsCount,
    setFirstPage,
    setRowsNumber,
    goToPatientsListFirstPage,
} = patientsListSlice.actions;

export default patientsListSlice.reducer;