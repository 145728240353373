import React from 'react'
import './PatientsDashboard.scss';
import { HeartFailureMenuMemo } from '../HeartFailureMenu/HeartFailureMenu';
import {PatientsListMemo} from "./PatientsList/PatientsList";
import {PatientsStatisticsMemo} from "./PatientsStatistics/PatientsStatistics";
import {WebAnalyticsTrackerMemo} from "../../WebAnalyticsTracker/WebAnalyticsTracker";
import { ModuleContainerMemo } from '../../ModuleContainer/ModuleContainer';
import {MetricTypeIdEnum} from "../../../models/MetricTypeIdEnum";
import {useMetrics} from "../../../utils/useMetrics";

const patientsStatisticsMetricTypeIds = [
    MetricTypeIdEnum.CODEMED_CONFIRMED,
    MetricTypeIdEnum.CODEMED_QUERY,
    MetricTypeIdEnum.CODEMED_EXCLUDED,
    MetricTypeIdEnum.CODEMED_NOTSCREEN,
    MetricTypeIdEnum.CODE_CONFIRMED,
    MetricTypeIdEnum.CODE_QUERY,
    MetricTypeIdEnum.CODE_EXCLUDED,
    MetricTypeIdEnum.CODE_NOTSCREEN,
    MetricTypeIdEnum.MED_CONFIRMED,
    MetricTypeIdEnum.MED_QUERY,
    MetricTypeIdEnum.MED_EXCLUDED,
    MetricTypeIdEnum.MED_NOTSCREEN,
    MetricTypeIdEnum.ACEBB_CONFIRMED,
    MetricTypeIdEnum.ACEBB_QUERY,
    MetricTypeIdEnum.ACEBB_EXCLUDED,
    MetricTypeIdEnum.ACEBB_NOTSCREEN,
    MetricTypeIdEnum.HO_CONFIRMED,
    MetricTypeIdEnum.HO_QUERY,
    MetricTypeIdEnum.HO_EXCLUDED,
    MetricTypeIdEnum.HO_NOTSCREEN,
    MetricTypeIdEnum.OTHER_CONFIRMED,
    MetricTypeIdEnum.OTHER_QUERY,
    MetricTypeIdEnum.OTHER_EXCLUDED,
    MetricTypeIdEnum.OTHER_NOTSCREEN,
    MetricTypeIdEnum.LVSD_REG_LAST,
    MetricTypeIdEnum.LVSD_REG_UPDATED,
    MetricTypeIdEnum.ADDN_INCOME_LAST,
    MetricTypeIdEnum.LVSD_REG_TOTAL,
    MetricTypeIdEnum.SCREEN_LVSD_CONFIRMED,
    MetricTypeIdEnum.SCREEN_NOT_SCREEN_PRIORITY,
    MetricTypeIdEnum.SCREEN_NOT_SCREEN_OTHER,
    MetricTypeIdEnum.SCREEN_QUERY,
    MetricTypeIdEnum.SCREEN_HFPEF
];

const PatientsDashboard = () => {
    const patientsStatisticsMetrics = useMetrics(patientsStatisticsMetricTypeIds);
    
    return (
        <ModuleContainerMemo>
            <HeartFailureMenuMemo
                disableBaselineDropdown={true}
            />
            <WebAnalyticsTrackerMemo/>
            <div className="hf-patients-container-wrapper">
                <div className="hf-patients-per-filter-metrics-summary-container">
                    <PatientsStatisticsMemo metricsData={patientsStatisticsMetrics}/>
                </div>
                <div className="hf-patients-list-container">
                    <PatientsListMemo onDataChange={patientsStatisticsMetrics.loadMetricsSilently}/> 
                </div>
            </div>
        </ModuleContainerMemo>
    );
}

export const PatientsDashboardMemo = React.memo(PatientsDashboard);

