import {useCallback, useEffect} from "react";

export const useCustomEvent = (eventName: string, listener: any) => {
    const subscribeToEvent = useCallback(() => {
        document.addEventListener(eventName, listener);
    }, [eventName, listener]);

    const unsubscribeFromEvent = useCallback(() => {
        document.removeEventListener(eventName, listener);
    }, [eventName, listener]);

    const publishEvent = useCallback((data?: any) => {
        const event = new CustomEvent(eventName, { detail: data });
        document.dispatchEvent(event);
    }, [eventName]);
    
    useEffect(() => {
        subscribeToEvent();
        return () => {
            unsubscribeFromEvent();
        }
    }, [subscribeToEvent, unsubscribeFromEvent]);
    
    return {
        publishEvent
    }
}