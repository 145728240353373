import { fetchPost } from "../../utils/api";
import {PagedPatientsList} from "../../models/PagedPatientsList";
import {PatientsListCriteria} from "../../models/PatientsListCriteria";
import {PatientEntry} from "../../models/PatientEntry";
import {PatientEntryUpdateModel} from "../../models/PatientEntryUpdateModel";
import {PatientsListExportFilterCriteria} from "../../models/PatientsListExportFilterCriteria";
import {PatientEntriesBulkUpdateModel} from "../../models/PatientEntriesBulkUpdateModel";

export const fetchPracticeMarketPagedPatientsList = (
    practiceId:string,
    criteria: PatientsListCriteria
) => fetchPost<PagedPatientsList>(`/api/HF/patients/practice/${practiceId}`, criteria);

export const updatePracticeMarketPatientData = (
    practiceId:string,
    marketId: string,
    updatedPatientEntry: PatientEntryUpdateModel
) => fetchPost<PatientEntry>(`/api/HF/patients/practice/${practiceId}/update`, updatedPatientEntry);

export const bulkUpdatePracticePatientData = (
    practiceId:string,
    updatePayload: PatientEntriesBulkUpdateModel
) => fetchPost(`/api/HF/patients/practice/${practiceId}/bulk-update`, updatePayload);

export const exportToExcelPracticePatientData = (
    practiceId:string,
    filterCriteria: PatientsListExportFilterCriteria
) => fetchPost(`/api/HF/patients/practice/${practiceId}/export`, filterCriteria, { responseType: 'blob' });
