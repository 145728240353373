import {Slice, TopCellTemplate} from "../TopCellTemplate/TopCellTemplate";
import {MetricTypeIdEnum} from "../../../../models/MetricTypeIdEnum";
import {Blue, Fuchsia, LightGray, Orange} from "../../../../utils/palette-hf";

const groupSizeMetrics = [
    MetricTypeIdEnum.ACEBB_CONFIRMED,
    MetricTypeIdEnum.ACEBB_QUERY,
    MetricTypeIdEnum.ACEBB_EXCLUDED,
    MetricTypeIdEnum.ACEBB_NOTSCREEN,
    MetricTypeIdEnum.HO_CONFIRMED,
    MetricTypeIdEnum.HO_QUERY,
    MetricTypeIdEnum.HO_EXCLUDED,
    MetricTypeIdEnum.HO_NOTSCREEN,
    MetricTypeIdEnum.OTHER_CONFIRMED,
    MetricTypeIdEnum.OTHER_QUERY,
    MetricTypeIdEnum.OTHER_EXCLUDED,
    MetricTypeIdEnum.OTHER_NOTSCREEN
];

const completedSizeMetrics = [
    MetricTypeIdEnum.ACEBB_CONFIRMED,
    MetricTypeIdEnum.ACEBB_QUERY,
    MetricTypeIdEnum.ACEBB_EXCLUDED,
    MetricTypeIdEnum.HO_CONFIRMED,
    MetricTypeIdEnum.HO_QUERY,
    MetricTypeIdEnum.HO_EXCLUDED,
    MetricTypeIdEnum.OTHER_CONFIRMED,
    MetricTypeIdEnum.OTHER_QUERY,
    MetricTypeIdEnum.OTHER_EXCLUDED
]

const slices: Slice[] = [
    {
        label: "Not screened",
        metricTypes: [
            MetricTypeIdEnum.ACEBB_NOTSCREEN,
            MetricTypeIdEnum.HO_NOTSCREEN,
            MetricTypeIdEnum.OTHER_NOTSCREEN
        ],
        color: LightGray
    },
    {
        label: "LVSD Confirmed",
        metricTypes: [
            MetricTypeIdEnum.ACEBB_CONFIRMED,
            MetricTypeIdEnum.HO_CONFIRMED,
            MetricTypeIdEnum.OTHER_CONFIRMED
        ],
        color: Blue
    },
    {
        label: "Query LVSD",
        metricTypes: [
            MetricTypeIdEnum.ACEBB_QUERY,
            MetricTypeIdEnum.HO_QUERY,
            MetricTypeIdEnum.OTHER_QUERY
        ],
        color: Orange
    },
    {
        label: "LVSD Excluded (HFpEF)",
        metricTypes: [
            MetricTypeIdEnum.ACEBB_EXCLUDED,
            MetricTypeIdEnum.HO_EXCLUDED,
            MetricTypeIdEnum.OTHER_EXCLUDED
        ],
        color: Fuchsia
    }
]

export const OtherNonLvsd = () => {
    const groupSizeCaption = <>Patients not on the LVSD register or with a record of HFpEF identified for screening to <strong>exclude</strong> LVSD</>;

    return <TopCellTemplate
        title="Other Non-LVSD Patients"
        groupSizeMetrics={groupSizeMetrics}
        groupSizeCaption={groupSizeCaption}
        completedSizeMetrics={completedSizeMetrics}
        slices={slices}/>
}