import "./ColumnSettingLabel.scss"
import React, {useMemo} from "react";
import {FrozenColumnsSeparatorKey} from "../../PatientsList";
import {useDrag, useDrop} from "react-dnd";
import {Checkbox, CheckboxChangeEvent} from "primereact/checkbox";
import {classNames} from "primereact/utils";
import {UserColumnSettings} from "../../../../../../models/UserColumnSettings";

interface ColumnSettingLabelProps {
    columnSettings: UserColumnSettings,
    onVisiblePropertyChanged: (event: CheckboxChangeEvent) => void
    onDrop: (sourceLabelKey: string, targetLabel: UserColumnSettings) => void
}

const DraggableItemTypes = {
    LABEL: 'label'
}

const ColumnSettingLabel: React.FunctionComponent<ColumnSettingLabelProps> = ({columnSettings, onVisiblePropertyChanged, onDrop}) => {
    const [{ isHovered }, drop] = useDrop(
        () => ({
            accept: DraggableItemTypes.LABEL,
            drop: (item: any) => {
                onDrop(item.settingLabel.key, columnSettings);
                return undefined;
            },
            collect: (monitor) => ({
                isHovered: monitor.isOver()
            })
        }),
        [onDrop, columnSettings]);
    
    const settingLabel = useMemo(() => {
        const labelClass = classNames({
            'column-setting-label': true,
            'frozen': columnSettings.isFrozen,
            'hover-active': isHovered,
            'frozen-separator': columnSettings.columnKey === FrozenColumnsSeparatorKey
        });
        
        return (
            <div
                ref={drop}
                key={columnSettings.columnKey}
                className={labelClass}
            >
                <div className="column-setting-label-text">
                    <i className="fa fa-bars drag-icon"></i>
                    <span className="column-setting-label">{columnSettings.columnName?.toString()}</span>
                </div>
                {columnSettings.columnKey !== FrozenColumnsSeparatorKey
                    && <Checkbox
                        className='column-setting-checkbox'
                        name="columnSetting"
                        value={columnSettings}
                        inputId={columnSettings.columnKey}
                        checked={columnSettings.isVisible}
                        onChange={(evt) => onVisiblePropertyChanged(evt)}
                />}
            </div>
        )
    }, [columnSettings, onVisiblePropertyChanged, isHovered, drop]);
    
    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: DraggableItemTypes.LABEL,
            item: { settingLabel },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            })
        }),
        [settingLabel]
    );

    return (
        <div 
            ref={dragRef} 
            style={{
                opacity: isDragging ? 0.5 : 1
            }}
        >
            {settingLabel}
        </div>
    )
}

export const ColumnSettingLabelMemo = React.memo(ColumnSettingLabel);