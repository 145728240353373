//@ts-ignore
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TabInfo } from '../../models/TabInfo';

export interface HeartFailureMenuState {
    tabInfo: TabInfo | undefined;
}

const initialState: HeartFailureMenuState = {
    tabInfo: undefined,
};

export const heartFailureMenuSlice = createSlice({
  name: 'heartFailureMenu',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTabInfo: (state: HeartFailureMenuState, action: PayloadAction<TabInfo>) => {
        const tabInfo: TabInfo = action.payload;
        state.tabInfo = tabInfo;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder: any) => {
  },
});

export const { setTabInfo } = heartFailureMenuSlice.actions;

export default heartFailureMenuSlice.reducer;
