export enum MetricTypeIdEnum {
    HF_REG_TOTAL = 'HF_REG_TOTAL',
    LVSD_REG_TOTAL = 'LVSD_REG_TOTAL',
    HF_PERCENT = 'HF_PERCENT',
    HF_PREV_LAST_YR = 'HF_PREV_LAST_YR',
    LVSD_PERCENT = 'LVSD_PERCENT',
    LVSD_PREV_LAST_YR = 'LVSD_PREV_LAST_YR',
    HF_REVIEW_PAT_COUNT = 'HF_REVIEW_PAT_COUNT',
    LVSD_ACE_ARB_PAT_COUNT = 'LVSD_ACE_ARB_PAT_COUNT',
    LVSD_BB_PAT_COUNT = 'LVSD_BB_PAT_COUNT',
    REGION_NAME = 'REGION_NAME',
    HF_QOF_AMT_FIRST = 'HF_QOF_AMT_FIRST',
    HF_QOF_AMT_LAST = 'HF_QOF_AMT_LAST',
    HF_QOF_AMT_UPDATED = 'HF_QOF_AMT_UPDATED',
    LVSD_QOF_AMT_FIRST = 'LVSD_QOF_AMT_FIRST',
    LVSD_QOF_AMT_LAST = 'LVSD_QOF_AMT_LAST',
    LVSD_QOF_AMT_UPDATED = 'LVSD_QOF_AMT_UPDATED',
    LVSD_QOF_COUNT_FIRST = 'LVSD_QOF_COUNT_FIRST',
    LVSD_QOF_COUNT_LAST = 'LVSD_QOF_COUNT_LAST',
    LVSD_QOF_COUNT_UPDATED = 'LVSD_QOF_COUNT_UPDATED',
    HF_DOMAIN_YR_AMT_FIRST = 'HF_DOMAIN_YR_AMT_FIRST',
    HF_DOMAIN_YR_AMT_LAST = 'HF_DOMAIN_YR_AMT_LAST',
    HF_DOMAIN_YR_AMT_UPDATED = 'HF_DOMAIN_YR_AMT_UPDATED',
    HF_DOMAIN_PERC_AMT_FIRST = 'HF_DOMAIN_PERC_AMT_FIRST',
    HF_DOMAIN_PERC_AMT_LAST = 'HF_DOMAIN_PERC_AMT_LAST',
    HF_DOMAIN_PERC_AMT_UPDATED = 'HF_DOMAIN_PERC_AMT_UPDATED',
    ADDN_INCOME_FIRST = 'ADDN_INCOME_FIRST',
    ADDN_INCOME_LAST = 'ADDN_INCOME_LAST',
    HF_REG_FIRST = 'HF_REG_FIRST',
    HF_REG_LAST = 'HF_REG_LAST',
    HF_REG_UPDATED = 'HF_REG_UPDATED',
    LVSD_REG_FIRST = 'LVSD_REG_FIRST',
    LVSD_REG_LAST = 'LVSD_REG_LAST',
    LVSD_REG_UPDATED = 'LVSD_REG_UPDATED',
    EXCLUDED_LVSD_FIRST = 'EXCLUDED_LVSD_FIRST',
    EXCLUDED_LVSD_LAST = 'EXCLUDED_LVSD_LAST',
    LVSD_HFPEF_UPDATED = 'LVSD_HFPEF_UPDATED',
    QUERY_LVSD_FIRST = 'QUERY_LVSD_FIRST',
    QUERY_LVSD_LAST = 'QUERY_LVSD_LAST',
    LVSD_QUERY_UPDATED = 'LVSD_QUERY_UPDATED',
    HF_PERCENT_FIRST = 'HF_PERCENT_FIRST',
    HF_PERCENT_LAST = 'HF_PERCENT_LAST',
    HF_PERCENT_UPDATED = 'HF_PERCENT_UPDATED',
    LVSD_PERCENT_FIRST = 'LVSD_PERCENT_FIRST',
    LVSD_PERCENT_LAST = 'LVSD_PERCENT_LAST',
    LVSD_PERCENT_UPDATED = 'LVSD_PERCENT_UPDATED',
    QOF_POINTS_ACHIEVED = 'QOF_POINTS_ACHIEVED',
    TOTAL_QOF_POINTS = 'TOTAL_QOF_POINTS',
    CDG_HF_PERCENT = 'CDG_HF_PERCENT',
    CDG_LVSD_PERCENT = 'CDG_LVSD_PERCENT',
    ENG_HF_PERCENT = 'ENG_HF_PERCENT',
    ENG_LVSD_PERCENT = 'ENG_LVSD_PERCENT',
    CODEMED_CONFIRMED = 'CODEMED_CONFIRMED',
    CODEMED_QUERY = 'CODEMED_QUERY',
    CODEMED_EXCLUDED = 'CODEMED_EXCLUDED',
    CODEMED_NOTSCREEN = 'CODEMED_NOTSCREEN',
    CODE_CONFIRMED = 'CODE_CONFIRMED',
    CODE_QUERY = 'CODE_QUERY',
    CODE_EXCLUDED = 'CODE_EXCLUDED',
    CODE_NOTSCREEN = 'CODE_NOTSCREEN',
    MED_CONFIRMED = 'MED_CONFIRMED',
    MED_QUERY = 'MED_QUERY',
    MED_EXCLUDED = 'MED_EXCLUDED',
    MED_NOTSCREEN = 'MED_NOTSCREEN',
    ACEBB_CONFIRMED = 'ACEBB_CONFIRMED',
    ACEBB_QUERY = 'ACEBB_QUERY',
    ACEBB_EXCLUDED = 'ACEBB_EXCLUDED',
    ACEBB_NOTSCREEN = 'ACEBB_NOTSCREEN',
    HO_CONFIRMED = 'HO_CONFIRMED',
    HO_QUERY = 'HO_QUERY',
    HO_EXCLUDED = 'HO_EXCLUDED',
    HO_NOTSCREEN = 'HO_NOTSCREEN',
    OTHER_CONFIRMED = 'OTHER_CONFIRMED',
    OTHER_QUERY = 'OTHER_QUERY',
    OTHER_EXCLUDED = 'OTHER_EXCLUDED',
    OTHER_NOTSCREEN = 'OTHER_NOTSCREEN',
    HF003_TRUE_LAST = 'HF003_TRUE_LAST',
    HF003_TRUE_FIRST = 'HF003_TRUE_FIRST',
    HF003_TRUE_UPDATED = 'HF003_TRUE_UPDATED',
    HF003_QOF_LAST = 'HF003_QOF_LAST',
    HF003_QOF_FIRST = 'HF003_QOF_FIRST',
    HF003_QOF_UPDATED = 'HF003_QOF_UPDATED',
    HF006_TRUE_LAST = 'HF006_TRUE_LAST',
    HF006_TRUE_FIRST = 'HF006_TRUE_FIRST',
    HF006_TRUE_UPDATED = 'HF006_TRUE_UPDATED',
    HF006_QOF_LAST = 'HF006_QOF_LAST',
    HF006_QOF_FIRST = 'HF006_QOF_FIRST',
    HF006_QOF_UPDATED = 'HF006_QOF_UPDATED',
    HF007_TRUE_LAST = 'HF007_TRUE_LAST',
    HF007_TRUE_FIRST = 'HF007_TRUE_FIRST',
    HF007_TRUE_UPDATED = 'HF007_TRUE_UPDATED',
    HF007_QOF_LAST = 'HF007_QOF_LAST',
    HF007_QOF_FIRST = 'HF007_QOF_FIRST',
    HF007_QOF_UPDATED = 'HF007_QOF_UPDATED',
    HF008_TRUE_LAST = 'HF008_TRUE_LAST',
    HF008_TRUE_FIRST = 'HF008_TRUE_FIRST',
    HF008_TRUE_UPDATED = 'HF008_TRUE_UPDATED',
    HF008_QOF_LAST = 'HF008_QOF_LAST',
    HF008_QOF_FIRST = 'HF008_QOF_FIRST',
    HF008_QOF_UPDATED = 'HF008_QOF_UPDATED',
    HF003_POINT_LAST = 'HF003_POINT_LAST',
    HF003_POINT_FIRST = 'HF003_POINT_FIRST',
    HF003_POINT_UPDATED = 'HF003_POINT_UPDATED',
    HF003_TOTAL_POINT = 'HF003_TOTAL_POINT',
    HF006_POINT_LAST = 'HF006_POINT_LAST',
    HF006_POINT_FIRST = 'HF006_POINT_FIRST',
    HF006_POINT_UPDATED = 'HF006_POINT_UPDATED',
    HF006_TOTAL_POINT = 'HF006_TOTAL_POINT',
    HF007_POINT_LAST = 'HF007_POINT_LAST',
    HF007_POINT_FIRST = 'HF007_POINT_FIRST',
    HF007_POINT_UPDATED = 'HF007_POINT_UPDATED',
    HF007_TOTAL_POINT = 'HF007_TOTAL_POINT',
    HF008_POINT_LAST = 'HF008_POINT_LAST',
    HF008_POINT_FIRST = 'HF008_POINT_FIRST',
    HF008_POINT_UPDATED = 'HF008_POINT_UPDATED',
    HF008_TOTAL_POINT = 'HF008_TOTAL_POINT',
    SCREEN_LVSD_CONFIRMED = 'SCREEN_LVSD_CONFIRMED',
    SCREEN_NOT_SCREEN_PRIORITY = 'SCREEN_NOT_SCREEN_PRIORITY',
    SCREEN_NOT_SCREEN_OTHER = 'SCREEN_NOT_SCREEN_OTHER',
    SCREEN_QUERY = 'SCREEN_QUERY',
    SCREEN_HFPEF = 'SCREEN_HFPEF'
}