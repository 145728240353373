import React, { useMemo } from 'react';
import './ServerInternalError.scss';
import { ErrorBlue, ErrorGray } from '../../../utils/palette';
import { HttpStatusCode } from 'axios';
import { GoHomeContactSupportButtonsMemo } from '../GoHomeContactSupportButtons/GoHomeContactSupportButtons';

interface ServerInternalErrorProps {
    errorCode?: HttpStatusCode,
}

const ServerInternalError: React.FunctionComponent<
    ServerInternalErrorProps> = ({errorCode}) => {

    const errorMessage = useMemo(
        () => {
            switch (errorCode) {
                case HttpStatusCode.InternalServerError:
                    return "Internal Server Error";
                case HttpStatusCode.BadGateway:
                    return "Bad Gateway";
                case HttpStatusCode.GatewayTimeout:
                    return "Gateway Timeout"
                default:
                    return HttpStatusCode[errorCode!];
            }
        }, [errorCode]);

    return(
        <div className='server-internal-error-container'>
            <h1 
                className='server-internal-error-title'
                style={{color: ErrorBlue}}>
                Internal Server Error
            </h1>
            <h2 
                className='server-internal-error-details'
                style={{color: ErrorGray}}>
                Code: {errorCode} - {errorMessage}
            </h2>
            <div className='server-internal-error-buttons-container-wrapper'>
                <GoHomeContactSupportButtonsMemo/>
            </div>
        </div>
    );
}

export const ServerInternalErrorMemo = React.memo(ServerInternalError)