import urlJoin from "url-join";
import { ICON_NAME } from "./icon-name";
import HttpService from "../app/services/http-service";
import {AxiosRequestConfig} from "axios";

const baseUrl = document.getElementsByTagName('base')[0].href;

const axiosClient = HttpService.getAxiosClient();

export const getBaseUrl = () => baseUrl

export const getIconUrl = (iconName: ICON_NAME) => urlJoin(getBaseUrl(), 'icons', iconName);

export const getAppUrl = (args: string[]) => urlJoin([getBaseUrl(), ...args])

export const fetchGet = <T>(url: string) =>
    axiosClient.get<T>(url).then(
        x => x.data);

export const fetchPost = <T>(url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosClient.post<T>(url, data, config).then(
        x => x.data);

export const fetchDelete = <T>(url: string,) =>
    axiosClient.delete<T>(url).then(
        x => x.data);