import {Slice, TopCellTemplate} from "../TopCellTemplate/TopCellTemplate";
import {MetricTypeIdEnum} from "../../../../models/MetricTypeIdEnum";
import {Blue, Fuchsia, LightGray, Orange} from "../../../../utils/palette-hf";

const groupSizeMetrics = [
    MetricTypeIdEnum.CODEMED_CONFIRMED,
    MetricTypeIdEnum.CODEMED_QUERY,
    MetricTypeIdEnum.CODEMED_EXCLUDED,
    MetricTypeIdEnum.CODEMED_NOTSCREEN,
    MetricTypeIdEnum.CODE_CONFIRMED,
    MetricTypeIdEnum.CODE_QUERY,
    MetricTypeIdEnum.CODE_EXCLUDED,
    MetricTypeIdEnum.CODE_NOTSCREEN,
    MetricTypeIdEnum.MED_CONFIRMED,
    MetricTypeIdEnum.MED_QUERY,
    MetricTypeIdEnum.MED_EXCLUDED,
    MetricTypeIdEnum.MED_NOTSCREEN
];

const completedSizeMetrics = [
    MetricTypeIdEnum.CODEMED_CONFIRMED,
    MetricTypeIdEnum.CODEMED_QUERY,
    MetricTypeIdEnum.CODEMED_EXCLUDED,
    MetricTypeIdEnum.CODE_CONFIRMED,
    MetricTypeIdEnum.CODE_QUERY,
    MetricTypeIdEnum.CODE_EXCLUDED,
    MetricTypeIdEnum.MED_CONFIRMED,
    MetricTypeIdEnum.MED_QUERY,
    MetricTypeIdEnum.MED_EXCLUDED
]

const slices: Slice[] = [
    {
        label: "Not screened",
        metricTypes: [
            MetricTypeIdEnum.CODEMED_NOTSCREEN,
            MetricTypeIdEnum.CODE_NOTSCREEN,
            MetricTypeIdEnum.MED_NOTSCREEN
        ],
        color: LightGray
    },
    {
        label: "LVSD Confirmed",
        metricTypes: [
            MetricTypeIdEnum.CODEMED_CONFIRMED,
            MetricTypeIdEnum.CODE_CONFIRMED,
            MetricTypeIdEnum.MED_CONFIRMED
        ],
        color: Blue
    },
    {
        label: "Query LVSD",
        metricTypes: [
            MetricTypeIdEnum.CODEMED_QUERY,
            MetricTypeIdEnum.CODE_QUERY,
            MetricTypeIdEnum.MED_QUERY
        ],
        color: Orange
    },
    {
        label: "LVSD Excluded (HFpEF)",
        metricTypes: [
            MetricTypeIdEnum.CODEMED_EXCLUDED,
            MetricTypeIdEnum.CODE_EXCLUDED,
            MetricTypeIdEnum.MED_EXCLUDED
        ],
        color: Fuchsia
    }
]

export const PriorityGroup = () => {
    const groupSizeCaption = <>Patients not on the LVSD register or with a record of HFpEF identified for screening to <strong>confirm</strong> LVSD</>;
    
    return <TopCellTemplate 
        title="Priority group"
        groupSizeMetrics={groupSizeMetrics}
        groupSizeCaption={groupSizeCaption}
        completedSizeMetrics={completedSizeMetrics}
        slices={slices}/>
}