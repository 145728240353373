import { Outlet } from 'react-router';
import './GlobalLayout.scss';
import React from 'react';
// @ts-ignore
import { GlobalNavigation } from '@e360/react-global-navigation';
import { useSelector } from 'react-redux';
import { actionsSelector, profileSelector, siteUriSelector } from '../../../app/selectors/globalNavigationSelector';
import { ErrorBoundary } from 'react-error-boundary';
import { HttpErrorInterceptorMemo } from '../../HttpErrorInterceptor/HttpErrorInterceptor';
import { GlobalErrorBoundaryMemo } from '../../GlobalErrorBoundary/GlobalErrorBoundary';

const GlobalLayout = () => {
    const siteUri = useSelector(siteUriSelector);
    const actions = useSelector(actionsSelector);
    const profile = useSelector(profileSelector);

    return(
                <div className='global-layout-wrapper'>
                    <GlobalNavigation
                        appName="IHAN"
                        siteUrl={siteUri}
                        actions={actions}
                        profile={profile}
                    />
                    <ErrorBoundary FallbackComponent={GlobalErrorBoundaryMemo}>
                        <HttpErrorInterceptorMemo>
                            <Outlet/>
                        </HttpErrorInterceptorMemo>
                    </ErrorBoundary>
                </div>
    )
}

export const GlobalLayoutMemo = React.memo(GlobalLayout);