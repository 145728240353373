import './OverallScreeningProgressByCategory.scss';
import React, {useCallback} from 'react';
import {Card, CardBody, CardTitle} from "@e360/react-core";
import {MetricTypeIdEnum} from "../../../../models/MetricTypeIdEnum";
import Plot from "react-plotly.js";
import {Layout, PlotData} from "plotly.js";
import _ from 'lodash';
import {useMetrics} from "../../../../utils/useMetrics";
import {Blue, Fuchsia, LightGray, Orange} from "../../../../utils/palette-hf";
import {BlueLoader} from "../../../BlueLoader/BlueLoader";
import {DEFAULT_PLOTLY_CONFIG} from "../../../../utils/plotly-commons";

const metricIds = [
    MetricTypeIdEnum.CODEMED_CONFIRMED,
    MetricTypeIdEnum.CODEMED_QUERY,
    MetricTypeIdEnum.CODEMED_EXCLUDED,
    MetricTypeIdEnum.CODEMED_NOTSCREEN,
    MetricTypeIdEnum.CODE_CONFIRMED,
    MetricTypeIdEnum.CODE_QUERY,
    MetricTypeIdEnum.CODE_EXCLUDED,
    MetricTypeIdEnum.CODE_NOTSCREEN,
    MetricTypeIdEnum.MED_CONFIRMED,
    MetricTypeIdEnum.MED_QUERY,
    MetricTypeIdEnum.MED_EXCLUDED,
    MetricTypeIdEnum.MED_NOTSCREEN,
    MetricTypeIdEnum.ACEBB_CONFIRMED,
    MetricTypeIdEnum.ACEBB_QUERY,
    MetricTypeIdEnum.ACEBB_EXCLUDED,
    MetricTypeIdEnum.ACEBB_NOTSCREEN,
    MetricTypeIdEnum.HO_CONFIRMED,
    MetricTypeIdEnum.HO_QUERY,
    MetricTypeIdEnum.HO_EXCLUDED,
    MetricTypeIdEnum.HO_NOTSCREEN,
    MetricTypeIdEnum.OTHER_CONFIRMED,
    MetricTypeIdEnum.OTHER_QUERY,
    MetricTypeIdEnum.OTHER_EXCLUDED,
    MetricTypeIdEnum.OTHER_NOTSCREEN
];

const priorityCodeAndMedicationCategory = 'Priority: Code & Medication';
const priorityCodeCategory = 'Priority: Code';
const priorityMedicationCategory = 'Priority: Medication';
const otherACEPlusAndBBCategory = 'Other: ACE+ & BB';
const otherHOEchoCategory = 'Other: H/O Echo';
const otherCategory = 'Other';

const categoryNames = [
    priorityCodeAndMedicationCategory,
    priorityCodeCategory,
    priorityMedicationCategory,
    otherACEPlusAndBBCategory,
    otherHOEchoCategory,
    otherCategory
];

const categoryIndexByMetricIdPrefix = {
    "CODEMED" : categoryNames.findIndex(cn => cn === priorityCodeAndMedicationCategory),
    "CODE" : categoryNames.findIndex(cn => cn === priorityCodeCategory),
    "MED" : categoryNames.findIndex(cn => cn === priorityMedicationCategory),
    "ACEBB" :categoryNames.findIndex(cn => cn === otherACEPlusAndBBCategory),
    "HO": categoryNames.findIndex(cn => cn === otherHOEchoCategory),
    "OTHER": categoryNames.findIndex(cn => cn === otherCategory),
};

const layout: Partial<Layout> = {
    barmode: "stack",
    bargap: 0.3,
    autosize: true,
    hovermode: "x unified",
    hoverlabel: { bgcolor: "#FFF" },
    font: {
        family: "Proxima Nova"
    },
    legend: {
        yanchor: "top",
        y: -0.1,
        xanchor: "center",
        x: 0.5,
        orientation: "h",
        traceorder: "normal"
    },
    margin: {
        t: 0,
        b: 50,
        l: 40,
        r: 20,
        pad: 5
    },
    yaxis: {fixedrange: true},
    xaxis : {fixedrange: true}
}

export const OverallScreeningProgressByCategory = () => {
    const {metrics, isLoading} = useMetrics(metricIds);

    const getMetricsValues = useCallback((...seriesMetricIds: MetricTypeIdEnum[]) => {
        if (!metrics) {
            return [];
        }
        return _
            .chain(metrics)
            .filter(m => seriesMetricIds.includes(m.metricTypeId))
            .sortBy(m => {                
                const metricIdPrefix = m.metricTypeId.split('_')[0] ?? '';
                return categoryIndexByMetricIdPrefix[metricIdPrefix] ?? -1;
            })
            .map(o => o.value)
            .valueOf();
    }, [metrics]);
    
    const getTrace = (metricName: string, color: string, metricTypeIds: MetricTypeIdEnum[]): PlotData => {
        const metricValues = getMetricsValues(...metricTypeIds);
        return {
            x: categoryNames,
            y: metricValues,
            name: metricName,
            type: 'bar',
            marker:  {
                color: color
            }
        } as PlotData;
    }
    
    const lvsdConfirmed = getTrace(
        'LVSD Confirmed',
        Blue,
        [
            MetricTypeIdEnum.CODEMED_CONFIRMED,
            MetricTypeIdEnum.CODE_CONFIRMED,
            MetricTypeIdEnum.MED_CONFIRMED,
            MetricTypeIdEnum.ACEBB_CONFIRMED,
            MetricTypeIdEnum.HO_CONFIRMED,
            MetricTypeIdEnum.OTHER_CONFIRMED
        ]
    );
    
    const queryLvsd = getTrace(
        'Query LVSD',
        Orange,
        [
            MetricTypeIdEnum.CODEMED_QUERY,
            MetricTypeIdEnum.CODE_QUERY,
            MetricTypeIdEnum.MED_QUERY,
            MetricTypeIdEnum.ACEBB_QUERY,
            MetricTypeIdEnum.HO_QUERY,
            MetricTypeIdEnum.OTHER_QUERY
        ]
    );
    
    const lvsdExcluded = getTrace(
        'LVSD Excluded (HFpEF)',
        Fuchsia,
        [
            MetricTypeIdEnum.CODEMED_EXCLUDED,
            MetricTypeIdEnum.CODE_EXCLUDED,
            MetricTypeIdEnum.MED_EXCLUDED,
            MetricTypeIdEnum.ACEBB_EXCLUDED,
            MetricTypeIdEnum.HO_EXCLUDED,
            MetricTypeIdEnum.OTHER_EXCLUDED
        ]
    );
    
    const notScreened = getTrace(
        'Not Screened',
        LightGray,
        [
            MetricTypeIdEnum.CODEMED_NOTSCREEN,
            MetricTypeIdEnum.CODE_NOTSCREEN,
            MetricTypeIdEnum.MED_NOTSCREEN,
            MetricTypeIdEnum.ACEBB_NOTSCREEN,
            MetricTypeIdEnum.HO_NOTSCREEN,
            MetricTypeIdEnum.OTHER_NOTSCREEN
        ]
    );
    
    const createNotScreenedWithCategoryTotals = () => {
        const totals: string[] = [];
        for (let categoryIndex = 0; categoryIndex < categoryNames.length; categoryIndex++) {
            const categoryTotal = _.chain([
                lvsdConfirmed.y[categoryIndex],
                queryLvsd.y[categoryIndex],
                lvsdExcluded.y[categoryIndex],
                notScreened.y[categoryIndex],
            ] as string[]).map(mv => Number(mv)).sum().value();
            totals.push(categoryTotal.toString());
        }
        return {
            ...notScreened,
            customdata: totals,
            hovertemplate:
                "%{y}<br>" +
                "<b>Total: %{customdata}</b>"
        };
    }
    
    
    const data = [
        lvsdConfirmed,
        queryLvsd,
        lvsdExcluded,
        createNotScreenedWithCategoryTotals()
    ] 
    
    return <Card className="root-card">
        <CardBody>
            <CardTitle>Overall Screening Progress by Category</CardTitle>
            {isLoading && <BlueLoader/>}
            {metrics && <Plot
                className="chart-wrapper"
                data={data}
                layout={layout}
                config={DEFAULT_PLOTLY_CONFIG}/>}
        </CardBody>
    </Card>;
};