import React from "react";

export const hf008MetricDescription = (
    <>
        The percentage of patients with a diagnosis of heart failure after 1 April 2023 which has been confirmed by:
        <ol>
            <li>an echocardiogram or by specialist assessment 6 months before entering on to the register; or</li>
            <li>if newly registered in the preceding 12 months, with a record of an echocardiogram or a specialist assessment within 6 months of the date of registration.</li>
        </ol>
    </>
);
export const  hf007MetricDescription = "The percentage of patients with heart failure on the register, who had a review in the preceding 12 months, including an assessment of functional capacity and a review of medication to ensure medines optimisation at maximum tolerated doses.";
export const  hf003MetricDescription = "The percentage of patients with a current diagnosis of heart failure due to left ventricular systolic dysfunction, who are currently treated with an ACE-I or ARB.";
export const  hf006MetricDescription = "The percentage of patients with a current diagnosis of heart failure due to left ventricular systolic dysfunction, who are currently treated with a beta-blocker licensed for heart failure.";