import { Scrollbars } from 'react-custom-scrollbars-2';
import './PracticeWidget.scss';
// @ts-ignore
import { DropdownItem, Dropdown, DropdownToggle, DropdownMenu, Button, Icon } from '@e360/react-core';
import { PracticeInfoLabelValue } from './PracticeInfoLabelValue/PracticeInfoLabelValueProps';
import { SectionSeparator } from './PracticeInfoSectionSeparator/PracticeInfoSectionSeparator';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PracticeHelpLink } from './PracticeHelpLink/PracticeHelpLink';
//@ts-ignore
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PracticeSelect } from '../../../models/PracticeSelect';

import { getIconUrl } from '../../../utils/api'
import { ICON_NAME } from '../../../utils/icon-name';
import { DATE_FORMAT } from '../../../utils/date-format';
import { formatDate } from '../../../utils/date';
import { formatNumberIntl } from '../../../utils/number';
import { fetchPracticeInfoAsync, setPractice } from '../../../app/slices/practiceSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectPracticeSelector } from '../../../app/selectors/practiceSelectors';
import { useInit } from '../../../utils/custom-react-hooks';
import React from 'react';
import { FlexLoaderMemo } from '../../FlexLoader/FlexLoader';
import { fetchUserPractices } from '../../../app/api/practicesApi';
import {useNavigate} from "react-router-dom";
import {MODULE_URLS} from "../../../app/module-urls";
import {goToPatientsListFirstPage} from "../../../app/slices/patientsListSlice";
import _ from "lodash";

const PracticeWidget = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedPractice, setSelectedPractice] = useState<PracticeSelect | null>(null);
    const [practices, setPractices] = useState<PracticeSelect[]|undefined>(undefined);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const practicesLoaded = useMemo(() => !_.isNil(practices), [practices]);
    const hasPractices = useMemo(() => !_.isEmpty(practices), [practices]);

    const {practiceInfo, isLoading: isLoadingPracticeInfo} = useAppSelector(selectPracticeSelector);
    const practiceInfoLoaded = useMemo(() => !_.isNil(practiceInfo), [practiceInfo]);
    
    const fetchPracticesCallback = useCallback(() => {
        fetchUserPractices().then(
            (practiceList: PracticeSelect[]) => {
                setPractices(practiceList);
                if (practiceList && practiceList.length > 0) {
                    setSelectedPractice(practiceList[0]);
                } else {
                    navigate(MODULE_URLS.NO_PRACTICES_AVAILABLE);
                }
            });
        },
        [navigate]);
    
    useInit(
        () => {
            fetchPracticesCallback();
        });

    useEffect(() => {
        if (!hasPractices && practicesLoaded) {
            setIsCollapsed(true);
        }
    }, [hasPractices, practicesLoaded]);
    
    const fetchPracticeInfoCallback = useCallback(
        async (practiceId: string) => {
            await dispatch(fetchPracticeInfoAsync(practiceId));
        },
        [dispatch]);
    
    useEffect(
        () => {
            if (selectedPractice) {
                dispatch(setPractice(undefined))
                fetchPracticeInfoCallback(
                    selectedPractice.id);
            }
        },
        [dispatch, selectedPractice, fetchPracticeInfoCallback]);

    const changePractice = useCallback((item: PracticeSelect) => {
        dispatch(goToPatientsListFirstPage());
        setSelectedPractice(item);
    }, [dispatch]);
    
    const children = useMemo(
        () => practices?.map((item: PracticeSelect) => (
            <DropdownItem 
                key={`dropdown-item-${item.id}`}
                active={selectedPractice?.id === item.id} 
                onClick={() => changePractice(item)}
                >
                {item.name}
            </DropdownItem>
        )),
        [practices, selectedPractice, changePractice]);
    
    const onSideMenuToggleButtonClick = useCallback(() => {
        setIsCollapsed(current => (!current));
        window.dispatchEvent(new Event("resize"));
    }, []);
    
    const sectionSeparator = useMemo(() => {
        return !isCollapsed && hasPractices && practicesLoaded 
            ? <SectionSeparator/> 
            : <></>;
    }, [isCollapsed, hasPractices, practicesLoaded]);
    
    const sideMenuToggleButtonIcon = useMemo(() => {
        return isCollapsed ? "chevron-right" : "chevron-left";
    }, [isCollapsed]);
    
    return (
        <>
            {hasPractices ? <Button 
                variant="icon"
                className='side-menu-toggle-button'
                onClick={onSideMenuToggleButtonClick}
                style={{ left: isCollapsed ? '2px' : '233px' }}>
                <Icon type={sideMenuToggleButtonIcon} />
            </Button> : <></>}
            <div className="side-menu-wrapper" 
                 style={{ marginLeft: isCollapsed ? '-230px' : '0' }}>
                <Scrollbars style={{ width: '100%', height: '100%' }}>
                    <header className="practice-header">
                        <div className='practice-icon-wrapper'>
                            <div className='practice-icon-circle'>
                                <FontAwesomeIcon
                                    icon={['fas', 'id-card']}
                                    size="2xl"
                                    className="practice-icon"/>
                            </div>
                        </div>
                        <div className='practice-label-wrapper'>
                            <PracticeInfoLabelValue
                                value={selectedPractice?.name ?? ''}/>
                        </div>
                        <div className="practice-dd-wrapper">
                            {practicesLoaded ? (
                                hasPractices ?
                                <Dropdown 
                                    className="practice-name-label" 
                                    size="sm"
                                >
                                    <DropdownToggle
                                        variant="select"
                                        className="practice-name-label">
                                        Change practice
                                    </DropdownToggle>
                                    <DropdownMenu placement="right" container="body">
                                        {children}
                                    </DropdownMenu>
                                </Dropdown> : <></>
                            ) : (
                                <FlexLoaderMemo
                                    wrapperClassName='practice-widget-practices-loader-wrapper'
                                    loaderClassName='practice-widget-loader'/>
                            )}
                        </div>
                    </header>
                    {sectionSeparator}
                    {!isLoadingPracticeInfo ? (
                        practiceInfoLoaded ?
                        <div>
                            <div className="practice-info-wrapper">
                                <PracticeInfoLabelValue
                                    label="ID"
                                    value={practiceInfo!.id}/>
                                <PracticeInfoLabelValue
                                    label="Medical Group"
                                    value={practiceInfo!.medicalGroup}/>
                                <PracticeInfoLabelValue
                                    label="List Size"
                                    value={formatNumberIntl(practiceInfo!.listSize)}/>
                                <PracticeInfoLabelValue
                                    label="Date of Report"
                                    value={formatDate(practiceInfo!.dateOfReport, DATE_FORMAT.dd_mm_yyyy_SLASHED)}/>
                            </div>
                            {sectionSeparator} 
                            <div className="practice-contacts-wrapper">
                                <PracticeHelpLink
                                    iconSource={getIconUrl(ICON_NAME.HEALTHCARE_HOSPITAL_BUILDING)}
                                    label="Information on clinical support for key therapy areas"
                                    link="https://www.interface-cs.co.uk/services/"/>
                                <PracticeHelpLink
                                    iconSource={getIconUrl(ICON_NAME.BUSINESS_PHONE)}
                                    label={`Telephone Service Support: ${window.appSettings.serviceSupportPhoneNumber}`}
                                    link={`tel:${window.appSettings.serviceSupportPhoneNumber}`}/>
                            </div>
                        </div> : <></>
                    ) : (
                        <FlexLoaderMemo
                            wrapperClassName='practice-widget-practice-info-loader-wrapper'
                            loaderClassName='practice-widget-loader'/>
                    )}
                </Scrollbars>
            </div>
        </>
    );
}

export const PracticeWidgetMemo = React.memo(PracticeWidget);