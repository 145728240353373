//@ts-ignore
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPracticeInfo } from '../api/practicesApi';
import { PracticeInfo } from '../../models/PracticeInfo';

enum FetchStatus {
    LOADING = 'loading',
    FAILED = 'failed',
    SUCCEED = 'succeed',
    IDLE = 'idle'
}

export interface PracticeState {
    practiceInfo: PracticeInfo | undefined;
    isLoading: boolean;
    status: FetchStatus;
}

const initialState: PracticeState = {
  practiceInfo: undefined,
  isLoading: false,
  status: FetchStatus.IDLE,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchPracticeInfoAsync = createAsyncThunk(
  'practice/fetchPracticeInfo',
  async (practiceId: string) => {
    const response = await fetchPracticeInfo(practiceId);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const practiceSlice = createSlice({
  name: 'practice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPractice: (state: PracticeState, action: PayloadAction<PracticeInfo|undefined>) => {
        const practiceInfo: PracticeInfo|undefined = action.payload;
        state.practiceInfo = practiceInfo;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchPracticeInfoAsync.pending, (state:PracticeState) => {
        state.status = FetchStatus.LOADING;
        state.isLoading = true;
      })
      .addCase(fetchPracticeInfoAsync.fulfilled, (state:PracticeState, action: PayloadAction<PracticeInfo>) => {
        state.status = FetchStatus.SUCCEED;
        state.practiceInfo = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPracticeInfoAsync.rejected, (state:PracticeState) => {
        state.status = FetchStatus.FAILED;
        state.isLoading = false;
      });
  },
});

export const { setPractice } = practiceSlice.actions;

export default practiceSlice.reducer;
