import './LvsdPatientsByTherapyMetrics.scss'
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Card, CardBody, CardTitle } from '@e360/react-core';
import Plot from 'react-plotly.js';
import {TemporalMetricsCollection} from "../../../../models/TemporalMetricsCollection";
import {CardLoaderMemo} from "../../../CardLoader/CardLoader";
import {TemporalMetricTypeIdEnum} from "../../../../models/TemporalMetricTypeIdEnum";
import {Blue, Purple} from "../../../../utils/palette-hf";
import {DEFAULT_PLOTLY_CONFIG} from "../../../../utils/plotly-commons";

interface LvsdPatientsByTherapyMetricsProps {
    lvsdAceArbTemporalMetrics: TemporalMetricsCollection[] | undefined,
    lvsdBbTemporalMetrics: TemporalMetricsCollection[] | undefined
}

const LvsdPatientsByTherapyMetrics: React.FunctionComponent<LvsdPatientsByTherapyMetricsProps> = ({lvsdAceArbTemporalMetrics, lvsdBbTemporalMetrics}) => {
    const [resize, setResize] = useState<boolean>(false);

    const [lvsdAceArbPercTemporalMetrics, setLvsdAceArbPercTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdAceArbCountTemporalMetrics, setLvsdAceArbCountTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdBbPercTemporalMetrics, setLvsdBbPercTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdBbCountTemporalMetrics, setLvsdBbCountTemporalMetrics] = useState<TemporalMetricsCollection>();
    
    const containsData = useMemo(() => {
        return lvsdAceArbTemporalMetrics?.length 
            && lvsdBbTemporalMetrics?.length;
    }, [lvsdAceArbTemporalMetrics, lvsdBbTemporalMetrics]);
    
    useEffect(() => {
        if (containsData) {
            setLvsdAceArbPercTemporalMetrics(lvsdAceArbTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_ACE_ARB_PERC));
            setLvsdAceArbCountTemporalMetrics(lvsdAceArbTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_ACE_ARB_COUNT));
            setLvsdBbPercTemporalMetrics(lvsdBbTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_BB_PERC));
            setLvsdBbCountTemporalMetrics(lvsdBbTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_BB_COUNT));
        }
    }, [containsData, lvsdAceArbTemporalMetrics, lvsdBbTemporalMetrics])
    
    const onAutosizeCallback = useCallback(
        (shouldResize: boolean)=> {
            setResize(shouldResize);
        }, []);

    const layout: Partial<Plotly.Layout> = useMemo(
        () => {
            return {
                autosize: true,
                margin: {
                    t: 0,
                    b: 70,
                    l: 50,
                    r: 20,
                    pad: 5
                },
                yaxis: {
                    side: 'left',
                    rangemode: 'tozero',
                    title: 'Patients',
                    titlefont: {
                        family: "Proxima Nova"
                    },
                    ticksuffix: '%',
                    fixedrange: true
                },
                xaxis: {
                    showgrid: false,
                    dtick: 'M1',
                    tickformat: "%B\n%Y",
                    ticks: 'inside',
                    fixedrange: true
                },
                legend: {
                    orientation: 'h',
                    xanchor: "center",
                    x: 0.5,
                    y: 1.1,
                    yanchor: 'top',
                },
                font: {
                    family: "Proxima Nova"
                }
            };
        }, []);
    
    const metricsChart = useMemo(
        () => {
            const getTemporalMetricsTrace = (
                temporalMetricsPercCollection: TemporalMetricsCollection,
                temporalMetricsCountCollection: TemporalMetricsCollection,
                colorHex: string) =>
            {
                const xValues : Date[] = [];
                const yValues : number[] = [];
                const textValues : string[] = [];

                temporalMetricsPercCollection?.values?.forEach(item => {
                    xValues.push(item.date);
                    yValues.push(Number(item.value));
                });

                temporalMetricsCountCollection?.values?.forEach(item => {
                    textValues.push(item.value);
                });
                
                const data: Plotly.Data = {
                    mode: 'lines+markers',
                    name: temporalMetricsPercCollection?.metricTypeName ?? 'Unknown metric',
                    marker: {
                        color: colorHex,
                        size: 6
                    },
                    x: xValues,
                    y: yValues,
                    text: textValues,
                    hoverinfo: 'x+text'
                };
                return data;
            };
            
            const getTracesData = [
                    getTemporalMetricsTrace(lvsdAceArbPercTemporalMetrics!, lvsdAceArbCountTemporalMetrics!, Purple),
                    getTemporalMetricsTrace(lvsdBbPercTemporalMetrics!, lvsdBbCountTemporalMetrics!, Blue),
                ];
            
            return(
                <Plot
                    className='lvsd-patients-by-therapy-plot'
                    data={getTracesData}
                    layout={layout}
                    config={DEFAULT_PLOTLY_CONFIG}
                    useResizeHandler={true}
                    onAutoSize={()=> onAutosizeCallback(!resize)}/>);
        }, [
            resize, 
            layout, 
            onAutosizeCallback,
            lvsdAceArbPercTemporalMetrics,
            lvsdAceArbCountTemporalMetrics,
            lvsdBbPercTemporalMetrics,
            lvsdBbCountTemporalMetrics]);

    return (
        <div className="lvsd-patients-by-therapy-container">
            {containsData ? (
                <Card className="lvsd-patients-by-therapy-card">
                    <CardBody>
                        <CardTitle>Proportion of HF patients with LVSD by therapy</CardTitle>
                        {metricsChart}
                    </CardBody>
                </Card>
            ) : (
                <CardLoaderMemo
                    cardClassName='lvsd-patients-by-therapy-loader'/>
            )}

        </div>
    );
};

export const LvsdPatientsByTherapyMetricsMemo = React.memo(LvsdPatientsByTherapyMetrics);