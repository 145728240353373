import React from 'react'
import './OpportunityDashboard.scss';
import { HeartFailureMenuMemo } from '../HeartFailureMenu/HeartFailureMenu';
import {OverallScreeningProgressByCategory} from "./OverallScreeningProgressByCategory/OverallScreeningProgressByCategory";
import {PriorityGroup} from "./PriorityGroup/PriorityGroup";
import {OtherNonLvsd} from "./OtherNonLvsd/OtherNonLvsd";
import { OverallScreeningProgressMetricsMemo } from './OverallScreeningProgressMetrics/OverallScreeningProgressMetrics';
import {WebAnalyticsTrackerMemo} from "../../WebAnalyticsTracker/WebAnalyticsTracker";
import { ModuleContainerMemo } from '../../ModuleContainer/ModuleContainer';

export const OpportunityDashboard = () => {
    return (
        <ModuleContainerMemo>
            <HeartFailureMenuMemo/>
            <WebAnalyticsTrackerMemo/>
            <div className="opportunity-dashboard-top-row">
                <div className="opportunity-dashboard-priority-group-cell">
                    <PriorityGroup/>
                </div>
                <div className="opportunity-dashboard-other-non-lvsd-cell">
                    <OtherNonLvsd/>
                </div>
            </div>
            <OverallScreeningProgressByCategory/>
            <OverallScreeningProgressMetricsMemo/>
        </ModuleContainerMemo>
    );
}

export const OpportunityDashboardMemo = React.memo(OpportunityDashboard);

