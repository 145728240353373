import React, {useEffect, useMemo} from 'react';
import { Button } from "@e360/react-core";
import './NoPracticesAvailableView.scss';
import { ErrorBlue } from '../../utils/palette';
import {useAppSelector} from "../../app/hooks";
import {selectPracticeSelector} from "../../app/selectors/practiceSelectors";
import {MODULE_URLS} from "../../app/module-urls";
import {useNavigate} from "react-router-dom";
import {ReactSVG} from "react-svg";
import {getIconUrl} from "../../utils/api";
import {ICON_NAME} from "../../utils/icon-name";

const NoPracticesAvailableView = () => {
    const navigate = useNavigate();
    const {practiceInfo} = useAppSelector(selectPracticeSelector);

    const supportNumber = window?.appSettings?.serviceSupportPhoneNumber;
    const contactSupportLabel = supportNumber
        ? `Contact Support: ${window.appSettings.serviceSupportPhoneNumber}`
        : `Contact Support`;

    useEffect(() => {
        if (practiceInfo) {
            navigate(MODULE_URLS.PERFORMANCE_SUMMARY_DASHBOARD)
        }
    }, [practiceInfo, navigate]);

    const companyIconUrl = useMemo(
        () => getIconUrl(ICON_NAME.COMPANY_LOGO_HORIZONTAL),
        []);

    const sadFaceIconUrl = useMemo(
        () => getIconUrl(ICON_NAME.SAD_FACE),
        []);
    
    return(
        <div className='no-practices-available-container'>
            <ReactSVG
                className='access-denied-company-icon'
                src={companyIconUrl}
                afterInjection={(svg) => {
                    svg.setAttribute('style', 'height: 50px; width: 150px')
                }}/>
            <ReactSVG
                className='access-denied-sad-face-icon'
                src={sadFaceIconUrl}
                afterInjection={(svg) => {
                    svg.setAttribute('style', 'height: 90px; width: 90px')
                }}/>
            <h3
                className='no-practices-available-title'
                style={{color: ErrorBlue, paddingTop: '25px'}}>
                No practice data is assigned to your User ID.
            </h3>
            <h3
                className='no-practices-available-title'
                style={{color: ErrorBlue}}>
                Please contact IHAN support.
            </h3>
            <div className='no-practices-available-buttons-container-wrapper'>
                <div className='no-practices-available-buttons-container'>
                    <a href={`tel:${supportNumber}`}>
                        <Button
                            variant='secondary'>
                            {contactSupportLabel}
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export const NoPracticesAvailableViewMemo = React.memo(NoPracticesAvailableView)