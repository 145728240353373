//@ts-ignore
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface GlobalNavigationState {
    siteUri: string | undefined;
    actions: any;
    profile: any;
}

const initialState: GlobalNavigationState = {
    siteUri: undefined,
    actions: undefined,
    profile: undefined,
};

export const globalNavigationSlice = createSlice({
    name: 'globalNavigation',
    initialState,
    reducers: {
        setSiteUri: (state: GlobalNavigationState, action: PayloadAction<string>) => {
            state.siteUri = action.payload;
        },
        setActions: (state: GlobalNavigationState, action: PayloadAction<any>) => {
            state.actions = action.payload;
        },
        setProfile: (state: GlobalNavigationState, action: PayloadAction<any>) => {
            state.profile = action.payload;
        }
    },
    extraReducers: (builder: any) => {
    },
});

export const { 
    setSiteUri, 
    setActions, 
    setProfile 
} = globalNavigationSlice.actions;

export default globalNavigationSlice.reducer;
