import './GlobalErrorBoundary.scss';

import React, { useMemo } from 'react';
import { HttpServiceError } from '../../models/HttpServiceError';
import { OopsSomethingWentWrongMemo } from './OopsSomethingWentWrong/OopsSomethingWentWrong';
import { HttpStatusCode } from 'axios';
import { ScheduledMaintenanceMemo } from './ScheduledMaintenance/ScheduledMaintenance';
import { ServerInternalErrorMemo } from './ServerInternalError/ServerInternalError';
import { TimeoutErrorMemo } from './TimeoutError/TimeoutError';
import { HttpServiceTimeoutError } from '../../models/HttpServiceTimeoutError';
import {AccessDeniedErrorMemo} from "./AccessDeniedError/AccessDeniedError";


interface GlobalErrorBoundaryProps {
    error?: any,
    resetErrorBoundary?: any
}

const GlobalErrorBoundary: React.FunctionComponent<
GlobalErrorBoundaryProps> = ({error, resetErrorBoundary}) => {

    if(error) {
        window.console.log(error);
    }

    const errorComponent = useMemo(
        () => {
            if (error instanceof HttpServiceError) {
                if (error.httpStatusCode === HttpStatusCode.RequestTimeout) {
                    const aborted = error instanceof HttpServiceTimeoutError ? error.aborted : false; 
                    return (<TimeoutErrorMemo aborted={aborted}/>);
                } else if (error.httpStatusCode === HttpStatusCode.ServiceUnavailable) {
                    return (<ScheduledMaintenanceMemo/>);
                } else if (error.httpStatusCode >= 500 && error.httpStatusCode < 600) {
                    return (<ServerInternalErrorMemo errorCode={error.httpStatusCode}/>);
                } else if (error.httpStatusCode === HttpStatusCode.Forbidden) {
                    return (<AccessDeniedErrorMemo />);
                }
            }

            return (<OopsSomethingWentWrongMemo/>);
        }, [error]);

    return(
        <div className='global-error-boundary-container'>
            {errorComponent}
        </div>
    )
};

export const GlobalErrorBoundaryMemo = React.memo(GlobalErrorBoundary);