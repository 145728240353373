import './TotalLvsdPatientsByTherapyMetrics.scss'
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Card, CardBody, CardTitle } from '@e360/react-core';
import Plot from 'react-plotly.js';
import {TemporalMetricsCollection} from "../../../../models/TemporalMetricsCollection";
import {CardLoaderMemo} from "../../../CardLoader/CardLoader";
import {Blue, Purple, Orange, LightGray} from "../../../../utils/palette-hf";
import {TemporalMetricTypeIdEnum} from "../../../../models/TemporalMetricTypeIdEnum";
import {DEFAULT_PLOTLY_CONFIG} from "../../../../utils/plotly-commons";

interface TotalLvsdPatientsByTherapyMetricsProps {
    lvsdAceArbBbTemporalMetrics: TemporalMetricsCollection[] | undefined,
    lvsdAceArbOnlyTemporalMetrics: TemporalMetricsCollection[] | undefined,
    lvsdBbOnlyTemporalMetrics: TemporalMetricsCollection[] | undefined,
    lvsdNoneTemporalMetrics: TemporalMetricsCollection[] | undefined,
}

const TotalLvsdPatientsByTherapyMetrics: React.FunctionComponent<TotalLvsdPatientsByTherapyMetricsProps> = ({lvsdAceArbBbTemporalMetrics, lvsdAceArbOnlyTemporalMetrics, lvsdBbOnlyTemporalMetrics, lvsdNoneTemporalMetrics}) => {
    const [resize, setResize] = useState<boolean>(false);

    const [lvsdAceArbBbPercTemporalMetrics, setLvsdAceArbBbPercTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdAceArbBbCountTemporalMetrics, setLvsdAceArbBbCountTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdAceArbOnlyPercTemporalMetrics, setLvsdAceArbOnlyPercTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdAceArbOnlyCountTemporalMetrics, setLvsdAceArbOnlyCountTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdBbOnlyPercTemporalMetrics, setLvsdBbOnlyPercTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdBbOnlyCountTemporalMetrics, setLvsdBbOnlyCountTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdNonePercTemporalMetrics, setLvsdNonePercTemporalMetrics] = useState<TemporalMetricsCollection>();
    const [lvsdNoneCountTemporalMetrics, setLvsdNoneCountTemporalMetrics] = useState<TemporalMetricsCollection>();
    
    const containsData = useMemo(() => {
        return lvsdAceArbBbTemporalMetrics?.length 
            && lvsdAceArbOnlyTemporalMetrics?.length
            && lvsdBbOnlyTemporalMetrics?.length
            && lvsdNoneTemporalMetrics?.length;
    }, [lvsdAceArbBbTemporalMetrics, lvsdAceArbOnlyTemporalMetrics, lvsdBbOnlyTemporalMetrics, lvsdNoneTemporalMetrics])

    useEffect(() => {
        if (containsData) {
            setLvsdAceArbBbPercTemporalMetrics(lvsdAceArbBbTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_ACE_ARB_BB_PERC));
            setLvsdAceArbBbCountTemporalMetrics(lvsdAceArbBbTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_ACE_ARB_BB_COUNT));
            setLvsdAceArbOnlyPercTemporalMetrics(lvsdAceArbOnlyTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_ACE_ARB_ONLY_PERC));
            setLvsdAceArbOnlyCountTemporalMetrics(lvsdAceArbOnlyTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_ACE_ARB_ONLY_COUNT));
            setLvsdBbOnlyPercTemporalMetrics(lvsdBbOnlyTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_BB_ONLY_PERC));
            setLvsdBbOnlyCountTemporalMetrics(lvsdBbOnlyTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_BB_ONLY_COUNT));
            setLvsdNonePercTemporalMetrics(lvsdNoneTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_NONE_PERC));
            setLvsdNoneCountTemporalMetrics(lvsdNoneTemporalMetrics?.find(m => m.metricTypeId === TemporalMetricTypeIdEnum.LVSD_NONE_COUNT));
        }
    },[containsData, lvsdAceArbBbTemporalMetrics, lvsdAceArbOnlyTemporalMetrics, lvsdBbOnlyTemporalMetrics, lvsdNoneTemporalMetrics]);
    
    const onAutosizeCallback = useCallback(
        (shouldResize: boolean)=> {
            setResize(shouldResize);
        }, []);

    const layout: Partial<Plotly.Layout> = useMemo(
        () => {
            return {
                autosize: true,
                margin: {
                    t: 0,
                    b: 70,
                    l: 55,
                    r: 20,
                    pad: 5
                },
                yaxis: {
                    side: 'left',
                    rangemode: 'tozero',
                    title: 'Patients',
                    titlefont: {
                        family: "Proxima Nova"
                    },
                    ticksuffix: '%',
                    fixedrange: true
                },
                xaxis: {
                    showgrid: false,
                    dtick: 'M1',
                    tickformat: "%B\n%Y",
                    ticks: 'inside',
                    fixedrange: true
                },
                hovermode: "x unified",
                hoverlabel: { bgcolor: "#FFF" },
                legend: {
                    orientation: 'h',
                    xanchor: "center",
                    x: 0.5,
                    y: 1.2,
                    yanchor: 'top',
                },
                font: {
                    family: "Proxima Nova"
                },
                barmode: 'stack'
            };
        }, []);
    
    const metricsChart = useMemo(
        () => {
            const getTemporalMetricsTrace = (
                temporalMetricsPercCollection: TemporalMetricsCollection,
                temporalMetricsCountCollection: TemporalMetricsCollection,
                colorHex: string) =>
            {
                const xValues : Date[] = [];
                const yValues : number[] = [];
                const textValues : string[] = [];

                temporalMetricsPercCollection?.values?.forEach(item => {
                    xValues.push(item.date);
                    yValues.push(Number(item.value));
                });

                temporalMetricsCountCollection?.values?.forEach(item => {
                    textValues.push(item.value);
                });

                const data: Plotly.Data = {
                    type: 'bar',
                    name: temporalMetricsPercCollection?.metricTypeName ?? 'Unknown metric',
                    marker: {
                        color: colorHex
                    },
                    x: xValues,
                    y: yValues,
                    text: textValues,
                    textposition: 'none',
                    hoverinfo: 'x+text'
                };
                return data;
            };
            
            const getTracesData = [
                    getTemporalMetricsTrace(lvsdAceArbBbPercTemporalMetrics!, lvsdAceArbBbCountTemporalMetrics!, Blue),
                    getTemporalMetricsTrace(lvsdAceArbOnlyPercTemporalMetrics!, lvsdAceArbOnlyCountTemporalMetrics!, Purple),
                    getTemporalMetricsTrace(lvsdBbOnlyPercTemporalMetrics!, lvsdBbOnlyCountTemporalMetrics!, Orange),
                    getTemporalMetricsTrace(lvsdNonePercTemporalMetrics!, lvsdNoneCountTemporalMetrics!, LightGray),
                ];
            
            return(
                <Plot
                    className='total-lvsd-patients-by-therapy-plot'
                    data={getTracesData}
                    layout={layout}
                    config={DEFAULT_PLOTLY_CONFIG}
                    useResizeHandler={true}
                    onAutoSize={()=> onAutosizeCallback(!resize)}/>);
        }, [
            resize, 
            layout, 
            onAutosizeCallback,
            lvsdAceArbBbPercTemporalMetrics,
            lvsdAceArbBbCountTemporalMetrics,
            lvsdAceArbOnlyPercTemporalMetrics,
            lvsdAceArbOnlyCountTemporalMetrics,
            lvsdBbOnlyPercTemporalMetrics,
            lvsdBbOnlyCountTemporalMetrics,
            lvsdNonePercTemporalMetrics,
            lvsdNoneCountTemporalMetrics]);

    return (
        <div className="total-lvsd-patients-by-therapy-container">
            {containsData ? (
                <Card className="total-lvsd-patients-by-therapy-card">
                    <CardBody>
                        <CardTitle>Proportion of LVSD patients by therapy</CardTitle>
                        {metricsChart}
                    </CardBody>
                </Card>
            ) : (
                <CardLoaderMemo
                    cardClassName='total-lvsd-patients-by-therapy-loader'/>
            )}

        </div>
    );
};

export const TotalLvsdPatientsByTherapyMetricsMemo = React.memo(TotalLvsdPatientsByTherapyMetrics);