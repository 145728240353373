import React from "react";
import './PatientsPrevalenceMetricCard.scss';
//@ts-ignore
import { Card, CardBody, CardTitle } from '@e360/react-core';
import { PatientsPrevalenceMetricIconMemo } from "../PatientsPrevalenceMetricIcon/PatientsPrevalenceMetricIcon";

interface PatientsPrevalenceMetricCardProps {
    iconSource: string;
    cardClassName: string;
    cardTitle: string;
    cardValue: string;
}

const PatientsPrevalenceMetricCard: React.FunctionComponent<
PatientsPrevalenceMetricCardProps> = ({iconSource, cardClassName, cardTitle, cardValue}) => {
    return (
        <Card className={cardClassName}>
            <CardBody className="patient-metric-card-body">
                <CardTitle className="patient-metric-card-title">
                    {cardTitle}
                </CardTitle>
                <div className="patient-metric-card-content">
                    <span className="patient-metric-card-card-value">
                        {cardValue}
                    </span>
                    <PatientsPrevalenceMetricIconMemo
                        className="patient-metric-icon"
                        iconSource={iconSource}/>
                </div>
            </CardBody>
        </Card>
    );
};

export const PatientsPrevalenceMetricCardMemo = React.memo(PatientsPrevalenceMetricCard);