import { HttpStatusCode } from "axios";

export class HttpServiceError {
    httpStatusCode: HttpStatusCode;
    error: any;

    constructor(
        statusCode: HttpStatusCode,
        error: any) {
        this.httpStatusCode = statusCode;
        this.error = error;
    }
}