export enum ICON_NAME {
    BUSINESS_PHONE = 'IQVIA_Icons_business_phone.svg',
    BUSINESS_VIRTUAL_REP = 'IQVIA_Icons_business_virtual_rep.svg',
    HEALTHCARE_HOSPITAL_BUILDING = 'IQVIA_Icons_healthcare_hospital_building.svg',
    HEALTHCARE_HEART_VASCULAR_SYSTEM = 'IQVIA_Icons_healthcare_heart_vascular_system.svg',
    PEOPLE_PATIENT_GROUP = 'IQVIA_Icons_people_patient_group.svg',
    HEALTHCARE_PILL = 'IQVIA_Icons_healthcare_pill.svg',
    GREEN_CHECK_CIRCLE = 'check-circle.svg',
    ORANGE_EXCLAMATION_CIRCLE = 'exclamation-circle.svg',
    RED_TIMES_CIRCLE = 'times-circle.svg',
    CHANGE_IN_HFXXX_CHARTS_LEGEND = 'change-in-hfxxx-charts-legend.png',
    COMPANY_LOGO_HORIZONTAL = 'IQVIALogoHorizontal.svg',
    SAD_FACE = 'icon-sad-face.svg',
    CONE = 'icon-cone.svg',
    HEALTHCARE_LUNGS = 'IQVIA_Icons_healthcare_lung.svg',
}