import './FlexLoader.scss';
import React from 'react';
//@ts-ignore
import { Loader } from '@e360/react-loader';

interface FlexLoaderProps {
    wrapperClassName? :string;
    loaderClassName?: string
}

const FlexLoader: React.FunctionComponent<
    FlexLoaderProps> = ({wrapperClassName, loaderClassName}) => {
    return (
        <div className={wrapperClassName ?? 'flex-loader-wrapper-default'}>
            <Loader className={loaderClassName ?? 'flex-loader-loader-default'}/>
        </div>
    )
};

export const FlexLoaderMemo = React.memo(FlexLoader);