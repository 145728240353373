// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of

import { AppState } from "../store";
import { PracticeData } from "../../models/PracticeData";

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPracticeIdSelector = (state: AppState) => state.practice.practiceInfo?.id;

export const selectPracticeSelector = (state: AppState) => { return {
    practiceInfo: state.practice.practiceInfo,
    isLoading: state.practice.isLoading } as PracticeData
};
