import './CardLoader.scss';
//@ts-ignore
import { Card, CardBody, CardTitle } from '@e360/react-core';
import React from 'react';
import {BlueLoader} from "../BlueLoader/BlueLoader";

interface CardLoaderProps {
    cardClassName: string;
    cardTitleClassName?: string;
    cardBodyClassName?: string;
    cardTitle?: string;
}

const CardLoader: React.FunctionComponent<
CardLoaderProps> = ({cardClassName, cardTitleClassName, cardBodyClassName, cardTitle}) => {
    return(
        <Card className={cardClassName}>
            <CardBody className={cardBodyClassName + ' card-loader-body'}>
                <CardTitle className={cardTitleClassName + ' card-loader-title'}>
                    {cardTitle}
                </CardTitle>
                <BlueLoader/>
            </CardBody>
        </Card>
    )
};

export const CardLoaderMemo = React.memo(CardLoader);